import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  AfterContentInit,
  AfterViewInit,
  SimpleChanges,
  Input,
  ViewEncapsulation,
  ViewChild
} from '@angular/core';

import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { Router, NavigationEnd } from '@angular/router';

import { SideMenuItem } from './side-menu-item';

import { Subscription } from 'rxjs';
import { Features } from '@app/shared/models/features';
import { AuthService } from '@app/core';
import { IS_ACTIVE_MATCH_OPTIONS_EXACT_TRUE } from '@app/shared/consts/is-active-match-options';
import { DashboardTourService } from '../../services/dashboard-tour.service';
import { FeedbackOverlayComponent } from '../feedback-overlay/feedback-overlay.component';
import { ChangePasswordOverlayComponent } from '../change-password-overlay/change-password-overlay.component';
import { GetFullVersionDialogComponent } from '../get-full-version-dialog/get-full-version-dialog.component';

@Component({
  selector: 'nav-menu',
  templateUrl: 'side-menu.html',
  styleUrls: ['side-menu.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('state', [
      state('expanded', style({
        height: '*',
        visibility: 'visible'
      })),
      state('collapsed', style({
        height: '0',
        visibility: 'hidden'
      })),
      transition('expanded => collapsed', [animate('500ms ease-out')]),
      transition('collapsed => expanded', [animate('500ms ease-out')])
    ])
  ]
})

export class SideMenuComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit, AfterViewInit {
  @Input()
  public id: string;

  private _items: Array<SideMenuItem>;
  @Input()
  public items: Array<SideMenuItem>;
  @Input()
  public minimized: boolean;

  private _navChange: Subscription;

  get userEmail(): string {
    return this.auth?.userDetail?.email;
  }

  get isLogged(): boolean {
    return this.auth.isLogged;
  }

  get showUpgradeButton(): boolean {
    return this.isLogged
      && (this.router.isActive(Features.BASIC_USER_ACTIVITY.defaultPath, IS_ACTIVE_MATCH_OPTIONS_EXACT_TRUE)
        || this.router.isActive(Features.PREMIUM_USER_ACTIVITY.defaultPath, IS_ACTIVE_MATCH_OPTIONS_EXACT_TRUE));
  }

  get showGuidedTourButton(): boolean {
    return this.dashboardTour.isTourSupported(this.router.url)
      && this.isLogged;
  }

  @ViewChild('feedbackOverlay') feedbackOverlay: FeedbackOverlayComponent;
  @ViewChild('changePasswordOverlay') changePasswordOverlay: ChangePasswordOverlayComponent;
  @ViewChild('getFullVersionDialog') getFullVersionDialog: GetFullVersionDialogComponent;

  constructor(
    private router: Router,
    private auth: AuthService,
    private dashboardTour: DashboardTourService,
  ) {
    this._navChange = this.router.events.subscribe(this.onNavigationChange);
  }

  trackByFn(index: number, item: SideMenuItem) {
    return item.id;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && changes['items'].currentValue) {
      // Mark a expaned the current active item
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].state = this.isActive(this.items[i])
          ? 'expanded'
          : 'collapsed';
      }
    }
  }

  ngOnDestroy() {
    this._navChange.unsubscribe();
  }

  ngAfterContentInit() {
  }

  ngAfterViewInit() {
  }

  onNavigationChange = (event: any) => {
    if (event instanceof NavigationEnd) {
      let current = this.getExpandedItem();

      if (!current || !this.isActive(current)) {
        let active = this.getActiveItem();

        if (active) {
          if (current) {
            current.state = 'collapsed';
          }

          active.state = 'expanded';
        }
      }
    }
  };

  onToggleExpanded(event: any, item: SideMenuItem) {
    event.preventDefault();

    let current = this.getExpandedItem();

    if (current && current.id === item.id) {
      switch (item.state) {
        case 'expanded':
          item.state = 'collapsed';
          break;
        case 'collapsed':
          item.state = 'expanded';
          break;
      }
    }
    else {
      if (current) {
        current.state = 'collapsed';
      }

      item.state = 'expanded';
    }

    if (item.link) {
      this.router.navigate([item.link]);
    } else
      if (item.nodes && item.nodes.length) {
        this.router.navigate([item.nodes[0].link]);
      }
  }

  getExpandedItem(): SideMenuItem {
    if (!this.items) {
      return null;
    }

    return this.items.find(item => item.state === 'expanded');
  }

  getActiveItem(): SideMenuItem {
    if (this.items) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.isActive(this.items[i])) {
          return this.items[i];
        }
      }
    }

    return null;
  }

  isActive(item: SideMenuItem): boolean {
    if (item.nodes && item.nodes.length) {
      for (let i = 0; i < item.nodes.length; i++) {
        if (this.isActive(item.nodes[i])) {
          return true;
        }
      }
    }
    else if (item.link) {
      return this.router.isActive(item.link, IS_ACTIVE_MATCH_OPTIONS_EXACT_TRUE);
    }

    return false;
  }

  onTourStart() {
    this.dashboardTour.tourService.start();
  }

  onFeedbackButtonClick(event: MouseEvent) {
    this.feedbackOverlay.overlay.toggle(event);
  }

  onChangePasswordButtonClick(event: MouseEvent) {
    this.changePasswordOverlay.overlay.toggle(event);
  }

  onUpgradeNowButtonClick(event: MouseEvent) {
    this.getFullVersionDialog.show();
  }
}
