<form [formGroup]="formGroup">
  <div class="d-flex">
    <p-multiSelect formControlName="genarts"
      [appendTo]="'body'"
      [options]="genarts"
      [optionLabel]="'label'"
      [filter]="true"
      [showToggleAll]="false"
      [virtualScroll]="true"
      [virtualScrollItemSize]="40"
      class="d-flex flex-grow-1 overflow-x-auto"
      [styleClass]="'flex-grow-1 analytics-filter xs'"
      [style]="{'maxWidth': '100%'}"
      [panelStyleClass]="'items-no-padding xs'">
    </p-multiSelect>
    <button [tourAnchor]="'fav-genarts-tour'" class="btn btn-favorite" (click)="menu.toggle($event)">
      <i class="fa fa-star"></i>
    </button>
  </div>
  <p-menu #menu
    [appendTo]="'body'"
    [model]="genartFavorites"
    [popup]="true"
    [style]="{'width': '310px'}"
    [styleClass]="'items-no-padding xs divide-first-item'">
    <ng-template pTemplate="item" let-item>
      <a class="d-inline-flex align-items-center cursor-pointer text-decoration-none ps-2 py-1 w-100"
        [ngClass]="{ 'text-dark': item.genart?.length }">
        <span class="flex-grow-1">{{ item.label }}</span>
        <i *ngIf="item.genart?.length"
          class="fa fa-trash text-danger cursor-pointer no-decoration mx-2"
          (click)="onDeleteFavorite(item)">
        </i>
      </a>
    </ng-template>
  </p-menu>
</form>
