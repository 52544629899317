import { Injectable, EventEmitter } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import { ChartData } from '@app/shared/models/analytics/chart-data';
import {Observable, of, timer} from 'rxjs';
import { environment } from '@env/environment';
import {map, catchError, share} from 'rxjs/operators';
import { Brand } from '@app/shared/models/brand';
import { ChartRequest } from '@app/shared/utils/chart-request';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import {
  StorageService,
  TaskTrackerService,
  AuthService,
  RequestCacheService,
  TrackerService} from '@app/core';
import { BasicResponse } from '@app/shared/models/basic-response';
import { BrandFilter } from '@app/shared/models/settings/brand-filter';
import { User } from '@app/shared/models/user';
import { FullExportListingResponse } from '@app/shared/models/full-export-listing-response';
import { FacetItem } from '@app/shared/models/facet-item';
import { BrandRecommendsResponse } from '@app/shared/models/brand-recommends-response';
import { GenartRecommendsResponse } from '@app/shared/models/genart-recommends-response';
import { Converter } from '@app/shared/utils/converter';
import { DatasourcesResponse } from '@app/shared/models/settings/datasources-response';
import { AssociationsRecommendsResponse } from '@app/shared/models/associations-recommends-response';
import { OwnBrand } from '@app/shared/models/own-brand';
import { UserRoleChecker } from '@app/shared/utils/user-role-checker';
import { TimeLineRows } from '@app/shared/models/analytics/time-line-rows';
import { SaveUserResponse } from '@app/shared/models/save-user-response';
import { DownloadHandler } from '@app/shared/utils/download-handler';
// import { PdfService } from '../pdf/pdf.service';
import { Feedback } from '@app/shared/models/feedback';
import { UserRoleDTO } from '@app/shared/models/user-role-dto';
import { ReportParams } from '@app/shared/models/i-analytics-page';
// import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { DatasourceInfo } from '@app/shared/models/datasource-info';
// import {MenuInterface} from '@app/admin-shared/services/menu-interface'; // TODO: Decouple
import {DatasourceSettings} from '@app/shared/models/datasource-settings';
import {DeleteUserResponse} from "@app/core/services/security/cis/delete-user-response";
import { SELECTED_DATASOURCE } from '../consts/datasource';
import { DashboardTourService } from './dashboard-tour.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private settings: Map<String, BasicResponse> = new Map();
  public datasources = new Map<string, FacetItem[]>();

  public datasourceChangeListener: EventEmitter<string[]> = new EventEmitter();

  private _selectedDataSource: string[] = [];

  private infoTexts: Map<string, string> = new Map<string, string>();

  // public menuInterface: MenuInterface;

  public set selectedDataSource(  v : string[] ) {
    if( ( '' + this._selectedDataSource ) != ( '' + v ) ) {
      this.datasourceChangeListener.emit(v);
    }
    this._selectedDataSource = v;
  }

  public get selectedDataSource(): string[] {
    return this._selectedDataSource;
  }

  //datasources
  private brands: Map<string, Brand[]> = new Map<string, Brand[]>();
  private allBrands: Brand[];
  private resources = new Map();

  public brandsMap: Map<string, Brand>;
  public allBrandMap: Map<string, Brand>;

  private props: any;

  public onBrandsLoaded: EventEmitter<Brand[]> = new EventEmitter();

  public datasourceSettings: DatasourceSettings;

  private reset() {
    console.log('reset');
    this.storage.remove(SELECTED_DATASOURCE);
  }

  private getHeaders() {
    let r = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    r = r.set('X-CustomToken', this.getToken());
    return r;
  }

  getOptions() {
    return {
              headers: this.getHeaders()//,//,
              //withCredentials: true
          };
  }

  clearStoredFilterOptions() {
    this.datasources.clear();
    this.brands.clear();
    this.resources.clear();
  }

  constructor( public http: HttpClient,
                public _auth: AuthService,
                  public storage: StorageService,
                    private _tracker: TaskTrackerService,
                      // public pdfService: PdfService,
                         private _tracking: TrackerService,
                         dashboardTour: DashboardTourService ) {

    console.log('api.service: constructor');

    _auth.loggedOut.subscribe(() => {
      console.log('api.service -> reset model');
      this.brands = new Map<string, Brand[]>();
      this.resources = new Map();
      this.settings = new Map();
      this.datasources = new Map();
    });
    _auth.loggedIn.subscribe((token) => {
      console.log( 'api.service -> token: ' + token);

      //this.checkFullVersionAlreadyRequested();

      this.getBrands().subscribe();

      this.getResources('countries').subscribe();
      this.getResources('articleNumberTypes').subscribe();
      this.getResources('vehicleTypes').subscribe();
      this.getResources('genericArticles2').subscribe();
      this.getSettings(dashboardTour.TOUR_SEEN_KEY, true).subscribe((v) => {
        let tourSeen = (v && v.message == 'true');
        this.storage.setCookie(dashboardTour.TOUR_SEEN_KEY, tourSeen, 1);
      });
    });

    this.loadInfoTexts();

    //this.checkFullVersionAlreadyRequested();
  }

  public isPropsLoaded() {
    return !!this.props;
  }

  public isAdmin() {
    return UserRoleChecker.isAdmin(this.props);
  }

  public isInternalUser() {
    return UserRoleChecker.isInternalUser(this.props);
  }

  public getUserRole() {
    return this.props ? this.props.userRole : null;
  }

  public isSuperAdmin() {
    return UserRoleChecker.isSuperAdmin(this.props);
  }

  public getUserName() {
    return this.storage.getCookie(this.storage.getKey('userName'));
  }

  public apiUrl( path: string ): string {
    let basePath;
    if (path.startsWith('/piwikApi')) {
      basePath = environment.api.piwikApiUrl + path.replace('/piwikApi', '');
    } else {
      basePath = environment.api.url + path;
    }
    return basePath + '?' + (environment.api.fixedParams || '');
  }

  public recommenderUrl( path: string ): string {
    return environment.recommender.url + path;
  }

  public onPropertiesLoaded: EventEmitter<any> = new EventEmitter<any>();

  private setFeatureFlags( p ) {
    if( p && p.features ) {
      //this.featureVehicleModels = p.features.indexOf('1a2b3c4c') !== -1; // keep just as example
    }
  }

  public getProps(): Observable<any> {
    return this.http.get( this.apiUrl( '/user/properties' ),
      this.getOptions() ).pipe(
        map((data: any) => {
        this.props = data;

        this.setFeatureFlags( data );

        this.onPropertiesLoaded.emit(data);

        const userRole = this.getUserRole();
        if( userRole ) {
          this._tracking.setDimensionValue(1, userRole);
        }

        return data;
      }),
      catchError(error => this.handleError(error, {features: []}))
    );
  }

  public getResources(path: string, showLoadingAnimation: boolean = false): Observable<any> {
    if( this.resources.has( path ) ) {
      return of( this.resources.get(path) );
    }

    if( !this.getToken() ) {
      console.log( 'no sessionId available');
      return of({});
    }

    let r = this.http.get(this.apiUrl('/resources/' + path),
      this.getOptions()).pipe(
      map((data: any) => {
        this.resources.set(path, data);
        return data;
      }),
      catchError(error => this.handleError(error, {}))
    );
    if (showLoadingAnimation) {
      this._tracker.add(r);
    }
    return r;
}

  createSettingsRequest( settingsName: string, jsonData?: string ) {
    console.log( 'userDetails', this._auth.userDetail );

    return { /*userId: this._auth.userDetail.id,
      userName: this._auth.userDetail.login, */
      settingsName: settingsName,
      jsonData: jsonData };
  }

  saveSettings( settingsName: string, jsonData: string ): Observable<BasicResponse> {

    const params = this.createSettingsRequest( settingsName, jsonData );

    return this.http.post<BasicResponse>( this.apiUrl( '/user/saveSettings' ),
                          params,
                          this.getOptions() ).pipe(
                            map((response: BasicResponse) => {
                              // handle BasicResponse statusCode and message
                              console.log(response);

                              // this.getSettings(settingsName, true).subscribe();

                              return response;
                          }),
                          catchError(error => this.handleError(error, <BasicResponse>{}))
                        );
  }

  getSettings( settingsName: string, force = false ): Observable<BasicResponse> {

    if( !force && this.settings.has(settingsName) ) {
      return of(this.settings.get(settingsName));
    }

    const params = this.createSettingsRequest( settingsName );

    return this.http.post<BasicResponse>( this.apiUrl( '/user/getSettings' ),
                                              params,
                      this.getOptions() ).pipe(
        map((data: BasicResponse) => {

          this.settings.set(settingsName, data);

          return data;
        }),
        catchError(error => this.handleError(error, <BasicResponse>{}))
      );
  }

  public getBrands( datasources? ): Observable<Brand[]> {

      const key = datasources || 'all';

      if( this.brands.has(key) ) {
          this.brandsMap = Converter.convertToBrandsMap( this.brands.get(key) );
          this.onBrandsLoaded.emit(this.brands.get(key));
          return of( this.brands.get(key) );
      }

      if( !this.getToken() ) {
        console.log( 'no sessionId available');
        const br: Brand[] = []
        return of(br);
      }

      let url = this.apiUrl( '/user/brands' );
      if( datasources ) {
        url += '&datasources=' + datasources;
      }
      return this.http.get( url,
                      this.getOptions() ).pipe(
        map((data: any) => {

          // was just test data to see i can get competitor data
          /*data.data.array.push({
            brandId: 30,
            brandName: 'BOSCH'
          });*/

          this.brands.set( key, data.data.array );
          this.brandsMap = Converter.convertToBrandsMap( this.brands.get(key) );
          this.onBrandsLoaded.emit(this.brands.get(key));
          return this.brands.get(key);
        }),
        catchError(error => this.handleError(error, []))
      );
  }

  public getAllBrands(showLoadingAnimation: boolean = false): Observable<Brand[]> {
    if( this.allBrands != null ) {
      return of( this.allBrands );
    }

    if( !this.getToken() ) {
      console.log( 'no sessionId available');
      const br: Brand[] = []
      return of(br);
    }

    let r = this.http.get( this.apiUrl( '/admin/allBrands' ),
      this.getOptions() ).pipe(
      map((data: any) => {
        this.allBrands = data;
        this.allBrandMap = Converter.convertToBrandsMap(data);
        return this.allBrands;
      }),
      catchError(error => this.handleError(error, []))
    );

    if (showLoadingAnimation) {
      this._tracker.add(r);
    }

    return r;
}

public getBrandFilters( withBrandIds?: boolean ): Observable<BrandFilter[]> {
  if( !this.getToken() ) {
    return of(<BrandFilter[]>[]);
  }

  return this.http.get<BrandFilter[]>( this.apiUrl( '/admin/brandFilters' )
                        + (withBrandIds != null ? '&withBrandIds=' + withBrandIds : ''),
                  this.getOptions() ).pipe(
    map((data: BrandFilter[]) => {
      return data;
    }),
    catchError(error => this.handleError(error, <BrandFilter[]>[]))
  );
}

getDatasources(): Observable<DatasourcesResponse> {
  if( !this.getToken() ) {
    return of(<DatasourcesResponse>{});
  }

  return this.http.get( this.apiUrl( '/datasources/sites' ),
                  this.getOptions() ).pipe(
    map((data: DatasourcesResponse) => {
      return data;
    }),
    catchError(error => this.handleError(error, <DatasourcesResponse>{}))
  );
}

private getDataSourceKey( pageKey: string ): string {
    if( pageKey && pageKey.startsWith('@') && pageKey.indexOf('_') != -1 ) {
       return pageKey.substring(1, pageKey.indexOf('_'));
    }
    return 'TCD';
}

getUserDataSources(pageKey:string): Observable<FacetItem[]> {
  if( !this.getToken() ) {
    return of(<FacetItem[]>{});
  }

  const cacheKey = this.getDataSourceKey(pageKey);
  if( this.datasources.has(cacheKey) ) {
    return of( this.datasources.get(cacheKey) );
  }

  return this.http.get<FacetItem[]>( this.apiUrl( '/datasources/user' ) + '&pageKey=' + pageKey,
                  this.getOptions() ).pipe(
    map((data: FacetItem[]) => {
      if( data && data.length ) {
        this.datasources.set( cacheKey, data );
      }

      return data;
    }),
    catchError(error => this.handleError(error, <FacetItem[]>[]))
  );
}

hasDatasourceCart( dsId: string ) {
    if( this.datasources ) {

    }
}

deleteBrandFilter(_selectedFilter: BrandFilter): Observable<BasicResponse> {
  return this.http.delete(
    this.apiUrl('/admin/brandFilters' ) + "&id=" + _selectedFilter.id,
    this.getOptions()
  ).pipe(
    map((data: BasicResponse) => {
      return data;
    }),
    catchError(error => this.handleError(error, <BasicResponse>{}))
  );
}

saveBrandFilter( filter: BrandFilter): Observable<BrandFilter> {

  return this.http.post<BrandFilter>(
    this.apiUrl('/admin/brandFilters' ),
          filter,
          this.getOptions()

  ).pipe(
    map((data: BrandFilter) => {
      return data;
    }),
    catchError(error => this.handleError(error, <BrandFilter>{}))
  );
}

  public getToken(): string {
    return this._auth.token;
  }

  private getUrl( path: string, request?: ChartRequest, type?: string, lang?: string ) {
    return this.apiUrl( path )
                + ( type ? '&type=' + type : '' )
                + ( lang ? '&lang=' + lang : '' )
                + ( request ? '&' + request.toRequestParams() : '' )

  }

  public getArticleDetails( req: ChartRequest, articleNumber: string, showLoadingAnimation = true ): Observable<any> {
    const r: Observable<any> =  this.http.get(
                  this.getUrl('/user/articleDetails2', req, undefined, 'en') + '&articleNumber=' + articleNumber,
                    this.getOptions() ).pipe(
                    map((data: any) => {
                      data.token = this.getToken();
                      return data;
                    }),
                    catchError(error => this.handleError(error, new ReportResponse())),
                    share()
          );

      if( showLoadingAnimation ) {
        this._tracker.add( r );
      }

      return r;
  }

  public getVehicleDetails( vehicleId: string ): Observable<any> {
    const r: Observable<any> =  this.http.get(
                  this.apiUrl( '/cache/vehicles2' ) + '&ids=' + vehicleId,
                    this.getOptions() ).pipe(
                    map((data: any) => {
                      return data;
                    }),
                    catchError(error => this.handleError(error, {})),
                    share()
          );

      return r;
  }

  public doExport(path: string, downloadHandler: DownloadHandler, request: ChartRequest, type?: string) {
    this.download(this.getUrl(path, request, type, 'en'), downloadHandler);
  }

  public getReport(path: string, request: ChartRequest, showLoadingAnimation = true): Observable<ReportResponse> {
    let options = this.getOptions();
    const r: Observable<ReportResponse> = this.http.get<ReportResponse>(this.getUrl(path, request),
      { ...options, observe: 'response'}).pipe(
      map((resp: HttpResponse<ReportResponse>) => {
        let data = resp.body;
        console.log('aS.loadReport #1');
        let fromCache = resp.headers.get(RequestCacheService.CACHE_HEADER) === 'true' ? true : false;
        if (data) {
          data.token = this.getToken();
        }
        const r = this.objectToResponse(data, request, fromCache);
        r.responseData = data;

        return r;
      }),
      catchError(error => this.handleError(error, new ReportResponse())),
      share()
    );

    if (showLoadingAnimation) {
      this._tracker.add(r);
    }

    return r;
  }

  public objectToResponse(data: ReportResponse, request?: ChartRequest, fromCache: boolean = false): ReportResponse {
    const r : ReportResponse = new ReportResponse();
    if( data ) {
      r.dateFormat = data.dateFormat;
      r.fromDate = data.fromDate;
      r.toDate = data.toDate;
      r.token = data.token;
      r.statusCode = data.statusCode;
      r.message = data.message;

      if( data.facets ) {
        for( const key in data.facets ) {
            const cd = new ChartData( key, data.facets[key].data, data.facets[key].numFound, 'name', 'count', !(request.facetCount == -1), fromCache);
            cd.label = data.facets[key].label;

            if(data.facets[key].timeLine) {
              cd.timeLine = new TimeLineRows( data.facets[key].timeLine);
            }
            if( request ) {
              cd.setSelectedIds( request.params.get(key) );
            }
            r.facets.set( key, cd );
        }
     }
    }
    return r;
  }

  getUsers(): Observable<User[]> {
    const r = this.http.get<User[]>(
      this.apiUrl('/admin/users' ),
            this.getOptions()

    ).pipe(
      map((data: User[]) => {
        return data;
      }),
      catchError(error => this.handleError(error, <User[]>[]))
    );

    this._tracker.add(r);

    return r;
  }

  resetBrandFilterCache(): Observable<any> {
    return this.http.get(
      this.apiUrl('/admin/clearBrandFilterCache' ),
            this.getOptions()

    ).pipe(
      map((data: any) => {
        return data;
      }),
      catchError(error => this.handleError(error, {}))
    );
  }

  testMail(): Observable<BasicResponse> {
    return this.http.get(
      this.apiUrl('/admin/test/new/user/mail' ),
            this.getOptions()

    ).pipe(
      map((data: BasicResponse) => {
        return data;
      }),
      catchError(error => this.handleError(error, <BasicResponse>{}))
    );
  }

  getFullExportListing( page: string ): Observable<FullExportListingResponse> {
    return this.http.get<FullExportListingResponse>(
      this.apiUrl('/rawexport/list' ) + '&page=' + page,
            this.getOptions()

    ).pipe(
      map((data: FullExportListingResponse) => {
        return data;
      }),
      catchError(error => this.handleError(error, <FullExportListingResponse>{}))
    );
  }

  getBrandAssociations( brandId: number, articleNr: string, genart?: number, showLoadingAnimation = true): Observable<BrandRecommendsResponse> {
    let r = this.http.get<BrandRecommendsResponse>(
      this.recommenderUrl('/association/brand' ) + '?manufacturer=' + brandId + '&articleNr=' + articleNr
      + ( genart ? '&genart=' + genart : '' ),
      this.getOptions()

    ).pipe(
      map((data: BrandRecommendsResponse) => {
        return data;
      }),
      catchError(error => this.handleError(error, <BrandRecommendsResponse>{}))
    );
    if (showLoadingAnimation) {
      this._tracker.add(r);
    }
    return r;
  }

  getGenericArticleAssociations( brandId: number, articleNr: string, refManufacturerId:number, showLoadingAnimation: boolean = true): Observable<GenartRecommendsResponse> {
    let r = this.http.get<GenartRecommendsResponse>(
      this.recommenderUrl('/association/genart') + '?manufacturer=' + brandId + '&articleNr=' + articleNr
      + (refManufacturerId ? '&refManufacturerId=' + refManufacturerId : ''),
      this.getOptions()
    ).pipe(
      map((data: GenartRecommendsResponse) => {
        return data;
      }),
      catchError(error => this.handleError(error, <GenartRecommendsResponse>{}))
    );
    if (showLoadingAnimation) {
      this._tracker.add(r);
    }
    return r;
  }

  getAssociationsTable( brandId: number, articleNr: string ) : Observable<AssociationsRecommendsResponse> {
    return this.http.get<AssociationsRecommendsResponse>(
      this.recommenderUrl('/association/all' ) + '?manufacturer=' + brandId + '&articleNr=' + articleNr,
            this.getOptions()

    ).pipe(
      map((data: AssociationsRecommendsResponse) => {
        return data;
      }),
      catchError(error => this.handleError(error, <AssociationsRecommendsResponse>{}))
    );
  }

  getUserRoles(): Observable<UserRoleDTO[]> {
    return this.http.get<UserRoleDTO[]>(
      this.apiUrl('/admin/roles' ),
            this.getOptions()

    ).pipe(
      map((data: UserRoleDTO[]) => {
        return data;
      }),
      catchError(error => this.handleError(error, <UserRoleDTO[]>[]))
    );
  }

  getFeatures(): Observable<FacetItem[]> {
    return this.http.get<FacetItem[]>(
      this.apiUrl('/admin/availableFeatures' ),
            this.getOptions()

    ).pipe(
      map((data: FacetItem[]) => {
        return data;
      }),
      catchError(error => this.handleError(error, <FacetItem[]>[]))
    );
  }

  saveUser(user: User): Observable<SaveUserResponse> {
    return this.http.post<SaveUserResponse>(
      this.apiUrl('/admin/user' ),
            user,
            this.getOptions()
    ).pipe(
      map((data: SaveUserResponse) => {
        return data;
      }),
      catchError(error => this.handleError(error, <SaveUserResponse>{}))
    );
  }

  getBrandsByGA(articleNr: string, timespan: string, datasource: string, gap: string = '1DAY'): Observable<OwnBrand[]> {
    return this.http.get(
      this.apiUrl('/brands/own-brand' ) + `&genArtId=${articleNr}&timespan=${timespan}&datasource=${datasource}&gap=${gap}`,
      this.getOptions()
    ).pipe((data: any) => {
        //console.log(data);
        return data;
      },
      catchError(error => this.handleError(error, <any>{}))
    );
  }

  public getDatasourceStats(datasourceIds: string[]): Observable<DatasourceInfo[]> {
    const requestUrl = this.apiUrl('/datasources/stats') + '&datasourceIds=' + datasourceIds.join(',');
    return this.http.get<DatasourceInfo[]>(requestUrl,
      this.getOptions()).pipe(
        map((data: DatasourceInfo[]) => {
          return data;
        }),
        catchError(error => this.handleError(error, []))
      );
  }

  public handleError<T>(error: HttpErrorResponse, fallbackValue: T): Observable<T> {
    //TODO: Error Handling oder Message ausgabe
    return of(fallbackValue);
  }

  public download(url: string, downloadHandler: DownloadHandler) {
    let downloadRequest = this.http.get(url, {
      responseType: 'blob',
      observe: 'events',
      reportProgress: true,
      headers: this.getHeaders()
    });
    downloadHandler.startTracking(downloadRequest);
  }

  sendFeedback(feedback: Feedback): Observable<BasicResponse> {

    const r: Observable<BasicResponse> = this.http.post<BasicResponse>( this.apiUrl( '/feedback/send' ),
                                              feedback,
                      this.getOptions() ).pipe(
        map((data: BasicResponse) => {
          return data;
        }),
        catchError(error => this.handleError(error, <BasicResponse>{}))
      );

    return r;
  }

  checkFullVersionRequested(): Observable<BasicResponse> {
    const r: Observable<BasicResponse> = this.http.get<BasicResponse>( this.apiUrl( '/feedback/full-version/requested' ),
                      this.getOptions() ).pipe(
        map((data: BasicResponse) => {
          return data;
        }),
        catchError(error => this.handleError(error, <BasicResponse>{}))
      );

    return r;
  }

  sendFullVersionRequest( data ): Observable<BasicResponse> {
    const r: Observable<BasicResponse> = this.http.post<BasicResponse>( this.apiUrl( '/feedback/full-version' ),
                                              data,
                      this.getOptions() ).pipe(
        map((data: BasicResponse) => {
          return data;
        }),
        catchError(error => this.handleError(error, <BasicResponse>{}))
      );

    return r;
  }

  loadInfoTexts() {
    this.loadText('infoTexts.properties')
          .subscribe((text: string) => {
      if( text ) {
        const lineSplit = text.indexOf('\r\n') != -1 ? '\r\n' : '\n';
        const lines = text.split(lineSplit);
        for( const line of lines ) {
          const idx = line.indexOf('=');
          if( idx != -1 ) {
            const key = line.substring(0, idx).trim();
            const text = line.substring(idx + 1).trim();

            this.infoTexts.set(key, text);
          }
        }
      }
    });
  }

  loadText(fileName): Observable<string> {

    const url = '/assets/texts/';
    const requestUrl = url + fileName + '?_=' + new Date().getTime();

    return this.http.get( requestUrl, {responseType: 'text'} )
      .pipe(
        map((data: string) => {
          return data;
        }),
      catchError(error => this.handleError(error, <string>''))
    );
  }

  getInfoText(fieldName: string) {
    fieldName = fieldName.replace(/:/g, '_');

    console.log( 'getInfoText ' + fieldName, this.infoTexts );

    if( this.infoTexts.has(fieldName) ) {
      console.log('infoTexts: #F1' );
      return this.infoTexts.get(fieldName);
    }

    return null;
  }

  public runLoading( seconds ) {
    this._tracker.add(timer(seconds * 1000).subscribe());
  }

  // public generatePDF(params: ReportParams, canvasImage: string,
  //                       currentChartData: ReportResponse,
  //                       chartParams: ChartRequest,
  //                       page: AnalyticsPage,
  //                       infoText?: string,
  //                       preRenderForPageNos?: boolean ) {

  //     this.pdfService.generatePDF(params, canvasImage, currentChartData,
  //                           chartParams, page, infoText);
  // }

  public getSelectedDatasourceId(): string[] {
    const selectedDatasource = this.storage.get(SELECTED_DATASOURCE);
    if( selectedDatasource ) {
      return selectedDatasource.split(',');
    }
    return [];
  }

  private getApplyDatasourceKey() {
    return SELECTED_DATASOURCE + 'Apply';
  }

  public applyDatasourceId() {
    let dsId = this.getSelectedDatasourceId();
    dsId = dsId.sort();
    this.storage.set(this.getApplyDatasourceKey(), dsId.join(','));
  }

  public isDatasourceChanged() {
    const applyDsId = this.storage.get(this.getApplyDatasourceKey());
    const lastDsId = this.getSelectedDatasourceId();
    if( applyDsId && lastDsId ) {
      return applyDsId != lastDsId.join(',');
    }
    return false;
  }

  public setSelectedDatasourceId(datasourceId: string[], fromFilter = false): DatasourceSettings {
    // TODO: refactor this method to make it single-responsible:
    // 1. Set the selected datasource on storage.
    // 2. Get the datasource settings from other method, not inside here. This will decouple this method
    // from dependencies of a more specific module.
    console.log('setSelectedDatasourceId', datasourceId);
    console.log('fromFilter: ' + fromFilter);

    this.storage.set(SELECTED_DATASOURCE, datasourceId.sort().join(','));
    // this.datasourceSettings = this.menuInterface.datasourceSelected( fromFilter );

    return this.datasourceSettings;
  }

  public deleteDatasourceSelection() {

  }

  public saveDatasourceDescription(id: string, description: string): Observable<BasicResponse> {
     const params = {
       id: id,
       description: description
     }

     const r = this.http.post<BasicResponse>( this.apiUrl( '/datasources/description' ),
                          params,
                          this.getOptions() ).pipe(
                            map((response: BasicResponse) => {
                              return response;
                          }),
                          catchError(error => this.handleError(error, <BasicResponse>{}))
                        );

     this._tracker.add(r);

     return r;
  }

  deleteMarkedUsers(): Observable<DeleteUserResponse> {
    const params = {
    };
    const r = this.http.delete<DeleteUserResponse>(
              this.apiUrl('/admin/user'),
              this.getOptions() )
              .pipe(
                  map((response: DeleteUserResponse) => {
                    return response;
                  }),
                  catchError(error => this.handleError(error, <DeleteUserResponse>{}))
              );

    this._tracker.add(r);

    return r;
  }
}
