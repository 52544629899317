<form [formGroup]="filterForm" tourAnchor="filter-bar-tour" class="h-100 d-flex align-items-center analytics-filter" *ngIf="isFilterEnabled">
  <button id="btnApplyFilter"
    class="btn btn-primary p-2"
    [pTooltip]="applyFilterButtonTooltipText | translate:applyFilterButtonTooltipParams"
    [disabled]="!isApplyFilterButtonEnabled"
    tooltipPosition="top"
    (click)="onApplyFilterClick()">
    <i class="fa ta-icon-filtering"></i>
  </button>

  <div class="flex-grow-1">
    <div class="card filter-datasource mx-2 flex-grow-1" *ngIf="showDatasourceFilter">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-master-data" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.DATA_SOURCE' | translate }}
        </span>
        <p-multiSelect formControlName="datasources" class="flex-grow-1"
          [appendTo]="'body'"
          [group]="true"
          [options]="datasources"
          [panelStyleClass]="'items-no-padding groups-as-dividers xs'"
          [styleClass]="'d-flex flex-grow-1 analytics-filter xs'"
          (onChange)="onDatasourceSelect($event)">
        </p-multiSelect>
      </div>
    </div>

    <div class="card filter-time mx-2 flex-grow-1">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-calendar" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.TIMESPAN' | translate }}
        </span>
        <timespan-select formGroupName="timespan" class="flex-grow-1"></timespan-select>
      </div>
    </div>

    <div class="card filter-tags mx-2 flex-grow-1" *ngIf="showBrandFilter">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-manufactor" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.BRANDS' | translate }}
        </span>
        <p-multiSelect formControlName="brands" class="flex-grow-1"
          [appendTo]="'body'"
          [options]="brands"
          optionLabel="brandLabel"
          optionValue="brandId"
          [virtualScroll]="true"
          [virtualScrollItemSize]="38"
          [panelStyleClass]="'items-no-padding xs'"
          [styleClass]="'d-flex flex-grow-1 analytics-filter xs'">
        </p-multiSelect>
      </div>
    </div>

    <div class="card filter-tags mx-2 flex-grow-1" *ngIf="showSearchTypeIdFilter || showSearchTypeOeIdFilter">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-tags" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.NUMBER_TYPE' | translate }}
        </span>
        <p-multiSelect formControlName="searchTypes" class="flex-grow-1"
          [appendTo]="'body'"
          [options]="searchTypes"
          [panelStyleClass]="'items-no-padding xs'"
          [styleClass]="'d-flex flex-grow-1 analytics-filter xs'">
        </p-multiSelect>
      </div>
    </div>

    <div class="card filter-tags mx-2 flex-grow-1" *ngIf="showNumberPlateTypeFilter">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-tags" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.NUMBER_TYPE' | translate }}
        </span>
        <p-multiSelect formControlName="numberPlateTypes" class="flex-grow-1"
          [appendTo]="'body'"
          [options]="numberPlateTypes"
          [virtualScroll]="true"
          [virtualScrollItemSize]="38"
          [panelStyleClass]="'items-no-padding xs'"
          [styleClass]="'d-flex flex-grow-1 analytics-filter xs'">
          <ng-template let-numberPlateTypes pTemplate="item">
            <span [ngClass]="numberPlateTypes.styleClass">{{ numberPlateTypes.label }}</span>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <div class="card filter-tags mx-2 flex-grow-1" *ngIf="showVehicleTypesFilter">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-car-front" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.VEHICLE_TYPES' | translate }}
        </span>
        <p-multiSelect formControlName="vehicleTypes" class="flex-grow-1"
          [appendTo]="'body'"
          [options]="vehicleTypes"
          [panelStyleClass]="'items-no-padding xs'"
          [styleClass]="'d-flex flex-grow-1 analytics-filter xs'">
        </p-multiSelect>
      </div>
    </div>

    <div class="card filter-location mx-2 flex-grow-1" *ngIf="showCountryFilter">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-earth" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.LOCATION' | translate }}
        </span>
        <p-multiSelect formControlName="locations" class="flex-grow-1"
          [appendTo]="'body'"
          [options]="locations"
          [virtualScroll]="true"
          [virtualScrollItemSize]="38"
          [panelStyleClass]="'items-no-padding xs'"
          [styleClass]="'d-flex flex-grow-1 analytics-filter xs'">
          <ng-template let-location pTemplate="item">
            <span [ngClass]="location.styleClass">{{ location.label }}</span>
          </ng-template>
        </p-multiSelect>
        <!-- <button [pTooltip]="'ANALYTICS_FILTER.SWITCH_DETAIL_SELECTION' | translate" class="btn btn-secondary border-0 ms-2">
          <i class="fa ta-icon-filtering"></i>
        </button> -->
                <!-- (mouseup)="onAdvancedFilterClicked( fieldLocation, locationActor )"></button> -->
      </div>
    </div>

    <div class="card filter-genarts ms-2 flex-grow-1" *ngIf="showGenericArticleFilter" tourAnchor="genart-filter-tour">
      <div class="card-body d-flex flex-column p-0">
        <span class="filter-label">
          <i class="filter-icon fa ta-icon-subgroup" aria-hidden="true"></i>
          {{ 'ANALYTICS_FILTER.PRODUCT_GROUPS' | translate }}
        </span>
        <genarts-filter formGroupName="genarts"></genarts-filter>
      </div>
    </div>
  </div>

  <!-- <filter-dialog #filterDialog></filter-dialog> --> <!-- TODO: implement filter-dialog -->
</form>
