export const NumberPlates = {
  defaultId: 12,
  availableSearches: [
    {
      icon: 'DK',
      label: 'CATALOGUE.NUMBER_PLATE_DK_AIS',
      country: 'DK',
      keySystemType: 92,
      maxLength: 8,
      id: 0,
    },
    {
      icon: 'DK',
      label: 'CATALOGUE.NUMBER_PLATE_DK_AUTIG',
      country: 'DK',
      keySystemType: 55,
      maxLength: 8,
      id: 1,
    },
    {
      icon: 'ES',
      label: 'CATALOGUE.NUMBER_PLATE_ES',
      country: 'ES',
      keySystemType: 99,
      maxLength: 20,
      id: 2,
    },
    {
      icon: 'FI',
      label: 'CATALOGUE.NUMBER_PLATE_FI_AIS',
      country: 'FI',
      keySystemType: 94,
      maxLength: 7,
      id: 3,
    },
    {
      icon: 'FI',
      label: 'CATALOGUE.NUMBER_PLATE_FI_KGK',
      country: 'FI',
      keySystemType: 95,
      maxLength: 7,
      id: 4,
    },
    {
      icon: 'FR',
      label: 'CATALOGUE.NUMBER_PLATE_FR_SIV',
      country: 'FR',
      keySystemType: 50,
      maxLength: 9,
      id: 5,
    },
    {
      icon: 'FR',
      label: 'CATALOGUE.NUMBER_PLATE_FR_MINE',
      country: 'FR',
      keySystemType: 21,
      maxLength: 20,
      id: 6,
    },
    {
      icon: 'GB',
      label: 'CATALOGUE.NUMBER_PLATE_GB_HPI',
      country: 'GB',
      keySystemType: 61,
      maxLength: 8,
      id: 7,
    },
    {
      icon: 'GB',
      label: 'CATALOGUE.NUMBER_PLATE_GB_CARTELL',
      country: 'IE',
      keySystemType: 99,
      maxLength: 8,
      id: 8,
    },
    {
      icon: 'IE',
      label: 'CATALOGUE.NUMBER_PLATE_IE',
      country: 'IE',
      keySystemType: 99,
      maxLength: 20,
      id: 9,
    },
    {
      icon: 'IT',
      label: 'CATALOGUE.NUMBER_PLATE_IT_RTWS',
      country: 'IT',
      keySystemType: 91,
      maxLength: 7,
      id: 10,
    },
    {
      icon: 'IT',
      label: 'CATALOGUE.NUMBER_PLATE_IT_CARDATA',
      country: 'IT',
      keySystemType: 50,
      maxLength: 7,
      id: 11,
    },
    {
      icon: 'DE',
      label: 'CATALOGUE.NUMBER_PLATE_DE',
      country: 'DE',
      keySystemType: 20,
      maxLength: 9,
      id: 12,
    },
    {
      icon: 'NL',
      label: 'CATALOGUE.NUMBER_PLATE_NL',
      country: 'NL',
      keySystemType: 1,
      maxLength: 8,
      id: 13,
    },
    {
      icon: 'NO',
      label: 'CATALOGUE.NUMBER_PLATE_NO_KGK',
      country: 'NO',
      keySystemType: 95,
      maxLength: 7,
      id: 14,
    },
    {
      icon: 'NO',
      label: 'CATALOGUE.NUMBER_PLATE_NO_AIS',
      country: 'NO',
      keySystemType: 97,
      maxLength: 7,
      id: 15,
    },
    {
      icon: 'PT',
      label: 'CATALOGUE.NUMBER_PLATE_PT',
      country: 'PT',
      keySystemType: 99,
      maxLength: 20,
      id: 16,
    },
    {
      icon: 'SE',
      label: 'CATALOGUE.NUMBER_PLATE_SE_TECDOC',
      country: 'SE',
      keySystemType: 3,
      maxLength: 7,
      id: 17,
    },
    {
      icon: 'SE',
      label: 'CATALOGUE.NUMBER_PLATE_SE_KGK',
      country: 'SE',
      keySystemType: 95,
      maxLength: 7,
      id: 18,
    },
    {
      icon: 'SE',
      label: 'CATALOGUE.NUMBER_PLATE_SE_AIS',
      country: 'SE',
      keySystemType: 93,
      maxLength: 7,
      id: 19,
    },
    {
      icon: 'AT',
      label: 'CATALOGUE.NUMBER_PLATE_AT',
      country: 'AT',
      keySystemType: 6,
      maxLength: 7,
      id: 20,
    },
    {
      icon: 'CH',
      label: 'CATALOGUE.NUMBER_PLATE_CH',
      country: 'CH',
      keySystemType: 4,
      maxLength: 6,
      id: 21,
    },
  ]
};
