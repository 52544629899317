<div class="card rounded-0" [ngClass]="panelStyleClass" [ngStyle]="panelStyle">
  <div class="card-header d-flex align-items-center justify-content-between pe-0 py-0">
    <!-- Left side -->
    <div class="flex-shrink-0 py-2">
      <span >{{ headerLabel }}</span>
      <span *ngIf="infoText" [pTooltip]="infoText" tooltipPosition="bottom" class="ms-1" [tourAnchor]="addTourAnchors ? 'tooltip-tour' : undefined">
        <i class="fa fa-question-circle"></i>
      </span>
      <input *ngIf="showSearch"
        [pTooltip]="searchTooltip | translate"
        tooltipPosition="right"
        [escape]="false"
        class="ms-2"
        type="text"
        placeholder="Search"
        (keyup.enter)="onSpecialSearch($event)">
    </div>

    <!-- Center -->
    <div class="flex-grow-1 text-center py-2">
      <span>{{ dateRangeLabel }}</span>
    </div>

    <!-- Right side -->
    <div class="flex-shrink-0 d-flex align-items-center h-100">
      <!-- OE Coverage -->
      <p-toggleButton *ngIf="showCoverageToggle"
        [(ngModel)]="showCoverage"
        [pTooltip]="oeCoverageTooltip"
        tooltipPosition="top"
        [styleClass]="'py-1 px-2'"
        [style]="{'fontSize': '14px'}"
        [onLabel]="oeCoverageText"
        [offLabel]="oeCoverageText">
      </p-toggleButton>

      <!-- Top 10, 20, 30 -->
      <p-dropdown *ngIf="showTopDropdown && !isTimelineChartType"
        [options]="topOptions"
        [(ngModel)]="selectedTopOptions"
        (onChange)="onChangeTop($event)"
        class="mx-2"
        [styleClass]="'rounded-0 xs'"
        [panelStyleClass]="'items-no-padding xs'">
      </p-dropdown>

      <!-- Timeline datasets -->
      <p-multiSelect *ngIf="showDatasetsSelector"
        [options]="timelineDatasets"
        [selectionLimit]="timelineDatasetLimit"
        [ngModel]="selectedTimelineDatasets"
        (ngModelChange)="onSelectedTimelineDatasetsChange($event)"
        [pTooltip]="timelineTooltip"
        tooltipPosition="left"
        [appendTo]="'body'"
        [overlayOptions]="{contentStyleClass: 'datasets items-no-padding'}"
        [placeholder]="'CHART_PANEL.DATASOURCES_PLACEHOLDER' | translate"
        optionLabel="label"
        [panelStyleClass]="'items-no-padding xs'"
        [styleClass]="'d-flex flex-grow-1 rounded-0 xs'"
        class="mx-2">
        <ng-template let-value pTemplate="selectedItems">
          {{ value?.length || 0 }} {{'CHART_PANEL.SELECTED_DATASOURCES' | translate}}
        </ng-template>
        <ng-template let-dataset let-i="index" pTemplate="item">
          <div style="width: 10px; height: 20px; float: left; margin-right: 5px;"
            [ngStyle]="{ 'background-color': dataset.color }">
          </div>
          {{dataset.label}}
        </ng-template>
      </p-multiSelect>

      <!-- Export button -->
      <button *ngIf="enableExport && !isAnyDownloadInProgress" [tourAnchor]="addTourAnchors ? 'exp-report-tour' : undefined"
        [pTooltip]="'COMMON.SAVE' | translate"
        tooltipPosition="left"
        class="btn text-primary border-0 border-start rounded-0 py-0 px-2 h-100"
        (click)="saveMenu.toggle($event)"
        (keyup.enter)="saveMenu.toggle($event)">
        <i class="fa ta-icon-download text-black"></i>
      </button>
      <p-menu #saveMenu [model]="saveMenuOptions" [popup]="true" appendTo="body" [styleClass]="'items-no-padding text-decoration-none'"></p-menu>

      <!-- Switch to line chart button -->
      <button *ngIf="showTimelineButton"
        [pTooltip]="showTimelineButtonTooltip | translate"
        tooltipPosition="left"
        class="btn text-primary border-0 border-start rounded-0 py-0 px-2 h-100"
        (click)="onShowTimelineButtonClick($event)">
        <i class="fa text-black" [ngClass]="isTimelineChartType ? 'ta-icon-chart-bar' : 'ta-icon-chart-line'"></i>
      </button>

      <!-- Switch to dougnhut chart button -->
      <button *ngIf="enableChartTypeToggle && !isTimelineChartType"
        [pTooltip]="showDoughnutButtonTooltip | translate"
        tooltipPosition="left"
        class="btn text-primary border-0 border-start rounded-0 py-0 px-2 h-100"
        (click)="onToggleChartTypeClick($event)">
        <i class="fa text-black" [ngClass]="{'ta-icon-chart-pie': isBarChartType, 'ta-icon-chart-bar': isDoughnutChartType}"></i>
      </button>
    </div>
  </div>
  <div class="card-body row" [style.maxHeight.%]="90">
    <div class="mh-100" [ngClass]="timelineDatasets?.length ? 'col-11' : 'col-12'" [tourAnchor]="addTourAnchors ? 'drill-down-tour' : undefined">
      <p-chart #chart *ngIf="isBarChartType" type="bar" [data]="chartData" [options]="chartOptions" [height]="'100%'"></p-chart>
      <p-chart #chart *ngIf="isDoughnutChartType" type="doughnut" [data]="chartData" [options]="chartOptions" [height]="'100%'"></p-chart>
      <p-chart #chart *ngIf="isTimelineChartType" type="line" [data]="chartData" [options]="chartOptions" [height]="'100%'"></p-chart>
    </div>
    <div *ngIf="showAggregationOptions" class="col-1 text-end">
      <div class="d-flex flex-column">
        <p-dropdown
          [(ngModel)]="selectedAggregationOption"
          (ngModelChange)="onSelectedAggregationOptionChange($event)"
          [options]="aggregationOptions"
          [optionLabel]="'label'"
          [styleClass]="'rounded-0'"
          [panelStyleClass]="'items-no-padding'">
          <ng-template pTemplate="selectedItem">
            {{ selectedAggregationOption.label | translate }}
          </ng-template>
          <ng-template let-option pTemplate="item">
            {{ option.label | translate }}
          </ng-template>
        </p-dropdown>
        <div *ngIf="showAggregationWeekdaysFilter && isDailyAggregationSelected" class="mt-3 d-flex flex-column">
          <p-checkbox *ngFor="let weekday of weekdays" class="my-1"
            [label]="weekday.label"
            [value]="weekday.value"
            [(ngModel)]="selectedAggregationWeekdays"
            (ngModelChange)="onAggregationWeekdaysChange($event)">
          </p-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
