export const RmiTopicCriteriaFilter =
  [{
    'GroupDescription': '',
    'KindOfFilter': 5,
    'isOptional': false,
    'FilterValues': [{
      'CriteriaID': 0,
      'CriteriaText': 'Standard'
    }]
  }, {
    'GroupDescription': '',
    'KindOfFilter': 1,
    'isOptional': true,
    'FilterValues': [{
      'CriteriaID': 1,
      'CriteriaText': 'links'
    }, {
      'CriteriaID': 2,
      'CriteriaText': 'rechts'
    }, {
      'CriteriaID': 3,
      'CriteriaText': 'oben'
    }, {
      'CriteriaID': 13,
      'CriteriaText': 'Mitte'
    }, {
      'CriteriaID': 25,
      'CriteriaText': 'unten'
    }]
  }, {
    'GroupDescription': '',
    'KindOfFilter': 2,
    'isOptional': true,
    'FilterValues': [{
      'CriteriaID': 3,
      'CriteriaText': 'aus- und einbauen'
    }, {
      'CriteriaID': 7,
      'CriteriaText': 'erneuern'
    }]
  }, {
    'GroupDescription': '',
    'KindOfFilter': 3,
    'isOptional': true,
    'FilterValues': [{
      'CriteriaID': 20,
      'CriteriaText': 'Motorraum-Unterschutz'
    }, {
      'CriteriaID': 60,
      'CriteriaText': 'Auslassnockenwelle'
    }, {
      'CriteriaID': 147,
      'CriteriaText': 'Einlassnockenwelle'
    }, {
      'CriteriaID': 405,
      'CriteriaText': 'Kurbelwellendichtring (Getriebegegenseite)'
    }, {
      'CriteriaID': 406,
      'CriteriaText': 'Kurbelwellendichtring (Getriebeseite)'
    }, {
      'CriteriaID': 459,
      'CriteriaText': 'Motor'
    }, {
      'CriteriaID': 462,
      'CriteriaText': 'Motor mit Getriebe'
    }, {
      'CriteriaID': 466,
      'CriteriaText': 'Motorabdeckung'
    }, {
      'CriteriaID': 468,
      'CriteriaText': 'Motorlager'
    }, {
      'CriteriaID': 492,
      'CriteriaText': 'Öldruckschalter'
    }, {
      'CriteriaID': 493,
      'CriteriaText': 'Motorölfilter'
    }, {
      'CriteriaID': 495,
      'CriteriaText': 'Ölfilterflansch'
    }, {
      'CriteriaID': 496,
      'CriteriaText': 'Ölpumpe'
    }, {
      'CriteriaID': 498,
      'CriteriaText': 'Ölwanne'
    }, {
      'CriteriaID': 625,
      'CriteriaText': 'Schwingungsdämpfer'
    }, {
      'CriteriaID': 626,
      'CriteriaText': 'Schwungrad'
    }, {
      'CriteriaID': 709,
      'CriteriaText': 'Spannrolle(n) für Zahnriemen'
    }, {
      'CriteriaID': 753,
      'CriteriaText': 'Zahnriemen'
    }, {
      'CriteriaID': 833,
      'CriteriaText': 'Umlenkrolle für Zahnriemen'
    }, {
      'CriteriaID': 839,
      'CriteriaText': 'Vakuumpumpe'
    }, {
      'CriteriaID': 840,
      'CriteriaText': 'Ventildeckel'
    }, {
      'CriteriaID': 843,
      'CriteriaText': 'Ventildeckeldichtung'
    }, {
      'CriteriaID': 909,
      'CriteriaText': 'Wärmetauscher Motoröl/Kühlmittel'
    }, {
      'CriteriaID': 980,
      'CriteriaText': 'Zylinderkopf'
    }, {
      'CriteriaID': 983,
      'CriteriaText': 'Zylinderkopfdichtung'
    }, {
      'CriteriaID': 1605,
      'CriteriaText': 'Nockenwellensatz'
    }, {
      'CriteriaID': 2432,
      'CriteriaText': 'Reparatursatz für Zahnriemen'
    }, {
      'CriteriaID': 2496,
      'CriteriaText': 'Ölablassschraube'
    }, {
      'CriteriaID': 2836,
      'CriteriaText': 'Zylinderkopfschrauben'
    }, {
      'CriteriaID': 5382,
      'CriteriaText': 'Drehmomentstütze für Motor'
    }, {
      'CriteriaID': 5387,
      'CriteriaText': 'Lager für Drehmomentstütze'
    }]
  }, {
    'GroupDescription': 'Bauzeitraum',
    'KindOfFilter': 4,
    'isOptional': true,
    'FilterValues': [{
      'CriteriaID': 204,
      'CriteriaText': 'ab 2005/06'
    }, {
      'CriteriaID': 407,
      'CriteriaText': 'bis 2005/05'
    }]
  }, {
    'GroupDescription': 'Getriebe',
    'KindOfFilter': 4,
    'isOptional': true,
    'FilterValues': [{
      'CriteriaID': 1280,
      'CriteriaText': 'mit automatisiertem Schaltgetriebe'
    }, {
      'CriteriaID': 7014,
      'CriteriaText': '6 Gang 02Q'
    }]
  }, {
    'GroupDescription': 'Motor',
    'KindOfFilter': 4,
    'isOptional': true,
    'FilterValues': [{
      'CriteriaID': 9369,
      'CriteriaText': 'Motor AZV'
    }, {
      'CriteriaID': 14489,
      'CriteriaText': 'Motor CBAA'
    }, {
      'CriteriaID': 17069,
      'CriteriaText': 'Motor CFFA'
    }]
  }];
