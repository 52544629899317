<div class="login-form">
  <div class="inner-login-form">
    <form [formGroup]="form">
      <fieldset>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <h3 class="text-left text-secondary">{{ formTitle | translate }}</h3>
            </div>
          </div>
          <!-- Error message -->
          <div class="row my-2" *ngIf="loginError" id="error-message">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <p class="badge badge-danger text-danger text-wrap" style="text-align: left; font-weight: normal; line-height: 18px" [innerHTML]="errorMessage"></p>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="text" id="userName" maxlength="50" class="required form-control"
                  [placeholder]="'FORGOT_PASSWORD.USERNAME_OR_EMAIL_PLACEHOLDER' | translate" formControlName="userNameOrEmail"
                  [ngClass]="{'border-2 border-danger': getFormControlErrors('userNameOrEmail') && showErrorStyles}"
                  [pTooltip]="getFormControlErrorMessages('userNameOrEmail') | translate" tooltipPosition="top" tooltipToValidateInput="true" (keyup)="saveUserNameOrEmail($event)"
                  [readOnly]="tempCodeSended">
              </div>
            </div>
          </div>
          <div class="row my-2" [hidden]="!tempCodeSended || isPasswordChange">
            <div class="col-lg-12 col-md-12 col-xs-12" title="Email ID">
              &nbsp;&nbsp;#{{email_uuid}}
            </div>
          </div>
          <div class="row my-2" [hidden]="!tempCodeSended || isPasswordChange">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="text" id="tempCode" maxlength="25" class="required form-control"
                  [ngClass]="{'border-2 border-danger': getFormControlErrors('tempCode') && showErrorStyles}"
                  [placeholder]="'FORGOT_PASSWORD.ENTER_TOKEN_PLACEHOLDER' | translate" formControlName="tempCode"
                  [pTooltip]="getFormControlErrorMessages('tempCode') | translate" tooltipPosition="top" tooltipToValidateInput="true" >
              </div>
            </div>
          </div>
          <div class="row my-2" [hidden]="!tempCodeSended">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input onfocus="this.type = 'password';" type="text" id="newPassword" maxlength="25" class="required form-control"
                  [ngClass]="{'border-2 border-danger': getFormControlErrors('newPassword') && showErrorStyles}"
                  [placeholder]="'FORGOT_PASSWORD.PASSWORD_PLACEHOLDER' | translate" formControlName="newPassword"
                  [pTooltip]="getFormControlErrorMessages('newPassword') | translate" tooltipPosition="top" tooltipToValidateInput="true"
                  autocomplete="new-password">
              </div>
            </div>
          </div>
          <div class="row my-2" [hidden]="!tempCodeSended">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input onfocus="this.type = 'password';" type="text" id="repeatNewPassword" maxlength="25" class="required form-control"
                  [ngClass]="{'border-2 border-danger': getFormControlErrors('repeatNewPassword') && showErrorStyles}"
                  [placeholder]="'FORGOT_PASSWORD.REPEAT_PASSWORD_PLACEHOLDER' | translate" formControlName="repeatNewPassword"
                  pTooltip [pTooltip]="getFormControlErrorMessages('repeatNewPassword') | translate" tooltipPosition="top" tooltipToValidateInput="true"
                  autocomplete="new-password">
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <button [hidden]="isPasswordChange" class="btn btn-primary" (click)="resetState()"><</button>
            <button [disabled]="countdownWaitTimerHandle" class="btn btn-block btn-primary" (click)="onResetPassword()">{{buttonText}}</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>

