import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { ContextService } from '../core/context';
import { StorageService } from '../core/storage';

import { Observable, EMPTY } from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';

import { AuthTypes } from '../../security/auth-types';
import { AuthResponse } from '../../security/auth-response';
import { UserDetail } from '../../security/user-detail';
import { NavigationService } from '../miscellany/navigation';

import { CoreModuleConfig } from '../../core-module-config';

import { AuthBaseService } from './auth-base';

import { timeout } from 'rxjs/operators';
import { Router } from '@angular/router';

export class OAuthResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  user_detail: string;
}

/**
 * @file oauth2.service.js
 * @module app.core
 * @summary Core service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2018
 */
@Injectable()
export class OAuth2Service extends AuthBaseService {
  //#region Authentication Properties

  public get type(): AuthTypes {
    return AuthTypes.Oauth2;
  }

  //#endregion

  //#region Constructor

  constructor(
    navigation: NavigationService,
    config: CoreModuleConfig,
    storage: StorageService,
    context: ContextService,
    http: HttpClient,
    router: Router,
  ) {
    super(navigation, config, context, storage, http, router);
  }

  //#endregion

  //#region Authentication Methods

  public login(body: any): Observable<AuthResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    const params: URLSearchParams = new URLSearchParams();
    params.append(`username`, `${body.userName}`);
    params.append(`password`, `${body.password}`);
    params.append(`client_id`, `${this.context.instance.clientId}`);
    // params.append(`client_secret`, `${this._context.instance.clientSecret}`);
    // params.push(`scope=`);
    params.append(`grant_type`, `password`);

    return this.http
      .post<OAuthResponse>(
        `${this.context.instance.auth.endpoint}`,
        params.toString(),
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        map((response: HttpResponse<OAuthResponse>) => {
          const result = new AuthResponse();
          result.status = response.status;
          result.message = response.statusText;

          if (response.ok) {
            const data = response.body;

            this.setAccessToken(data.access_token);
            this.setRefreshToken(data.refresh_token);

            const expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + data.expires_in);
            this.setExpirationDate(expiration);

            const userDetail = this.config.userFactory
              ? this.config.userFactory(data)
              : JSON.parse(data.user_detail) as UserDetail;
            result.userDetail = userDetail;
            this.setUser(userDetail);

            this.loggedIn.emit();
          }

          return result;
        }),
        share()
      );
  }

  public refresh(body?: any): Observable<AuthResponse> {
    if (!this.isLogged) {
      return EMPTY;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    const params: URLSearchParams = new URLSearchParams();
    params.append(`username`, `${this.userDetail.email}`);
    params.append(`refresh_token`, `${this.refreshToken}`);
    params.append(`client_id`, `${this.context.instance.clientId}`);
    // params.append(`client_secret`, `${this._context.instance.clientSecret}`);
    // params.push(`scope=`);
    params.append(`grant_type`, `refresh_token`);

    return this.http
      .post<OAuthResponse>(
        `${this.context.instance.auth.endpoint}`,
        params.toString(),
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        catchError((err: any, caught: Observable<HttpResponse<OAuthResponse>>) => {
          this.logout();
          return EMPTY;
        }),
        map((response: HttpResponse<OAuthResponse>) => {
          const result = new AuthResponse();
          result.status = response.status;
          result.message = response.statusText;

          if (response.ok) {
            const data = response.body;

            this.setAccessToken(data.access_token);
            this.setRefreshToken(data.refresh_token);

            const expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + data.expires_in);
            this.setExpirationDate(expiration);

            const userDetail = this.config.userFactory
              ? this.config.userFactory(data)
              : JSON.parse(data.user_detail) as UserDetail;
            result.userDetail = userDetail;
            this.setUser(userDetail);

            this.refreshed.emit();
          } else {
            this.logout();
          }

          return result;
        }),
        share()
      );
  }

  //#endregion
}
