import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

import { ApiService } from '@app/shared/services/api.service';
import { GenArt } from '../analytics-filter-data';

import { EMPTY, combineLatest, switchMap, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

type FavoriteMenuItem = MenuItem & { genart: GenArt[], ids: Set<number> };

@Component({
  selector: 'genarts-filter',
  templateUrl: './genarts-filter.component.html',
  styleUrl: './genarts-filter.component.scss'
})
export class GenartsFilterComponent implements OnInit {
  formGroup: FormGroup<{
    genarts: FormControl<GenArt[]>
  }>;

  genarts: GenArt[];
  genartFavorites: FavoriteMenuItem[];

  private readonly SETTING_GENERIC_ARTICLE_FAVORITES = 'genericArticleFavorites';

  constructor(
    private rootFormGroup: FormGroupDirective,
    private api: ApiService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.rootFormGroup.control;

    this.api.getResources('genericArticles2')
      .subscribe(genarts => this.genarts = Object.entries(genarts)
        .map(([key, value]) => ({ id: +key, label: `[${key}] ${value}` }))
      );

    this.refreshFavorites().subscribe(() => {});
  }

  /**
   * Retrieves the genarts favorites stored settings and configures the related selector
   *
   * @returns The genarts favorites stored settings
   */
  private refreshFavorites() {
    const saveAsFavoriteKey = 'GENARTS_FILTER.SAVE_AS_FAVORITE';

    return combineLatest([
      this.api.getSettings(this.SETTING_GENERIC_ARTICLE_FAVORITES, true),
      this.translate.get([saveAsFavoriteKey])
    ])
    .pipe(
      tap(([settings, translations]) => {
        let jsonResponse: any[] = JSON.parse(settings?.message || '[]');

        // Transform legacy favorite format into new one
        if (jsonResponse.length && !Array.isArray(jsonResponse[0])) {
          try {
            jsonResponse = jsonResponse.map(fav => {
              const ids = (fav['id'] as string).split(',');
              const labels = (fav['name'] as string).split('; ');

              return ids.map((id, i) => (<GenArt>{
                id: +id,
                label: labels[i],
              }));
            });
          }
          catch {
            jsonResponse = [];
          }
        }

        const genartFavorites: GenArt[][] = jsonResponse;
        this.genartFavorites = genartFavorites.map(genArtGroup => <FavoriteMenuItem>({
          genart: genArtGroup,
          ids: new Set(genArtGroup.map(ga => ga.id)),
          label: genArtGroup.map(ga => ga.label).join(', '),
        }));
        this.genartFavorites.forEach(genartFav => genartFav.command = (event) => this.onFavoriteSelection(event));
        this.genartFavorites.unshift(<FavoriteMenuItem>{
          genart: [],
          ids: new Set(),
          label: translations[saveAsFavoriteKey],
          command: () => this.onSaveFavorite(),
        });
      })
    );
  }

  private onSaveFavorite() {
    const selectedGenarts = this.formGroup.get('genarts').value
    const selectedGenartsIds = selectedGenarts.map(x => x.id);
    if (!selectedGenartsIds.length) {
      return;
    }

    // Discard favorite IDs maps that don't have the same size than current selection
    const favoritesIds = this.genartFavorites.map(x => x.ids).filter(x => x.size === selectedGenartsIds.length);

    for (const favoritesId of favoritesIds) {
      if (selectedGenartsIds.every(x => favoritesId.has(x))) {
        // If every selected genart ID is on one of the favorite IDs map, then no need to add a favorite -> return;
        return;
      }
    }

    this.api.saveSettings(
      this.SETTING_GENERIC_ARTICLE_FAVORITES,
      JSON.stringify([...this.genartFavorites.filter(x => x.genart.length).map(x => x.genart) , selectedGenarts])
    )
    .pipe(
      switchMap(response => {
        return response.statusCode === 200
          ? this.refreshFavorites()
          : EMPTY
      })
    )
    .subscribe();
  }

  onDeleteFavorite(favoriteGenart: FavoriteMenuItem) {
    const selectedGenartsIds = favoriteGenart.genart.map(x => x.id);
    const favoritesIds = this.genartFavorites.map(x => x.ids);

    let indexToRemove = -1;
    for (const [index, favoritesId] of favoritesIds.entries()) {
      if (selectedGenartsIds.every(x => favoritesId.has(x))) {
        indexToRemove = index;
        break;
      }
    }

    if (indexToRemove < 0) {
      return;
    }

    this.genartFavorites.splice(indexToRemove, 1);

    this.api.saveSettings(
      this.SETTING_GENERIC_ARTICLE_FAVORITES,
      JSON.stringify(this.genartFavorites.filter(x => x.genart.length).map(x => x.genart))
    )
    .pipe(
      switchMap(response => {
        return response.statusCode === 200
          ? this.refreshFavorites()
          : EMPTY
      })
    )
    .subscribe();
  }

  private onFavoriteSelection(event: MenuItemCommandEvent) {
    const selection = event.item as FavoriteMenuItem;
    const genart = selection?.genart;
    if (!genart) {
      return;
    }

    this.formGroup.get('genarts').setValue(genart);
    this.formGroup.markAsDirty();
  }
}
