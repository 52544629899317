export const DISCART_CHANGE_TITLE         = 'Discard changes?';
export const DISCART_CHABGE_MESSAGE       = 'Do you really want to discard the changes?';

export const LOGOUT_CONFIRM_TITLE         = 'LOGOUT';
export const LOGOUT_CONFIRM_MESSAGE       = 'Do you really want to logout?';

export const CONFIRM_DELETE_TITLE         = 'Warning';
export const CONFIRM_DELETE_MESSAGE       = 'Do you really want to delete it?';

export const CONFLICT_DELETE_USER_TITLE   = 'Conflict';
export const CONFLICT_DELETE_USER_MESSAGE = 'You can\'t delete yourself';

export const MAX_3_TIMES_PER_DAY_FORGOT_PASSWORD = 'User password cannot be reset more than 3 times a day.\nPlease try again resetting your password tomorrow.';
