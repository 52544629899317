import { Component } from '@angular/core';

import { PageComponent } from '@app/shared/components/page';
import { LoginData } from '@app/anonymous/models/login-data';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginPage extends PageComponent {
  override get pageName(): string {
    return 'Login';
  }

  loginData: LoginData = {
    username: '',
    password: '',
  };

  public override hasChanges(): boolean {
    return false;
  }
}
