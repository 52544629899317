import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { ContextService } from '../core/context';

export interface NavPage {
  id: string;
  name: string;
  route: string;
  query?: string[];
  isHome?: boolean;
  historyOptions?: {
    resetHistory?: boolean;
    resetQueryParams?: boolean
  };
}

export interface NavParams {
  [param: string]: any;
}

export interface NavHistoryItem {
  page: NavPage;
  params?: NavParams;
  queryParams?: NavParams;
  command?: () => Promise<any>;
  label: string;
}

export interface NavOptiosn {
  // custom properties

  pageLabel?: string;
  resetHistory?: boolean;
  notRollback?: boolean;

  // angular properties

  replaceUrl?: boolean;

  // replaceUrl?: boolean;
  // queryParamsHandling?: string;
}

/**
 * Navigation service.
 *
 * @class NavigationService
 */
@Injectable()
export class NavigationService {
  //#region Fields

  private _history: NavHistoryItem[] = [];
  private _currentPage: NavHistoryItem;

  //#endregion

  //#region Properties

  private _pages: {[key: string]: NavPage};

  /**
   * Pages map.
   *
   * @type {{[key: string]: NavPage}}
   */
  public get pages(): {[key: string]: NavPage} {
    return this._pages;
  }

  /**
   * Navigation history.
   *
   * @type {NavHistoryItem[]}
   */
  public get history(): NavHistoryItem[] {
    return this._history;
  }

  //#endregion

  //#region Constructor

  /**
   * Creates an instance of `NavigationService`.
   *
   * @memberof NavigationService
   */
  constructor(
    private context: ContextService,
    private router: Router
  ) {
    try {
      this._pages = Object.freeze( this.context.instance.navigation.pages );
    } catch (err) {
      console.error(err);
    }

    this.tryRestoreHistoryFromUrl();
  }

  //#endregion

  //#region Methods

  /**
   * Tries to decode the history from the url:
   */
  public tryRestoreHistoryFromUrl() {
    try {
      this._history = this.decodeHistory(location.hash) || [];
    } catch (err) {
      console.error(err);
      this._history = [];
    }
  }

  /**
   * Given a page id, allows update its label and parameters on the history. Only updates
   * the specify data, the rest of parameters are keeped.
   *
   * @param {string} pageId
   * @param {string} pageLabel
   * @param {NavParams} params
   * @param {NavParams} queryParams
   */
  public updateHistoryItem(pageId: string, pageLabel?: string, params?: NavParams, queryParams?: NavParams) {
    const historyItem = this.findHistoryItem(pageId);

    if (!historyItem) {
      return;
    }

    if (pageLabel) {
      historyItem.label = pageLabel;
    }

    const newParams = { ...(historyItem.params || {}), ...(params || {}) };
    const newQueryParams = { ...(historyItem.queryParams || {}), ...(queryParams || {}) };

    historyItem.params = Object.keys(newParams).length ? newParams : undefined;
    historyItem.queryParams = Object.keys(newQueryParams).length ? newQueryParams : undefined;
  }

  /**
   * Finds all the matched parameters on the history and updates them (both in params and query params).
   * Only updates the specify data, the rest of parameters are keeped.
   *
   * @param {NavParams} params
   */
  public updateHistoryParams(params: NavParams) {
    if (!params) {
      return;
    }

    this.history.forEach(item => {
      for (const paramName in params) {
        if (item.params && item.params[paramName]) {
          item.params[paramName] = params[paramName];
        }

        if (item.queryParams && item.queryParams[paramName]) {
          item.queryParams[paramName] = params[paramName];
        }
      }
    });
  }

  /**
   * Given a page id, find the page in the history.
   *
   * @param {string} pageId
   * @return {NavHistoryItem}
   */
  public findHistoryItem(pageId: string): NavHistoryItem {
    const index = this.findLastIndexOf(pageId);

    if (index >= 0) {
      return this._history[index];
    }

    return null;
  }

  /**
   * Given a history page id, returns its url.
   *
   * @public
   * @memberof NavigationService
   * @param {number} pageId page id to navigate
   */
  public getHistoryUrlData(pageId: string): {url: string; queryParams?: {[k: string]: any}; fragment?: string} {
    const index = this.findLastIndexOf(pageId);
    if (index < 0) {
      return { url: '' };
    }

    const history = this._history.slice(0, index + 1);
    const historyItem = history[index];
    const url = this.getUrl(historyItem.page.route, historyItem.params);

    return {
      url: url,
      queryParams: historyItem.queryParams,
      fragment: this.encodeHistory(history)
    };
  }

  /**
   * Given a history page id, returns its url.
   *
   * @public
   * @memberof NavigationService
   * @param {number} pageId page id to navigate
   */
  public getHistoryUrl(pageId: string): string {
    const data = this.getHistoryUrlData(pageId);

    if (!data.url) {
      return undefined;
    }

    if (!data.queryParams) {
      return !data.fragment ? `${data.url}` : `${data.url}#${data.fragment}`;
    }

    const query = [];
    for (const key in data.queryParams) {
      query.push([key, data.queryParams[key]].join('='));
    }

    return !data.fragment ? `${data.url}?${query.join('&')}` : `${data.url}?${query.join('&')}#${data.fragment}`;
  }

  /**
   * Rollback to the previous page.
   */
  public back() {
    if (this.history.length <= 1) {
      return;
    }

    this.rollbackAt(this.history.length - 2);
  }

  /**
   * Given a history page id, rolls back to this page.
   *
   * @public
   * @memberof NavigationService
   * @param {number} pageId page id to navigate
   * @param {NavParams} params page params
   * @param {NavParams} queryParams page query params
   * @param {boolean} ignoreLastItem True by default
   */
  public rollback(pageId: string, params?: NavParams, queryParams?: NavParams, ignoreLastItem = true) {
    const index = this.findLastIndexOf(pageId);
    if (index < 0) {
      return;
    }

    this.rollbackAt(index, params, queryParams, ignoreLastItem);
  }

  /**
   * Given a history page index, rolls back to this page.
   *
   * @public
   * @memberof NavigationService
   * @param {number} index page index to navigate
   * @param {NavParams} params page params
   * @param {NavParams} queryParams page query params
   * @param {boolean} ignoreLastItem
   */
  public rollbackAt(index: number, params?: NavParams, queryParams?: NavParams, ignoreLastItem = true) {
    const limit = this._history.length - (ignoreLastItem ? 2 : 1 );
    if (index < 0 || index > limit) {
      return;
    }

    const removed = this._history.splice(index, this._history.length - index);
    const target = removed.shift();

    if (!target) {
      return;
    }

    let command = target.command;

    if (!command) {
      command = () => new Promise<any | void>(resolve => resolve(resolve));
    }

    command().then(() => {
      this.go(target.page, params || target.params, queryParams || target.queryParams, {
        notRollback: true,
        pageLabel: target.label
      });
    });
  }

  /**
   * Set a history item as current page.
   *
   * @public
   * @param {NavHistoryItem} item
   * @memberof NavigationService
   */
  public setCurrentPage(item: NavHistoryItem) {
    if (!item) {
      throw Error('page undefined');
    }

    this.checkParams(item.page, item.params, item.queryParams);

    this._currentPage = item;
    this.pushCurrentPage();
  }

  /**
   * Given a navigation page item and its params, navigates to the page.
   *
   * @public
   * @memberof NavigationService
   * @param {NavPage} page page to navigate
   * @param {NavParams} params page params
   * @param {NavParams} queryParams page query params
   * @param {NavOptiosn} optiosn label to be used in the history for the current page
   */
  public go(page: NavPage, params?: NavParams, queryParams?: NavParams, options?: NavOptiosn) {
    if (!page) {
      throw Error('page undefined');
    }

    if ((page.historyOptions && page.historyOptions.resetHistory)
      || (this._currentPage && this._currentPage.page.isHome)
      || (options && options.resetHistory)) {
      this.clearHistory();
    }

    if (!options || !options.notRollback) {
      const index = this.findLastIndexOf(page.id);
      if (index > -1) {
        this.updateHistoryItem(page.id, options && options.pageLabel);
        this.rollbackAt(index, params, queryParams, false);
        return;
      }
    }

    // Initilize params becuase `checkParams` method could add mandatory parameters
    // specified in nav settings.
    if (!params) {
      params = {};
    }

    this.setCurrentPage(<NavHistoryItem>{
      page: page,
      label: options && options.pageLabel,
      params: params,
      queryParams: !page.historyOptions || !page.historyOptions.resetQueryParams ? queryParams : null,
    });

    const history = this.encodeHistory();
    const extras = <NavigationExtras>{
      fragment: history || undefined,
      queryParams: queryParams || undefined,
      replaceUrl: options && options.replaceUrl
      // queryParamsHandling: options && options.queryParamsHandling
    };

    this.router
      .navigate([this.getUrl(page.route, params)], extras)
      .catch((err) => {
        console.error(err);

        if (!this._history.length) {
          return;
        }

        if (this._history[this._history.length - 1].page.id === this._currentPage.page.id) {
          this._history.pop();
          this._currentPage = this._history.length
            ? this._history[this._history.length - 1]
            : null;
        }
      });
  }

  public clearHistory() {
    this._currentPage = null;
    this._history = [];
  }

  public resetHistoryAt(level: number) {
    if (level >= this._history.length) {
      return;
    }

    this._history.splice(level);
  }

  //#endregion

  //#region History management

  private findLastIndexOf(pageId: string): number {
    for (let i = this._history.length - 1; i >= 0; i--) {
      if (this._history[i].page.id === pageId) {
        return i;
      }
    }

    return -1;
  }

  private pushCurrentPage() {
    if (!this._currentPage) {
      return;
    }

    if (!this._currentPage.label) {
      console.log(`${this._currentPage.page.name} doesn't have linked a label and it'll no stacked in history`);
      return;
    }

    this._history = [ ...this._history, this._currentPage  ];
  }

  /**
   * Encode page history format.
   *
   * @brc[/pageId:label;paramName:value;paramName:value;...]
   */
  private encodeHistory(history?: NavHistoryItem[]): string {
    let states = [];

    (history || this._history).forEach(item => {
      let state = [ [ item.page.id, encodeURIComponent( item.label || '' ) ].join(':') ];

      [item.params, item.queryParams].forEach(params => {
        if (params && Object.keys(params).length) {
          Object.keys(params).forEach(paramName => {
            state = [ ...state, [ paramName, encodeURIComponent( params[paramName] ) ].join(':') ];
          });
        }
      });

      states = [ ...states, state.join(';') ];
    });

    return states.length ? `@brc/${states.join('/')}` : '';
  }

  /**
   * Decode page history format.
   *
   * @brc[/pageId:label;paramName:value;paramName:value;...]
   */
  private decodeHistory(urlFragment: string): NavHistoryItem[] {
    if (!urlFragment) {
      return null;
    }

    const historyIndex = urlFragment.indexOf('@brc/');

    if (historyIndex < 0) {
      return null;
    }

    const historyFragment = urlFragment.substr( historyIndex );

    const states = historyFragment.split('/');

    // Discard place holder @brc:
    states.shift();

    if (!states || !states.length) {
      return null;
    }

    const history:  NavHistoryItem[] = [];
    states.forEach(state => {
      const peers = state.split(';');
      if (!peers || !peers.length) {
        throw Error('Navigation - Decoding history: a history state can not be empty.');
      }

      const pageData = peers.shift().split(':');

      if (!pageData || pageData.length !== 2) {
        throw Error('Navigation - Decoding history: parameter\'s wrong format.');
      }

      const [ pageId, pageLabel ] = pageData;
      const page = this.getPageById(pageId);

      if (!page) {
        throw Error('Navigation - Decoding history: page not found.');
      }

      const pageParamsList = this.getRouteParams(page.route);
      const params: NavParams = {};
      const queryParams: NavParams = {};

      while (peers && peers.length) {
        const paramsData = peers.shift().split(':');

        if (!paramsData || paramsData.length !== 2) {
          throw Error('Navigation - Decoding history: parameter\'s wrong format.');
        }

        const [ paramName, paramValue ] = paramsData;

        if (pageParamsList.indexOf(paramName) > -1) {
          params[paramName] = decodeURIComponent( decodeURIComponent( paramValue ) );
        } else {
          queryParams[paramName] = decodeURIComponent( decodeURIComponent( paramValue ) );
        }
      }

      if (Object.keys(params).length !== pageParamsList.length) {
        throw Error('Navigation - The params doesn\'t match with the page params');
      }

      const item = <NavHistoryItem>{
        page: page,
        label: decodeURIComponent( decodeURIComponent( pageLabel ) ),
        params: params,
        queryParams: queryParams
      };

      history.push(item);
    });

    return history;
  }

  private getPageById(id: string): NavPage {
    for (const pageKey in this.pages) {
      if (this.pages[pageKey].id === id) {
        return this.pages[pageKey];
      }
    }

    return null;
  }

  //#endregion

  //#region Helpers

  private getUrl(uri: string, params?: NavParams) {
    if (params) {
      const keys = Object.keys(params);

      if (keys && keys.length) {
        for (let i = 0; i < keys.length; i++) {
          const value = params[keys[i]];
          const regex = new RegExp(':' + keys[i], 'ig');
          uri = uri.replace(regex, value);
        }
      }
    }

    return uri;
  }

  private checkParams(page: NavPage, params?: NavParams, queryParams?: NavParams) {
    const pageParams = this.getRouteParams(page.route);

    if (this.context.instance.navigation.contextParams) {
      for (let i = 0; i < this.context.instance.navigation.contextParams.length; i++) {
        const paramName = this.context.instance.navigation.contextParams[i];

        if (params && !params[paramName]) {
          params[paramName] = this.context.instance[paramName];
        }
      }
    }

    if (pageParams && pageParams.length) {
      if (!params) {
        throw Error(`${page.name}: ${pageParams.toString()} are required.`);
      }

      const paramsList = Object.keys(params);
      pageParams.forEach(param => {
        if (paramsList.indexOf(param) < 0) {
          throw Error(`${page.name}: \`${param}\` is required.`);
        }
      });
    }

    if (queryParams && Object.keys(queryParams).length) {
      if (!page.query || !page.query.length) {
        throw Error(`${page.name}: the page doesn't define query params.`);
      }

      if (queryParams && Object.keys(queryParams).length) {
        Object.keys(queryParams).map(param => {
          if (page.query.indexOf(param) < 0) {
            throw Error(`${page.name}: \`${param}\` is not a valid query param for this page.`);
          }
        });
      }
    }
  }

  private getRouteParams(route: string) {
    const regex = /:(\w+)/gmi;
    const params = [];
    let result;
    while ((result = regex.exec(route)) !== null) {
      params.push(result[1]);
    }

    return params;
  }

  //#endregion
}
