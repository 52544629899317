import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { UIChart } from 'primeng/chart';
import { ChartOptions, ChartData, Chart } from 'chart.js'
import { cloneDeep, groupBy, range } from 'lodash';
import moment, { Moment } from 'moment';
import jsPDF from 'jspdf';

import { TaskTrackerService } from '@app/core';
import { TimeLineData } from '@app/shared/models/analytics/time-line-data';
import { DownloadStatus } from '@app/shared/models/download-status';
import { DownloadHandler } from '@app/shared/utils/download-handler';
import { AggregationTime } from '@app/shared/models/analytics/time-line-rows';
import { TranslateService } from '@ngx-translate/core';

export type ChartType = 'bar' | 'doughnut' | 'line';

export type OptionalChartPanelData<T> = {
  chartData: ChartData<ChartType, {id: string, nested: {value: T}}[]>,
  headerLabel?: string,
  infoText?: string,
  facetId?: string,
};

/**
 * Component that represents the panel in which the chart will be represented,
 * and also supports all related controls, like chart types switch, top options,
 * aggregation, etc.
 *
 * NOTE: This component should containt the data visualization transformation logic
 * methods, so the pages components just have to pass the chart data and configuration
 * as "raw" as possible as it's received from the web service.
 */
@Component({
  selector: 'chart-panel',
  templateUrl: './chart-panel.component.html',
  styleUrl: './chart-panel.component.scss'
})
export class ChartPanelComponent implements OnChanges {
  /**
   * CSS Class(es) to be passed to the panel.
   */
  @Input() panelStyleClass: string;

  /**
   * Collection of CSS styles to be passed to the panel.
   */
  @Input() panelStyle: { [key: string]: any };

  /**
   * Flag to determine if an instance of chart-panel should include the
   * `tourAnchor` tags attributes, needed by the Dashboard Tour functionality.
   *
   * If there are more than one chart-panel in the same component, only
   * one of them should include these `tourAnchor` tags; otherwise, some errors
   * will be shown in console because of duplicity of `tourAnchor` IDs.
   */
  @Input() addTourAnchors: boolean = false;

  // Header data inputs/outputs

  /**
   * Text to be shown as a tooltip when hover on the panel header info icon.
   */
  @Input() infoText: string;

  /**
   * Text label to be shown on the panel header.
   */
  @Input() headerLabel: string;

  /**
   * Date range of the selected data to be shown at the center of the panel header.
   */
  @Input() dateRangeLabel: string;

  /**
   * If `true`, the text search input is shown in the panel header.
   */
  @Input() showSearch: boolean = false;

  /**
   * Text to be shown as tooltip when hover on the text search input in the panel header.
   */
  @Input() searchTooltip = 'CHART_PANEL.SEARCH_TOOLTIP';

  /**
   * If `true`, the "Show OE coverage" toggle button is shown in the panel header.
   */
  @Input() showCoverageToggle: boolean = false;

  /**
   * If `true`, the "Top options" dropdown is shown in the panel header.
   */
  @Input() showTopDropdown: boolean = true;

  /**
   * Selected value of the "Top options" dropdown.
   */
  @Input() selectedTopOptions: 10 | 20 | 30 = 10;

  /**
   * If `true`, the "Export to CSV/PDF" button is shown in the panel header.
   */
  @Input() enableExport: boolean = true;

  /**
   * If `true`, the "Switch to line/bar chart" is shown in the panel header.
   */
  @Input() showTimelineButton: boolean = false;

  /**
   * If `true`, the "Switch to donut/bar chart" is shown in the panel header.
   */
  @Input() enableChartTypeToggle: boolean = false;

  /**
   * Emits the search input value when the text search is triggered.
   */
  @Output() searchStart = new EventEmitter<string>();

  // Chart options inputs/outputs

  /**
   * The data to be represented in the chart. +info: https://www.chartjs.org/docs/
   */
  @Input() chartData: ChartData | ChartData<ChartType, any>;

  /**
   * The options to configure the chart looking and behavior. +info: https://www.chartjs.org/docs/
   */
  @Input() chartOptions: ChartOptions;

  /**
   * Sometimes the report response includes a total amount to calculate
   * the percentage for each value. Use this property to specify that total amount.
   */
  @Input() totalFound: number;

  /**
   * If `true`, the selected data series are shown stacked if the selected chart type is bar.
   */
  @Input() stackedBar: boolean = false;

  /**
   * Maximum number of datasets (chart series) that can be selected simultaneously.
   */
  @Input() timelineDatasetLimit: number = 10;

  /**
   * List of available datasets (chart series) that can be represented in the chart.
   */
  @Input() timelineDatasets: TimeLineData[] = [];

  /**
   * List of selected datasets (chart series) to be represented in the chart.
   */
  @Input() selectedTimelineDatasets: TimeLineData[] = [];

  /**
   * If `true`, the time aggregation options will be shown on the right side of the chart.
   */
  @Input() showAggregationOptions: boolean;

  /**
   * If `true`, the filter by weekdays for the time aggregation options will be shown below the
   * time aggregation selector. This filter only actuates (and is shown) when the time aggregation
   * is set to "Daily".
   */
  @Input() showAggregationWeekdaysFilter: boolean = false;

  /**
   * Emits the new selected chart type when a chart type switch button is clicked.
   */
  @Output() chartTypeChange = new EventEmitter<ChartType>();

  /**
   * Emits the new selection of datasets (chart series) when it's changed from the datasets selector.
   */
  @Output() selectedTimelineDatasetsChange = new EventEmitter<TimeLineData[]>();

  /**
   * Event emitted when the "Export CSV" button is clicked.
   */
  @Output() exportCsvClick = new EventEmitter();

  /**
   * Reference to the PrimeNG Chart component. See `this.chartRef` to get the reference of the Chart.js
   * Chart reference inside of the PrimeNG component
   */
  @ViewChild('chart') uiChart: UIChart;

  /**
   * Reference to the Chart.js Chart component inside on the Chart PrimeNG component.
   */
  private get chartRef(): Chart {
    return this.uiChart?.chart as Chart;
  }

  get oeCoverageTooltip(): string {
    return 'OE Coverage';
    // return this.chartData ? ( ''
    //             + this.chartData.oeCoverageCount + ' of ' + this.chartData.getDisplayCount()
    //               + ' displayed OE Numbers are covered by your articles' ) :
    //             'OE Coverage';

  }

  get oeCoverageText(): string {
    return 'OE Coverage';
    // return this.chartData ? ( 'OE Coverage ( ' + this.chartData.oeCoverageCount
    //   + '/' + this.chartData.getDisplayCount() + ' )' ) :
    //   'OE Coverage';
  }

  /**
   * Tooltip text shown on datasources (chart series) selector on hover. It'll show the selected
   * datasources (series) names.
   */
  get timelineTooltip(): string {
    return this.selectedTimelineDatasets?.map(x => x.label).join('\n') || '';
  }

  /**
   * If `true`, the datasets (chart series) selector will be shown. This is the case for stacked bar
   * and line charts.
   */
  get showDatasetsSelector(): boolean {
    return this.isTimelineChartType || this.stackedBar;
  }

  /**
   * Flag to determine if the selected chart type is a line chart.
   */
  get isTimelineChartType(): boolean {
    return this.selectedChartType === 'line';
  }

  /**
   * Flag to determine if the selected chart type is a bar chart.
   */
  get isBarChartType(): boolean {
    return this.selectedChartType === 'bar';
  }

  /**
   * Flag to determine if the selected chart type is a doughnut chart.
   */
  get isDoughnutChartType(): boolean {
    return this.selectedChartType === 'doughnut';
  }

  /**
   * Flag that specifies if there is any chart export in progress.
   */
  get isAnyDownloadInProgress(): boolean {
    return this.downloadHandler.downloadStatus !== DownloadStatus.NONE;
  }

  /**
   * Tooltip text for the "Switch to bar/line chart" button.
   */
  get showTimelineButtonTooltip(): string {
    return this.isTimelineChartType
      ? 'CHART_PANEL.SWITCH_BAR_CHART'
      : 'CHART_PANEL.SWITCH_LINE_CHART';
  }

  /**
   * Tooltip text for the "Switch to bar/donut chart" button.
   */
  get showDoughnutButtonTooltip(): string {
    return this.isBarChartType
      ? 'CHART_PANEL.SWITCH_DOUGHNUT_CHART'
      : 'CHART_PANEL.SWITCH_BAR_CHART';
  }

  /**
   * Flag to check if the selected time aggregation is "Daily".
   */
  get isDailyAggregationSelected(): boolean {
    return this.selectedAggregationOption === AggregationTime.DAILY;
  }

  /**
   * Available top options.
   */
  readonly topOptions = [
    { label: 'Top 10', value: 10 },
    { label: 'Top 20', value: 20 },
    { label: 'Top 30', value: 30 }
  ];

  /**
   * Available chart export options, as a list of PrimeNG `MenuItem`.
   */
  readonly saveMenuOptions: MenuItem[] = [
    { label: this.translate.instant('CHART_PANEL.SAVE_CSV'), command: (event) => { this.exportCsv(); } },
    { label: this.translate.instant('CHART_PANEL.SAVE_PDF'), command: (event) =>  { this.tracker.add(this.generatePdf()); } }
  ];

  /**
   * Available time aggregation options.
   */
  readonly aggregationOptions: AggregationTime[] = AggregationTime.OPTIONS;

  /**
   * List of weekdays for the related filter of time aggregation. The items contain
   * the weekday label and its ISO numeric value (+info: https://en.wikipedia.org/wiki/ISO_week_date).
   */
  readonly weekdays: {label: string, value: number}[] = range(1, 8).map(x => ({
    label: moment().isoWeekday(x).format('dddd'),
    value: x
  }));

  /**
   * Instance of `DownloadHandler`, used in this component for the chart exports.
   */
  private readonly downloadHandler = new DownloadHandler(true);

  /**
   * Selected value of the "Show OE coverage" toggle button.
   */
  showCoverage: boolean;

  /**
   * Snapshot of the `@Input() chartData`, taken when it changes.
   */
  originalChartData: ChartData | ChartData<ChartType, any>;

  /**
   * Selected chart type.
   */
  @Input() selectedChartType: ChartType = 'bar';

  /**
   * Selected time aggregation option.
   */
  selectedAggregationOption: AggregationTime = AggregationTime.DAILY;

  /**
   * Selected values for the weekday filter for time aggregation.
   */
  selectedAggregationWeekdays: number[] = range(1, 8);

  constructor(
    private translate: TranslateService,
    private tracker: TaskTrackerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']?.currentValue) {
      // This should happen when the filtered data has changed
      this.originalChartData = cloneDeep(changes['chartData']?.currentValue)

      if (this.showAggregationOptions) {
        setTimeout(() => this.aggregateAndApplyWeekdaysFilter());
      }

      if (this.showTopDropdown) {
        this.setupTopOptions();
      }
    }
  }

  //#region Data transform methods

  /**
   * Sets the aggregated data and labels to the chart.
   *
   * @param aggregationType the specified aggregation type
   */
  private aggregate(aggregationType: AggregationTime = this.selectedAggregationOption) {
    // For daily aggregation, use data as it comes from the report request.
    if (aggregationType === AggregationTime.DAILY) {
      this.chartData = cloneDeep(this.originalChartData);
      return;
    }

    // Set up aggregated labels
    this.chartData.labels = this.getAggregatedLabels(aggregationType);

    // Aggregate data for each dataset
    const originalDataAndDates = this.getChartDataAsDataAndDate(this.originalChartData);
    const aggregatedData = originalDataAndDates.map(x => this.getAggregatedData(x, aggregationType)); // Get only numeric values for each dataset
    this.chartData.datasets.forEach((dataset, i) => dataset.data = aggregatedData[i]);
  }

  /**
   * Returns a list of labels based on the specified aggregation, from the original
   * chart data labels.
   *
   * @param aggregationType the specified aggregation type
   * @returns a list of labels based on the specified aggregation, from the original
   * chart data labels.
   */
  private getAggregatedLabels(aggregationType: AggregationTime): string[] {
    const groupedDates = groupBy(this.originalChartData.labels, d  => {
      const date = moment(d, 'YYYY-MM-DD');
      if (!date.isValid()) {
        // The date format is different (HH:mm) only when the data we requested is for one single day.
        return ['Day'];
      }

      switch (aggregationType) {
        case AggregationTime.WEEKLY:
          return `${date.year()}-W${date.week()}`;
        case AggregationTime.MONTHLY:
          return `${date.year()}-${(date.month() + 1).toString().padStart(2, '0')}`;
        case AggregationTime.QUARTERLY:
          return `${date.year()}-Q${date.quarter()}`;
        case AggregationTime.YEARLY:
        default:
          return `${date.year()}`;
      }
    });

    return Object.keys(groupedDates);
  }

  /**
   * From an array of numeric values and a related date for each value, returns the
   * aggregated value (sum), according to the specified aggregation type.
   *
   * @param data the array of numeric values and related date.
   * @param aggregationType the aggregation type
   * @returns a list of aggregated (sum) values, according to the specified aggregation type.
   */
  private getAggregatedData(data: {value: number, date: Moment}[], aggregationType: AggregationTime): number[] {
    let groupedData = {};

    switch (aggregationType) {
      case AggregationTime.WEEKLY:
        groupedData = groupBy(data, d => `${d.date.year()}-W${d.date.week()}`);
        break;
      case AggregationTime.MONTHLY:
        groupedData = groupBy(data, d => `${d.date.year()}-${(d.date.month() + 1).toString().padStart(2, '0')}`);
        break;
      case AggregationTime.QUARTERLY:
        groupedData = groupBy(data, d => `${d.date.year()}-Q${d.date.quarter()}`);
        break;
      case AggregationTime.YEARLY:
        groupedData = groupBy(data, d => `${d.date.year()}`);
        break;
    }

    // Get rid of dates after groupping
    const groupedValues = (Object.values(groupedData) as {value: number, date: Moment}[][]).map(x => x.map(y => y.value));
    return (Object.values(groupedValues) as number[][]).map(x => x.reduce((a, b) => a + b));
  }

  /**
   * Limits the shown data on the chart to the selected top options.
   */
  private setupTopOptions() {
    this.chartData.labels = Array.from(this.originalChartData.labels).splice(0, this.selectedTopOptions);
    this.chartData.datasets[0].data = Array.from(this.originalChartData.datasets[0].data).splice(0, this.selectedTopOptions);
    this.chartRef?.update();
  }

  /**
   * Given a chart data, returns a list with an item per dataset with a list of objects
   * with the dataset data values and their related dates.
   *
   * @param chartData the chart data
   * @returns the list with an item per dataset with a list of objects
   * with the dataset data values and their related dates.
   */
  private getChartDataAsDataAndDate(chartData: ChartData): {
    value: number;
    date: moment.Moment;
  }[][] {
    return chartData.datasets.map(dataset => dataset.data?.map((value, i) => ({
      value: value as number,
      date: moment(this.originalChartData.labels[i], 'YYYY-MM-DD')
    })));
  }

  /**
   * Aggregates the day by the selected aggregation options and applies
   * the weekday filter, according to selected weekdays.
   */
  private aggregateAndApplyWeekdaysFilter(aggregationType?: AggregationTime) {
    if (!this.chartData) {
      return;
    }

    // It's necessary to re-aggregate to get the data from the original source.
    this.aggregate(aggregationType);

    const labelsAsDate = this.chartData.labels.map(label => moment(label, 'YYYY-MM-DD'));
    if (!labelsAsDate.every(date => date.isValid())) {
      console.warn(`Unable to aggregate data by time, using labels: [${this.chartData.labels.join(', ')}]`);
      return;
    }

    this.chartData.labels = labelsAsDate.filter(label => this.selectedAggregationWeekdays.includes(label.isoWeekday()))
      .map(label => label.format('YYYY-MM-DD'));

    const dataAndDates = this.getChartDataAsDataAndDate(this.chartData);
    const filteredDataAndDates = dataAndDates.map(dataset => dataset?.filter(d =>
      this.selectedAggregationWeekdays.includes(d.date.isoWeekday())));

    const filteredData = filteredDataAndDates.map(dataset => dataset?.map(d => d.value));
    this.chartData.datasets.forEach((dataset, i) => dataset.data = filteredData[i]);
  }

  //#endregion

  private exportCsv(): void {
    this.exportCsvClick.emit();
  }

  private async generatePdf(): Promise<void> {
    const maxLengthPx = 260;
    const leftMarginPx = 15;
    const topMarginPx = 20;
    const lineHeightPx = 10;

    let currentLineHeight = topMarginPx;
    const addBreakLine = (numberOfLines = 1) => currentLineHeight += numberOfLines * lineHeightPx;

    const pdf = new jsPDF({
      orientation: 'landscape',
    });

    // Header
    pdf.setFont(undefined, 'bold');
    pdf.text(this.headerLabel, leftMarginPx, currentLineHeight);
    pdf.setFont(undefined, 'normal');

    addBreakLine();

    // Subheader
    const splitInfoText = pdf.splitTextToSize(this.infoText, maxLengthPx);
    pdf.text(splitInfoText, leftMarginPx, currentLineHeight);

    // Filter values
    // TODO: add selected filter values on PDF.

    addBreakLine(1);

    // Chart
    const chartImageHeightPx = 500;
    const chartImageWidthPx = 1090;

    const base64ChartImage = await this.getChartBase64Image(chartImageHeightPx, chartImageWidthPx);
    const horizontalRatio = maxLengthPx / chartImageWidthPx;
    const chartImageHeight = chartImageHeightPx * horizontalRatio;

    pdf.addImage(base64ChartImage, 'JPEG', leftMarginPx, currentLineHeight, maxLengthPx, chartImageHeight);
    currentLineHeight += chartImageHeight;

    addBreakLine(4);

    // Footer
    pdf.text('Copyright © by TecAlliance GmbH', leftMarginPx, currentLineHeight);

    pdf.save(`${this.headerLabel}.pdf`);
  }

  private getChartBase64Image(height: number, width: number): Promise<string> {
    const chartCanvas = this.chartRef.canvas;
    const chartCanvasParent = chartCanvas.parentNode as HTMLElement;
    const originalHeight = chartCanvasParent.style.height;
    const originalWidth = chartCanvasParent.style.width;

    chartCanvasParent.style.height = `${height}px`;
    chartCanvasParent.style.width = `${width}px`;

    return new Promise((resolve, _) => {
      setTimeout(() => {
        const base64ChartImage = chartCanvas.toDataURL();
        chartCanvasParent.style.height = originalHeight;
        chartCanvasParent.style.width = originalWidth;
        resolve(base64ChartImage);
      }, 60);
    })
  }

  /**
   * Event handler triggered when the text search is executed.
   */
  onSpecialSearch(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.searchStart.emit(searchTerm);
  }

  /**
   * Event handler triggered when the selected "Top options" value changes.
   *
   * @param event the "Top options" PrimeNG dropdown change event.
   */
  onChangeTop(event: DropdownChangeEvent) {
    this.setupTopOptions();
  }

  /**
   * Event handler triggered when the "Switch to bar/line chart" button is clicked.
   *
   * @param event the click event.
   */
  onShowTimelineButtonClick(event: MouseEvent) {
    this.selectedChartType = this.isBarChartType ? 'line' : 'bar';
    this.chartTypeChange.emit(this.selectedChartType);
  }

  /**
   * Event handler triggered when the "Switch to bar/donut chart" button is clicked.
   *
   * @param event the click event.
   */
  onToggleChartTypeClick(event: MouseEvent) {
    this.selectedChartType = this.isBarChartType ? 'doughnut' : 'bar';
    this.chartTypeChange.emit(this.selectedChartType);
  }

  /**
   * Event handler triggered when the selected datasets (chart series) list changes.
   *
   * @param event the new list of selected datasets (chart series).
   */
  onSelectedTimelineDatasetsChange(event: TimeLineData[]) {
    this.selectedTimelineDatasetsChange.emit(event);
  }

  /**
   * Event handler triggered when the selected time aggregation option changes.
   *
   * @param event the numeric values of the selected time aggergation option.
   */
  onSelectedAggregationOptionChange(event: AggregationTime) {
    this.aggregateAndApplyWeekdaysFilter();
    this.chartRef.update();
  }

  /**
   * Event handler triggered when the selected weekdays of the filter for time aggregation change.
   */
  onAggregationWeekdaysChange(event) {
    this.aggregateAndApplyWeekdaysFilter();
    this.chartRef.update();
  }
}
