import { inject } from '@angular/core';

import { Router, CanActivateFn } from '@angular/router';

import { AuthService } from '../../core';

/**
 * Guard for anonymous module. It should only be accessible when the user is not logged in.
 *
 * @param route The actual route
 * @param state The actual route snapshot
 * @returns `true` if the user is not logged in (meaning that is allowed to navigate), otherwise redirect to analytics (home) page.
 */
export const loginGuard: CanActivateFn = (route, state) => {
  return inject(AuthService).isLogged
    ? inject(Router).createUrlTree(['/analytics'])
    : true;
};
