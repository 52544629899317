import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Calendar } from 'primeng/calendar';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'timespan-select',
  templateUrl: './timespan-select.component.html',
  styleUrl: './timespan-select.component.scss'
})
export class TimespanSelectComponent implements OnInit, AfterViewInit {
  private readonly defaultDatesValue = 'lastMonth';

  formGroup: FormGroup<{
    isRange: FormControl<boolean>,
    dates: FormControl<string[]>,
  }>;

  private get formGroupDates(): AbstractControl {
    return this.formGroup?.get('timespan.dates');
  }

  dropdownOptions: {id: string, label: string}[];

  dateFormat: string;

  @ViewChild('calendar') calendar: Calendar;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private translate: TranslateService,
  ) { }

  async ngOnInit() {
    this.formGroup = this.rootFormGroup.control;

    this.formGroupDates.valueChanges
      .subscribe((value: string | string[]) => {
        const isValueRange = Array.isArray(value);
        if (!isValueRange) {
          this.calendar.inputFieldValue = this.dropdownOptions.find(x => x.id === value)?.label;
          setTimeout(() => this.calendar.hideOverlay());
        }

        (this.formGroup.get('timespan.isRange') as AbstractControl<boolean>)?.setValue(isValueRange);
      });
  }

  ngAfterViewInit(): void {
    // Prevent calendar overlay to show unexpectedly on load.
    this.calendar.onInputFocus = (event) => event.preventDefault();

    this.calendar.clear = () => {
      this.calendar.overlayVisible
        ? this.calendar.hideOverlay()
        : this.calendar.showOverlay();
    };

    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.initialize());
  }

  private initialize() {
    this.translate.get([
      'ANALYTICS_FILTER.THIS_YEAR',
      'ANALYTICS_FILTER.LAST_YEAR',
      'ANALYTICS_FILTER.LAST_QUARTER',
      'ANALYTICS_FILTER.LAST_MONTH',
      'ANALYTICS_FILTER.LAST_WEEK',
      'ANALYTICS_FILTER.YESTERDAY',
      'ANALYTICS_FILTER.LAST_12_MONTHS',
      'ANALYTICS_FILTER.LAST_24_MONTHS'
    ])
    .subscribe(translations => {
        this.dropdownOptions = [
          { id: "ytd", label: translations['ANALYTICS_FILTER.THIS_YEAR'] },
          { id: "lastYear", label: translations['ANALYTICS_FILTER.LAST_YEAR'] },
          { id: "lastQuarter", label: translations['ANALYTICS_FILTER.LAST_QUARTER'] },
          { id: "lastMonth", label: translations['ANALYTICS_FILTER.LAST_MONTH'] },
          { id: "lastWeek", label: translations['ANALYTICS_FILTER.LAST_WEEK'] },
          { id: "yesterday", label: translations['ANALYTICS_FILTER.YESTERDAY'] },
          { id: "last12months", label: translations['ANALYTICS_FILTER.LAST_12_MONTHS'] },
          { id: "last24months", label: translations['ANALYTICS_FILTER.LAST_24_MONTHS'] },
        ];

        this.initializeDates();
      });
  }

  private initializeDates() {
    this.dateFormat = this.dropdownOptions.find(x => x.id === this.formGroupDates?.value)?.label;
    this.updateCalendarDateFormat();
    this.formGroupDates.setValue(this.defaultDatesValue);
  }

  private updateCalendarDateFormat() {
    if (!this.calendar) {
      return;
    }

    this.calendar.dateFormat = this.dateFormat;
  }

  onDateSelect(event) {
    this.dateFormat = 'dd.mm.yy';
    this.updateCalendarDateFormat();
  }

  onPresetChange(event: DropdownChangeEvent) {
    this.dateFormat = this.dropdownOptions.find(x => x.id === event.value).label;
    this.updateCalendarDateFormat();
    this.formGroupDates.setValue(event.value);
  }
}
