<p-overlayPanel #overlay [style]="{'minWidth': '400px', 'minHeight': '300px'}">
  <form [formGroup]="form" class="d-flex flex-column">
    <span>
      <i class="fa fa-envelope me-1"></i>
      {{ userEmail }}
    </span>

    <hr class="mt-2">

    <div class="d-flex align-items-center mb-3">
      <h6 class="d-inline m-0">{{ 'CHANGE_PASSWORD.HEADER' | translate }}</h6>
      <a class="ms-2 cursor-pointer"
        [pTooltip]="'COMMON.SHOW_PASSWORD' | translate"
        tooltipPosition="top"
        (click)="onShowPasswordClick($event)">
        <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
      </a>
    </div>

    <input class="mb-3"
      formControlName="oldPassword"
      [type]="showPassword ? 'text' : 'password'"
      [ngClass]="{'border-2 border-danger': getFormControlErrors('oldPassword') && showErrorStyles}"
      [pTooltip]="getFormControlErrorMessages('oldPassword')"
      tooltipPosition="top"
      [placeholder]="'CHANGE_PASSWORD.OLD_PASSWORD' | translate">

    <input class="mb-3"
      formControlName="newPassword"
      [type]="showPassword ? 'text' : 'password'"
      [ngClass]="{'border-2 border-danger': getFormControlErrors('newPassword') && showErrorStyles}"
      [pTooltip]="getFormControlErrorMessages('newPassword')"
      tooltipPosition="top"
      [placeholder]="'CHANGE_PASSWORD.NEW_PASSWORD' | translate">

    <input class="mb-3"
      formControlName="repeatNewPassword"
      [type]="showPassword ? 'text' : 'password'"
      [ngClass]="{'border-2 border-danger': getFormControlErrors('repeatNewPassword') && showErrorStyles}"
      [pTooltip]="getFormControlErrorMessages('repeatNewPassword')"
      tooltipPosition="top"
      [placeholder]="'CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate">

    <button class="btn btn-primary" (click)="onSendClick($event)">{{ 'COMMON.SEND' | translate }}</button>
  </form>
</p-overlayPanel>
