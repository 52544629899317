import { Injectable } from '@angular/core';

import { ContextService } from '../core/context';

import { UserDetail } from '../../security/user-detail';
import { AuthService } from '../security/auth';

/**
 * @file tracker.js
 * @module app.core
 * @summary Tracker service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2018
 */
@Injectable()
export class TrackerService {
  //#region Properties

  private get tracker(): any {
    if (!window.hasOwnProperty('tracker')) {
      return null;
    }

    return window['tracker'];
  }

  private get AI(): any {
    if (!window.hasOwnProperty('AI')) {
      return null;
    }

    return window['AI'];
  }

  private get paq(): any {
    if (!window.hasOwnProperty('_paq')) {
      return null;
    }

    return window['_paq'];
  }

  private get user(): UserDetail {
    return (this.auth.isLogged)
      ? this.auth.userDetail
      : null;
  }

  //#endregion

  //#region Constructor

  constructor(
    protected context: ContextService,
    protected auth: AuthService
  ) {
    this.auth.loggedIn.subscribe(() => this.trackEvent('Authentication', 'Logged in'));
    this.auth.loggingOut.subscribe(() => this.trackEvent('Authentication', 'Logged out'));
  }

  //#endregion

  //#region Methods

  /**
   * Tracks page view.
   *
   * @param {string} name The name used to identify the page in the portal. Defaults to the document title.
   * @param {string} url A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
   * @param {{[string]:string}} properties Map of string to string: Additional data used to filter pages in the portal. Defaults to empty.
   * @param {[string]:number} measurements Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on
   * the portal. Defaults to empty.
   * @param {number} duration The number of milliseconds it took to load this page, displayed in Metrics Explorer on the portal. Defaults
   * to empty. If empty, end of page view duration is recorded when browser page load event is called.
   *
   * @example
   * tecAppInsight.trackPageView("page name", "http://fabrikam.com/pageurl.html",
   *    // String properties:
   *    {Game: currentGame.name, Difficulty: currentGame.difficulty},
   *    // Numeric metrics:
   *    {Score: currentGame.score, Opponents: currentGame.opponentCount}
   * );
   *
   */
  public trackPageView(
    name: string,
    url?: string,
    properties?: Object,
    measurements?: Object,
    duration?: number): void {
    if (!this.tracker && !this.paq) {
      return;
    }

    properties = Object.assign({}, this.getDefaultsProperties(), properties || {});

    console.log(
      '%c[P]%c AI%s PP%s➜ %s - %O',
      'background: #D8BFD8; color: #0000CD;font-weight: bold;',
      'background: #D8BFD8; color: #0000CD;font-weight: 100;',
      this.tracker ? '✅' : '❌',
      this.paq ? '✅' : '❌',
      name,
      properties);

    this.setContext(name, url, properties);

    if (this.tracker) {
      this.tracker.trackPageView(name, url || window.location.href, properties, measurements, duration);
    }

    if (this.paq) {
      this.paq.push(['trackPageView']);
    }
  }

  /**
   * Starts the timer for tracking a page view. Use this instead of trackPageView if you want to control when the page
   * view timer starts and stops, but don't want to calculate the duration yourself. This method doesn't send any telemetry.
   * Call stopTrackPage to log the end of the page view and send the event.
   *
   * @param {string} name The name used to identify the page in the portal. Defaults to the document title.
   */
  public startTrackPage(name: string): void {
    if (!this.tracker && !this.paq) {
      return;
    }

    this.setContext(name, undefined, this.getDefaultsProperties());

    if (this.tracker) {
      this.tracker.startTrackPage(name);
    }

    if (this.paq) {
      this.paq.push(['trackPageView']);
    }
  }

  /**
   * Stops the timer that was started by calling startTrackPage and sends the page view telemetry with the specified properties and
   * measurements. The duration of the page view will be the time between calling startTrackPage and stopTrackPage.
   *
   * @param {string} name The name used to identify the page in the portal. Defaults to the document title.
   * @param {string} url A relative or absolute URL that identifies the page or similar item. Defaults to the window location.
   * @param {{[string]:string}} properties Map of string to string: Additional data used to filter pages in the portal. Defaults to empty.
   * @param {[string]:number} measurements Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on
   * the portal.Defaults to empty.
   */
  public stopTrackPage(
    name: string,
    url?: string,
    properties?: Object,
    measurements?: Object): void {
    if (!this.tracker) {
      return;
    }

    properties = Object.assign({}, this.getDefaultsProperties(), properties || {});

    this.tracker.stopTrackPage(name, url, properties, measurements);
  }

  /**
   * Log a user action or other occurrence.
   *
   * @param action Identifies the event. Events with the same name are counted and can be charted in Metric Explorer.
   * @param properties Map of string to string: Additional data used to filter events in the portal. Defaults to empty.
   * @param measurements Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on the portal.
   * Defaults to empty.
   */
  public trackEvent(
    category: string,
    action: string,
    properties?: Object,
    measurements?: Object) {
    if (!this.tracker && !this.paq) {
      return;
    }

    properties = Object.assign({}, this.getDefaultsProperties(), properties || {});

    console.log(
      '%c[E] AI%s PP%s➜ %s: %s - %O',
      'background: #98FB98; color: #FF4500; font-weight: 100;',
      this.tracker ? '✅' : '❌',
      this.paq ? '✅' : '❌',
      category,
      action,
      properties);

    this.setContext(undefined, undefined, properties);

    if (this.tracker) {
      this.tracker.trackEvent(action, properties, measurements);
    }

    if (this.paq) {
      const value = properties['Value'];
      const name = properties['Name'];

      if (typeof name !== 'undefined') {
        this.paq.push(['trackEvent', category, action, name]);
      } else
      if (typeof value !== 'undefined') {
        this.paq.push(['trackEvent', category, action, 'Value', value]);
      } else {
        this.paq.push(['trackEvent', category, action]);
      }
    }
  }

  /**
   * Log a positive numeric value that is not associated with a specific event. Typically used to send regular reports
   * of performance indicators.
   *
   * @param {string} name A string that identifies the metric. In the portal, you can select metrics for display by name.
   * @param {number} average Either a single measurement, or the average of several measurements. Should be >=0 to be correctly displayed.
   * @param {*} sampleCount Count of measurements represented by the average. Defaults to 1. Should be >=1.
   * @param {*} min The smallest measurement in the sample. Defaults to the average. Should be >= 0.
   * @param {*} max The largest measurement in the sample. Defaults to the average. Should be >= 0.
   * @param {*} properties Map of string to string: Additional data used to filter events in the portal.
   */
  public trackMetric(
    name: string,
    average: number,
    sampleCount: number,
    min: number,
    max: number,
    properties: Object) {
    if (!this.tracker) {
      return;
    }

    properties = Object.assign({}, this.getDefaultsProperties(), properties || {});

    this.tracker.trackMetric(name, average, sampleCount || 1, min || 0, max || 0, properties);
  }

  /**
   * Log an exception you have caught. (Exceptions caught by the browser are also logged.)
   *
   * @param {Error} exception An Error from a catch clause.
   * @param {string} handledAt Defaults to "unhandled".
   * @param {{[string]:string}} properties Map of string to string: Additional data used to filter exceptions in the portal.
   * Defaults to empty.
   * @param {{[string]:number}} measurements Map of string to number: Metrics associated with this page, displayed in Metrics Explorer on
   * the portal. Defaults to empty.
   * @param {AI.SeverityLevel} severityLevel Supported values: SeverityLevel.ts
   */
  public trackException(
    exception: Error,
    handledAt: string,
    properties: [{ key: string, value: any }],
    measurements: [{ key: string, value: number }],
    severityLevel: any) {
    if (!this.tracker) {
      return;
    }

    properties = Object.assign({}, this.getDefaultsProperties(), properties || {});

    this.tracker.trackException(exception, handledAt, properties, measurements, severityLevel || this.AI.SeverityLevel.Error);
  }

  /**
   * Immediately send all queued telemetry. Synchronous.
   *
   * You don't usually have to use this, as it happens automatically on window closing.
   */
  public flush(): void {
    if (!this.tracker) {
      return;
    }

    this.tracker.flush();
  }

  //#endregion

  //#region Helpers

  private getDefaultsProperties(): any {
    return {
      AppId: this.context.instance.clientId,
      Language: this.context.instance.language,
      // Country: this._context.instance.country,
      Hosting: this.context.instance.hostingLocation
    };
  }

  private setContext(pageName?: string, url?: string, properties?: Object) {
    if (this.tracker) {
      if (this.user) {
        this.tracker.setAuthenticatedUserContext(this.user.email);
      } else {
        this.tracker.clearAuthenticatedUserContext();
      }
    }

    if (this.paq) {
      if (this.user) {
        this.paq.push(['setUserId', this.user.login]);
      } else {
        // this.paq.push(['resetUserId']);
      }

      if (pageName) {
        this.paq.push(['setDocumentTitle', pageName]);
      }

      this.paq.push(['setCustomUrl', url || window.location.href]);
      this.pushPiwikCustomVars(properties);
    }
  }

  private pushPiwikCustomVars(properties: Object) {
    if (!this.paq || !properties) {
      return;
    }

    let idx = 1;
    for (const key in properties) {
      if (typeof properties[key] === 'undefined') {
        continue;
      }

      this.paq.push(['setCustomVariable',
        idx++,
        key,
        properties[key],
        // scope of the custom variable,
        // - "visit" means the custom variable applies to the current visit
        // - "page" to the current page view
        'visit'
      ]);
    }
  }

  //#endregion
  setDimensionValue(position: number, value: string) {
    if (!this.paq || !position || !value) {
      return;
    }
    this.paq.push(['setCustomDimensionValue', position, value]);
  }
}
