export interface ILoadingOptions {
    message?: string;
    delay?: number;
    minDuration?: number;
}

export class LoadingOptions implements ILoadingOptions {
    constructor(config: ILoadingOptions = {}) {
        for (let option in LOADING_OPTIONS_DEFAULTS) {
            if (!LOADING_OPTIONS_DEFAULTS.hasOwnProperty(option)) {
                continue;
            }

            this[option] = typeof config[option] !== 'undefined' && config[option] !== null
                ? config[option]
                : LOADING_OPTIONS_DEFAULTS[option];
        }
    }
}

export const LOADING_OPTIONS_DEFAULTS = {
    delay: 0,
    minDuration: 0,
    message: 'Please wait'
};
