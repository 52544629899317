// Theme
//$primary-color: #FF6633;

$colors: (
  // primary
  ta-blue:      #003366,
  ta-dark-blue: #272838,
  ta-sky-blue:  #3f9dd1,
  ta-green:     #87C7AD,
  ta-gray:      #868e96,
  ta-steel-blue:#5C89B6,
  ta-air-blue:  #8FAEC9,
  // secondary
  ta-orange:    #E75112,
  ta-purple:    #6E1868,
  // background
  ta-white:     #fff,
  ta-light: 	  #f8f9fa,
  ta-dark:      #343a40,
  // status
  ta-warning: 	#FFC107,
  ta-danger: 	  #DC3545,
  ta-success: 	#28a745
);

$white: #fff;
$primary:  #8FAEC9;
$tab-selected: #DEE2E6;
$tab-disabled:#6C757D;
$tab-border-selected:#DEE2E6;
$tab-border-hoover:#E9ECEF;

$primary-color: map-get($colors, ta-blue);
$secondary-color: map-get($colors, ta-orange);

$bg-color: map-get($colors, ta-white);
$border-color: #e9ecef;

// Header
$header-height: 55px;
$header-width-min: 365px;
// Footer
$footer-height: 35px;

// Side menu
$side-menu-width: 225px;
$logo-width: 165;

$side-menu-bg-color: map-get($colors, ta-white);
$side-menu-text-color: map-get($colors, ta-blue);


$main-section-min-width: 360px;


$datasource-border-color: #CCCCCC;
$datasource-header-color: #272838;

$ta-primary-color: #5C89B6 ;
/*$ff2ecc: #FF2ECC;
$2b2c3a: #2B2C3A;
$0b3c84: #0B3C84;
$4c4b63: #4C4B63;
$0052cc: #0052CC;
$272838: #272838;*/
$ta-two-color: #0052CF;
$ta-light-color: #F8F9FA;
$ta-input-border-color: #BDBDBD;
$ta-dropdown-bg: #eceeef;
$ta-panel-header-background-color: #ECEEEF;
$ta-info-disabled-color: #A9D5C5;
$ta-primary-text-color: #FFFFFF;
$ta-info-hover-color: #6DBB9B;
$ta-info-color: #87C7AD;
$ta-panel-border-color: #E6E6E7;
$ta-four-color: #E75112;
$ta-success-color: #28A745;
$ta-primary-hover-color: #216489;
$ta-primary-color-3f9dd1: #3F9DD1;
$ta-primary-disabled-color: #80BDDF;
$ta-text-color: #272838;
$_707070: #707070;
$ta-dark-color: #343A40;
$ta-gray-color: #868E96;
$ta-secondary-hover-color: #727B84;
$ta-panel-body-background-color: #ffffff;

$ta-cta-bg: #ff562b;
$ta-filter-bar-border-left-color: #AFB4B9;
$ta-active-color: #0052cf;
$ta-link-color: #0052cf;
$ta-link-color-active: #0043a8;
$ta-link-hover-color: #0043a8;
$ta-primary-button-bg-color: #0052CF;
$ta-primary-button-bg-color-hover: #0043A8;
$ta-sidebar-active-bg: #ECEEEF;
$ta-font: 'Roboto', sans-serif;