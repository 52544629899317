<form [formGroup]="formGroup">
  <div class="d-flex flex-grow-1" formGroupName="timespan">
    <p-calendar #calendar formControlName="dates"
      [dateFormat]="dateFormat"
      selectionMode="range"
      [appendTo]="'body'"
      [readonlyInput]="true"
      [dataType]="'string'"
      [keepInvalid]="true"
      [showClear]="true"
      class="d-flex flex-grow-1"
      [styleClass]="'d-flex flex-grow-1 analytics-filter xs'"
      (onSelect)="onDateSelect($event)">
      <ng-template pTemplate="clearicon">
        <i class="pi pi-chevron-down"></i>
      </ng-template>
      <ng-template pTemplate="header">
        <p-dropdown formControlName="dates"
          [placeholder]="'Presets'"
          [options]="dropdownOptions"
          [optionValue]="'id'"
          class="d-flex flex-grow-1"
          [styleClass]="'flex-grow-1 analytics-filter xs'"
          [panelStyleClass]="'items-no-padding xs'"
          (onChange)="onPresetChange($event)">
        </p-dropdown>
      </ng-template>
    </p-calendar>
  </div>
</form>
