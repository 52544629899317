import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as pages from './pages';
import { loginGuard } from './guards/login-guard';

const routes: Routes = [
  {
    path: 'portal', // This hierarchy level keeps compatibility with legacy routes
    canActivateChild: [loginGuard],
    children: [
      { path: 'login', component: pages.LoginPage },
      { path: 'forgotPassword', component: pages.ForgotPasswordPage }
      // { path: 'licences', component: LicencesPage },
      // { path: 'imprint', component: ImprintPage },
      // { path: 'privacy-policy', component: PrivacyPolicyPage },
      // { canActivate: [loginGuard], path: 'release-notes', component: ReleaseNotesComponent }
    ]
  },
  { path: 'portal/logout', component: pages.LogoutPage },
  { path: '', redirectTo: 'portal/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnonymousRoutingModule { }
