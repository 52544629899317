export type Language = {
  code: string;
  name: string;
  nativeName: string;
}

export const Languages: Language[] = [
  {
    code: 'ar',
    name: 'Arabic',
    nativeName: 'عربي'
  },
  {
    code: 'qc',
    name: 'Brazilian Portuguese',
    nativeName: 'português (BR)'
  },
  {
    code: 'bg',
    name: 'Bulgarian',
    nativeName: 'Български'
  },
  {
    code: 'zh',
    name: 'Chinese',
    nativeName: '汉语'
  },
  {
    code: 'hr',
    name: 'Croatian',
    nativeName: 'Hrvatski'
  },
  {
    code: 'cs',
    name: 'Czech',
    nativeName: 'čeština'
  },
  {
    code: 'da',
    name: 'Danish',
    nativeName: 'Dansk'
  },
  {
    code: 'nl',
    name: 'Dutch',
    nativeName: 'Nederlands'
  },
  {
    code: 'qb',
    name: 'English (AUS)',
    nativeName: 'English (AUS)'
  },
  {
    code: 'en',
    name: 'English (GB)',
    nativeName: 'English (GB)'
  },
  {
    code: 'qa',
    name: 'English (USA)',
    nativeName: 'English (USA)'
  },
  {
    code: 'et',
    name: 'Estonian',
    nativeName: 'Eesti'
  },
  {
    code: 'fi',
    name: 'Finnish',
    nativeName: 'Suomi'
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français'
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch'
  },
  {
    code: 'el',
    name: 'Greek',
    nativeName: 'Ελληvικά'
  },
  {
    code: 'he',
    name: 'Hebrew',
    nativeName: 'עברית'
  },
  {
    code: 'hu',
    name: 'Hungarian',
    nativeName: 'Magyar'
  },
  {
    code: 'is',
    name: 'Icelandic',
    nativeName: 'íslenska'
  },
  {
    code: 'it',
    name: 'Italian',
    nativeName: 'Italiano'
  },
  {
    code: 'ja',
    name: 'Japanese',
    nativeName: '日本語'
  },
  {
    code: 'ko',
    name: 'Korean',
    nativeName: '한국어'
  },
  {
    code: 'lv',
    name: 'Latvian',
    nativeName: 'Latviešu'
  },
  {
    code: 'lt',
    name: 'Lithuanian',
    nativeName: 'Lietuvių'
  },
  {
    code: 'ms',
    name: 'Malay',
    nativeName: 'Bahasa Melayu'
  },
  {
    code: 'no',
    name: 'Norwegian',
    nativeName: 'Norsk'
  },
  {
    code: 'pl',
    name: 'Polish',
    nativeName: 'polski'
  },
  {
    code: 'pt',
    name: 'Portuguese',
    nativeName: 'Português'
  },
  {
    code: 'ro',
    name: 'Romanian',
    nativeName: 'Română'
  },
  {
    code: 'ru',
    name: 'Russian',
    nativeName: 'русский'
  },
  {
    code: 'sr',
    name: 'Serbian',
    nativeName: 'Srpski'
  },
  {
    code: 'sk',
    name: 'Slovak',
    nativeName: 'slovenčina'
  },
  {
    code: 'sl',
    name: 'Slovenian',
    nativeName: 'Slovenski'
  },
  {
    code: 'es',
    name: 'Spanish',
    nativeName: 'Español'
  },
  {
    code: 'qd',
    name: 'Spanish (MEX)',
    nativeName: 'Español (MEX)'
  },
  {
    code: 'sv',
    name: 'Swedish',
    nativeName: 'Svenska'
  },
  {
    code: 'th',
    name: 'Thai (Thailand)',
    nativeName: 'ภาษาไทย (ประเทศไทย)'
  },
  {
    code: 'tr',
    name: 'Turkish',
    nativeName: 'Türkçe'
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    nativeName: 'Українська'
  },
  {
    code: 'vi',
    name: 'Vietnamese',
    nativeName: 'Tiếng việt'
  }
];
