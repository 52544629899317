<p-overlayPanel #versionBubble [appendTo]="'body'" [styleClass]="'versionBubble'" [autoZIndex]="false">
    <div class="col px-2 header">
        Product update {{version}}
    </div>
    <div class="col px-2 content bg-white">
      New product version is available with more fixes, optimizations and features. <br>
      For more details, please click on the version number below.
    </div>
    <div class="col px-2 footer">
      Please reload the website:
      <button pButton class="btn_reload_page pull-right ml-2" type="button" label="Close" (click)="onCloseClick()"></button>
      <button pButton class="btn_reload_page pull-right ml-2" type="button" label="Release notes" (click)="openReleaseNotes()"></button>
      <button pButton class="btn_reload_page pull-right ml-2" type="button" label="Reload" (click)="reloadPage()"></button>
    </div>
 </p-overlayPanel>

<footer>
  <nav class="navbar navbar-expand-md navbar-dark">
    <a class="navbar-brand text company-name" href="https://www.tecalliance.net" target="_blank">
      © {{currentYear}}&nbsp;TecAlliance GmbH
    </a>
    <a [hidden]="!isLogged" id="versionLink" class="version-link text" href="/portal/release-notes" target="ddbReleaseNotes">
      {{version}}
    </a>
    <span *ngIf="!isLogged" class="version-link text">
      {{version}}
    </span>
    <div class="collapse navbar-collapse mr-auto">
      <ul class="nav navbar-nav d-flex">
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.facebook.com/TecAlliance">
            <span class="fa fa-facebook"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.youtube.com/user/TecAlliance">
            <span class="fa fa-youtube"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://twitter.com/TecAlliance">
            <span class="fa fa-twitter"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.xing.com/companies/tecalliancegmbh">
            <span class="fa fa-xing"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://plus.google.com/u/0/b/111060335767599936833/111060335767599936833/about">
            <span class="fa fa-google-plus"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.linkedin.com/company/3752764?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2Cidx%3A1-1-1%2CtarId%3A1436867065836%2Ctas%3ATecAlliance">
            <span class="fa fa-linkedin"></span>
          </a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav ml-auto d-none d-lg-flex d-xl-flex">
      <li class="nav-item">
        <a class="nav-link text" href="" (click)="onLegalNoticeAnchorClick($event)">{{ 'FOOTER.LEGAL_NOTICE' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" href="https://www.tecalliance.net/privacy-policy/" target="_blank">{{ 'FOOTER.PRIVACY_STATEMENT' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" href="" (click)="onLicensesAnchorClick($event)">{{ 'FOOTER.LICENCES' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" href="https://www.tecalliance.net/contact-tecdoc/" target="_blank">{{ 'FOOTER.CONTACT' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" href="https://www.tecalliance.net/gtcs/" target="_blank">{{ 'FOOTER.TERMS_AND_CONDITIONS' | translate }}</a>
      </li>
    </ul>
  </nav>
</footer>

<p-dialog header="Licences" [(visible)]="showLicensesDialog" [style]="{width: '50vw'}">
  <div [style]="{'white-space': 'pre-line'}">
      {{ licensesText | async }}
  </div>
</p-dialog>

<p-dialog [(visible)]="showLegalNoticeDialog" [showHeader]="false" [style]="{width: '50vw'}">
  <div class="d-flex align-items-center">
    <h1 class="display-4 hidden-xs-down flex-grow-1">
      TecAlliance GmbH
    </h1>
    <span class="m-3" [style.cursor]="'pointer'" (click)="showLegalNoticeDialog = false">
      <i class="fa fa-2x fa-times"></i>
    </span>
  </div>
  <hr>
  <div class="row">
      <div class="col-md-12">
          <address>
              Steinheilstraße 10
              <br> 85737 Ismaning
              <br> Fon: +49 (0)89-32 12 16-0
              <br> Fax: +49 (0)89-32 12 16-100
              <br>
              <a href="mailto:#">info&#64;tecalliance.net</a>
              <br>
          </address>
      </div>
  </div>

  <div class="row">
      <div class="col-md-12">
          <address>
              <strong>Managing Director</strong>
              <br> Peter van der Galiën
              <br>
              <br>The company is registered in the commercial register of the Local Court Munich with the
              <br>HRB-Nr.: 134 509
              <br>USt-IdNr.: DE212306071
          </address>
      </div>
  </div>

  <div class="row">
      <div class="col-md-12">
          <address>
              <strong>All rights reserved.</strong>
              <br> Complete copyright for this website by TecAlliance GmbH.
          </address>
      </div>
  </div>

  <div class="row">
      <div class="col-md-12">
          <address>
              <strong>Right of use</strong>
              <br> Copyright © by
              <br>TecAlliance GmbH
              <br>Steinheilstr. 10
              <br>85737 Ismaning
          </address>
      </div>
  </div>

  <div class="row">
      <div class="col-md-12">
          <address class="text-justify">
              <strong>Exclusion Note</strong>
              <br> Despite of a careful control of the contents, TecAlliance GmbH cannot be held liable for the contents of third
              links. For this, exclusively the operator of the correspondent website is held liable.
              <br>
              <br>TecAlliance GmbH is anxious to present the contents of your web offer always up-to-date, correctly and completely.
              Errors, however, cannot be entirely excluded.
              <br>
              <br>TecAlliance GmbH cannot be held liable for the up-to-dateness, the correct contents and the completeness of the
              information contained in your website, unless errors had been entered deliberately and grossly negligent. This
              rule applies to any material or immaterial damage of third parties that may occur by using the website.
              <br>
          </address>
      </div>
  </div>
</p-dialog>
