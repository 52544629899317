/**
 * Represents the TecDoc number types.
 *
 * @export
 * @enum {number}
 */
export enum NumberType {
    Article = 0,
    OE = 1,
    Trade = 2,
    Comparable = 3,
    Replacement = 4,
    Replaced = 5,
    GTIN = 6,
    Criteria = 7,
    Any = 10,
    FreeText = 99
}
