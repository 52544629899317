import {
    Injectable, EventEmitter
} from '@angular/core';

import { Router, RouterEvent, NavigationEnd, Event } from '@angular/router';

import { AuthResponse, TrackerService, AuthService } from '../../core';

import { GrowlService } from './growl';
import { filter } from 'rxjs';

@Injectable()
export class SessionManagerService {
  //#region Consts

  readonly ROOT_PUBLIC_URL: string = '/portal';
  readonly ROOT_PRIVATE_URL: string = '/admin';

  //#endregion

  //#region Fields

  private _idelSettings = {
    timeout: 60 * 15, // seconds
    interval: 5, // seconds
    intervalRef: 0,
    time: 0
  };

  private _refreshInProgress: boolean = false;
  private _logoutRequestedByUser: boolean;
  private _lastURL: string = '';
  public getToken(): string {
    return this._auth.token;
  }

  public loggedOut: EventEmitter<any> = new EventEmitter();
  public loggedIn: EventEmitter<string> = new EventEmitter();

  //#endregion

  //#region Constructor

  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _growl: GrowlService,
    private _tracker: TrackerService
  ) {
    this._auth.loggedIn.subscribe(this.onLoggedIn);
    this._auth.loggingOut.subscribe(this.onLoggingOut);
    this._auth.loggedOut.subscribe(this.onLoggedOut);

    this._router.events.pipe(
      filter((event: Event | RouterEvent) => event instanceof NavigationEnd)
    )
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!/^admin\/logout$/gi.test(event.url)) {
          this._lastURL = event.url;
        }
      }
    });

    // Reset idle time
    document.onclick = this.resetIdelCounter_Handler;
    document.onmousemove = this.resetIdelCounter_Handler;
    document.onkeypress = this.resetIdelCounter_Handler;
  }

  //#endregion

  //#region Public Methods

  public refresh() {
    console.log('sessionManager refresh');

    if (!this._auth.isLogged) {
      return;
    }

    //this.onLoggedIn();
    // Try refresh current token
    this._auth.refresh(null);
  }

  public logout() {
    console.log('sessionManager logout');
    this._logoutRequestedByUser = true;

    this._auth.logout();
  }

  //#endregion

  //#region Helpers

  private idleTimeCheck_Handler = () => {
    console.log('sessionManager idleTimeCheck_Handler');

    this._idelSettings.time += this._idelSettings.interval;

    if (!this._auth.isLogged) {
      this.onLoggedOut();
    } else if (this._idelSettings.time >= this._idelSettings.timeout) {
      this._auth.logout();
    } else if (new Date() >= this._auth.expirationDate && !this._refreshInProgress) {
      // If access token has expired then we try to update with
      // refresh token
      this._refreshInProgress = true;

      this._auth.refresh(null)
        .subscribe(
        (result: AuthResponse) => {

        },
        (err) => {

        },
        () => {
          this._refreshInProgress = false;
        }
      );

      setTimeout(() => {
        if (this._refreshInProgress) {
          this._auth.logout();
        }
      }, 5000);
    }
  }

  private resetIdelCounter_Handler = () => {
    this._idelSettings.time = 0;
  }

  //#endregion

  //#region Events Handlers

  private onLoggedIn = () => {

    console.log('sessionManager onLoggedIn');

    window.clearInterval(this._idelSettings.intervalRef);

    this._idelSettings.intervalRef = window.setInterval(this.idleTimeCheck_Handler, this._idelSettings.interval * 1000);
    //this._tracker.trackEvent('Authentication', 'Logged in');

    this.loggedIn.emit( this.getToken() );
  }

  private onLoggingOut = () => {
    //this._tracker.trackEvent('Authentication', 'Logged out');
  };

  private onLoggedOut = ( params?: any ) => {

    console.log('sessionManager onLoggedOut');
    // if (!this._router.isActive('/portal', false)) {
    this._router.navigate( this._lastURL.startsWith(this.ROOT_PRIVATE_URL)
      ? [this.ROOT_PUBLIC_URL]
      : [this._lastURL]
    );

    // Clear Idle time check
    window.clearInterval(this._idelSettings.intervalRef);
    this._idelSettings.intervalRef = null;
    this._idelSettings.time = 0;

    if (!this._logoutRequestedByUser) {
      this._growl.sessionExpired( params );
    }

    this._logoutRequestedByUser = false;

    this.loggedOut.emit();
  }

  //#endregion
}