export const AssemblyGroupRmiMapping = [
  {
    maingroupText: 'Accessories',
    assemblyGroupId: 100733,
    rmiMainGroupId: 1,
    subgroups: [
      {
        assemblySubgroupId: 0,
        rmiSubgroupId: 0
      }
    ]
  },
  {
    maingroupText: 'Engine',
    assemblyGroupId: 100002,
    rmiMainGroupId: 2,
    subgroups: [
      {
        assemblySubgroupId: 100754,
        rmiSubgroupId: 14
      },
      {
        assemblySubgroupId: 100541,
        rmiSubgroupId: 12
      },
    ]
  },
  {
    maingroupText: 'Transmission',
    assemblyGroupId: 100238,
    rmiMainGroupId: 3,
    subgroups: [
      {
        assemblySubgroupId: 0,
        rmiSubgroupId: 0
      }
    ]
  },
  {
    maingroupText: 'Chassis/steering',
    assemblyGroupId: 100012,
    rmiMainGroupId: 4,
    subgroups: [
      {
        assemblySubgroupId: 100198,
        rmiSubgroupId: 64
      }
    ]
  }
];
