export const VALIDATION_REQUIRED     = 'VALIDATION.REQUIRED';
export const VALIDATION_MIN_LENGTH   = 'VALIDATION.MIN_LENGTH';
export const VALIDATION_MAX_LENGTH   = 'VALIDATION.MAX_LENGTH';
export const VALIDATION_EMAIL        = 'VALIDATION.EMAIL';
export const VALIDATION_EMAIL_IN_USE = 'VALIDATION.EMAIL_IN_USE';
export const VALIDATION_EQUALS       = 'VALIDATION.EQUALS';
export const VALIDATION_NUMBERS      = 'VALIDATION.NUMBERS';
export const VALIDATION_UPPER_CASE   = 'VALIDATION.UPPER_CASE';
export const VALIDATION_LOWER_CASE   = 'VALIDATION.LOWER_CASE';
export const VALIDATION_SPECIAL_CHARS = 'VALIDATION.SPECIAL_CHARS';

