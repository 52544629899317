import { AppContext } from './models/app-context';
import { UserDetail } from './security/user-detail';
import { AuthTypes } from './security/auth-types';

export class CoreModuleConfig {
  readonly contextFactory?: () => Promise<AppContext>;
  readonly userFactory?: <T extends UserDetail>(data: any) => T;
  readonly authType?: AuthTypes;

  constructor() {}
}
