import { Injectable, EventEmitter } from '@angular/core';

import {
    CoreModuleConfig,
    AppContext
} from '../../.';
import { StorageService } from './storage';

/**
 * @file context.service.js
 * @module app.core
 * @summary Context service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2016
 */
@Injectable()
export class ContextService {
    //#region Const

     private readonly STORAGE_LANGUAGE = 'UTVPSJYHGZFEMIWQOXRCBKNLAD';

    //#endregion


    //#region Properties

    get instance(): AppContext {
        return this._context;
    }

    //#endregion

    //#region Fields

    private _context: AppContext;

    //#endregion

    //#region Events

    private _ready: EventEmitter<AppContext> = new EventEmitter<AppContext>();

    public get ready(): EventEmitter<AppContext> {
        return this._ready;
    }

    private storage: StorageService;

    //#endregion

    //#region Constructor

    constructor(private _config: CoreModuleConfig) {
    }

    public setStorageService( v: StorageService ): void {
      this.storage = v;
    }

    public saveUserName( userName ) {
      this.storage.setCookie(this.storage.getKey('userName'), (userName as string).trim().toLowerCase(), 1);
    }

    //#endregion

    //#region Methods

    public async load() {
        if (this._config.contextFactory) {
            this._context = await this._config.contextFactory();
        }

        this._ready.emit(this._context);
    }

    public getInstance<T extends AppContext>(): T {
        return (this._context as T);
    }


    /**
   * Updates the language and local storage copy.
   *
   * @param {string} code
   */
  public setLanguage(code: string) {
    if (!this.instance) {
      return;
    }

    if (!this.isLanguageAvailable(code)) {
      return;
    }

    this.instance.language = code;
    this.storage.set(this.STORAGE_LANGUAGE, code);
  }
    //#endregion

    /**
   * Checks if a code language is available.
   *
   * @param {string} code
   */
  public isLanguageAvailable(code: string): boolean {
    if (!this.instance) {
      return false;
    }

    if (!code || !this.instance.languages.find(item => item.code === code)) {
      return false;
    }

    return true;
  }

  //#endregion
}
