import { NumberTypes } from './number-types';
import { RmiTopicCriteriaFilter } from './topic-filter-criteria';
import { AssemblyGroupIcons } from './assembly-group-icons';
import { NumberPlates } from './number-plates';
import { Languages } from './languages';
import { VERSION } from './version';
import { AssemblyGroupRmiMapping } from './assembly-group-rmi-mapping';
import { SrsCategories } from './srs-categories';

// import { PAGE } from '@routes/index';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  header_title: 'Demand Dashboard',
  header_subtitle: '',
  context: {
    version: VERSION.version + '.' + VERSION.hash,
    baseUrl: '/',
    apiUrl: null,
    auth: {
      endpoint2: '/cis',
      endpoint: 'https://customerintegration.tecdoc.net/cis/json/TecdocToFrontend',
      lifetime: 60,
      autoRefresh: true,
      checkInterval: 5,
      timeout: 10,
      idelTimeout: 60 * 30,
      loginPage: 'portal/login',
      homePage: null
    },
    navigation: {
      // pages: PAGE,
      contextParams: ['mandator']
    },
    clientId: 'Analytics',
    language: 'en',
    languages: Languages,
    debug: true,
    hostingLocation: '[local]',
    country: 'DE',
    images: {
      tecalliance: {
        baseUrl: 'https://tecalliance-cdn-global-live.azureedge.net',
        useVersion: true
      },
      documents: {
        baseUrl: 'https://webservice.tecalliance.services/pegasus-3-0/documents',
        useVersion: false
      },
      local: {
        baseUrl: 'assets/images',
        useVersion: true
      }
    },
    providerId: null,
    OEDataSupplierIds: [9950, 9951],
    mandator: 'analytics',
    mandators: ['tecdocfull', 'oldtimer', 'ecars']
  },
  translate: {
    baseUrl: '/assets/i18n',
    default: 'en'
  },
  assemblyGroupRmiMapping: AssemblyGroupRmiMapping,
  rmiTopicCriteriaFilter: RmiTopicCriteriaFilter,
  numberTypes: NumberTypes,
  assemblyGroupIconsArray: AssemblyGroupIcons,
  numberPlateSearch: NumberPlates,
  srsCategories: SrsCategories,
  srs: {
    baseUrl: 'http://srs20-test.tecdoc.net/srs20',
    newReportUrl: '#issues/new',
    registerUrl: '#register',
    sourceId: 'TECDOCFULL_WEB',
    softwareVersion: '3.0',
    dataVersion: '0718',
    errorTypeId: 'D',
    defaultSupplierNo: '0'
  },
  tecdoc: {
    services: {
      baseUrl: 'https://webservice.tecalliance.services/pegasus-3-0/services/TecdocToCatDLW.jsonEndpoint',
      defaultHeaders: {
        'Content-Type': 'application/json'
      }
    }
  },
  cis: {
    services: {
      baseUrl: '/cis',
      baseUrl2: 'https://customerintegration.tecdoc.net/cis/json/TecdocToFrontend',
      pdfUrl: 'https://customerintegration.tecdoc.net/getDocument?cmd=generatePDF',
      defaultHeaders: {
        'Content-Type': 'application/json'
      }
    }
  },
  rmi: {
    services: {
      baseUrl: 'https://rmi-services-dev.tecalliance.net',
      authOrigin: 'https://rmi-services-dev.tecalliance.net',
      authScheme: 'TecRMI'
    }
  },
  recommender: {
    url: 'https://service.analytics.tecalliance.net/recommender/api'
  },
  api: {
    fixedParams: '',
    url: 'https://service.analytics.tecalliance.net/analytics/api',
    piwikApiUrl: 'https://service.analytics.tecalliance.net/analytics/api/piwikapi'
  }
};
