export class UserRoleChecker {

    public static isAdmin( props: any ): boolean {
        return props && ( props.userRole === 'admin' || this.isSuperAdmin(props) );
    }

    public static isSuperAdmin( props: any ): boolean {
        return props && props.userRole === 'superAdmin'
    }

    public static isInternalUser( props: any) {
      return this.isAdmin( props ) || this.isSuperAdmin( props ) ||
                props && props.userRole === 'internal_user';
    }
}
