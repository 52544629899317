import { Component } from '@angular/core';

import { SessionManagerService } from '@app/shared/services/session-manager';

@Component({
  selector: 'app-logout-page',
  template: ''
})

export class LogoutPage {
  constructor(private sessionManager: SessionManagerService) {
    this.sessionManager.logout();
  }
}
