import { UserDetail } from './user-detail';

/**
 * @summary Authentication response.
 */
export class AuthResponse {
  status: number;
  status2?: number;
  message: string;
  userDetail: UserDetail;
  frontendCachePaths: string[];
}
