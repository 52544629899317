import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

/**
 * @file taskTracker.service.js
 * @module app.core
 * @summary TraskTracker service.
 * @description
 * Task tracker service.
 *
 * @version 0.0.0
 * @copyright 2016
 */
@Injectable()
export class TaskTrackerService {
    //#region Fields

    private taskList: Array<Promise<any> | Subscription | Observable<any>> = [];

    //#endregion

    //#region Constructor

    constructor() {
    }

    //#endregion

    //#region Public Methods

    public add(task: Promise<any> | Subscription | Observable<any>): Promise<any> | Subscription | Observable<any> {
        if (this.taskList.indexOf(task) !== -1) {
            return task;
        }

        this.taskList.push(task);

        if (task instanceof Promise) {
            (task as Promise<any>).then(
                () => this.remove(task),
                () => this.remove(task)
            );
        } else if (task instanceof Subscription) {
            (task as Subscription).add(() => this.remove(task));
        } else {
            (task as Observable<any>).subscribe(
                () => { },
                () => {
                    this.remove(task);
                },
                () => {
                    this.remove(task);
                }
            );
        }

        return task;
    }

    public remove(task: Promise<any> | Subscription | Observable<any>): void {
        let index = this.taskList.indexOf(task);

        if (index === -1) {
            return;
        }

        this.taskList.splice(index, 1);
    }

    public clearTasks(){
      this.taskList = [];
    }

    public anyActiveTask(): boolean {
        return this.taskList.length > 0;
    }

    //#endregion
}
