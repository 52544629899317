import { EventEmitter, Injectable } from '@angular/core';

import { SelectItem, SelectItemGroup } from 'primeng/api';

import { Brand } from '@app/shared/models/brand';

import { AnalyticsFilterData } from './analytics-filter-data';

export interface AnalyticsFilterOptions {
  showBrandFilter?: boolean;
  showSearchTypeIdFilter?: boolean;
  showSearchTypeOeIdFilter?: boolean;
  showNumberPlateTypeFilter?: boolean;
  showVehicleTypesFilter?: boolean;
  showCountryFilter?: boolean;
  showDatasourceFilter?: boolean;
  showGenericArticleFilter?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsFilterService {
  private readonly DEFAULT_OPTIONS: AnalyticsFilterOptions = {
    showBrandFilter: true,
    showSearchTypeIdFilter: false,
    showSearchTypeOeIdFilter: false,
    showNumberPlateTypeFilter: false,
    showVehicleTypesFilter: false,
    showCountryFilter: true,
    showDatasourceFilter: true,
    showGenericArticleFilter: true,
  };

  pageKey: string;
  options: AnalyticsFilterOptions = {...this.DEFAULT_OPTIONS};

  brands: Brand[];
  datasources: SelectItemGroup[];
  locations: SelectItem[];
  searchTypes: SelectItem[];
  vehicleTypes: SelectItem[];
  numberPlateTypes: SelectItem[];

  isFilterEnabled = true;

  setupFilterEnd = new EventEmitter();
  applyFilter = new EventEmitter<AnalyticsFilterData>();

  constructor() { }

  setupFilterForPage(pageKey: string, options: AnalyticsFilterOptions = {}, emitEvent = true) {
    this.isFilterEnabled = true;
    this.pageKey = pageKey;

    // To avoid "NG0100: Expression has changed after it was checked" error
    setTimeout(() => {
      this.options = {
        ...this.DEFAULT_OPTIONS,
        ...options,
      };

      if (emitEvent) {
        this.setupFilterEnd.emit();
      }
    });
  }

  disableFilter() {
    // To avoid NG0100 ("Expression has changed after it was checked") error
    setTimeout(() => this.isFilterEnabled = false);
  }
}
