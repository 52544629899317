import { FacetItem } from "../models/facet-item";

export class PercentCalculator {

    /**
     * calculate percent of all items to 100%
     *
     * return total value
     */
    static calculate(fis: FacetItem[]): number {

        let total = this.getTotal(fis);

        for (const f of fis) {
            this.calcPercent(f, total);
            this.addPercentToLabel(f);
        }

        return total;
    }

    static calculateWithTotal(fis: FacetItem[], total: number): number {

        for (const f of fis) {
            this.calcPercent(f, total);
        }
        return total;
    }

    static calcPercent( f, total ) {
        f.percent = parseFloat(((f.count * 100) / total).toFixed(2));
    }

    static round( v: number ) {
        return parseFloat(v.toFixed(2));
    }

    static addPercentToLabel( f ) {
        f.label = f.name + " " + " (" + f.percent + "%)";
    }

    static getTotal(fis: FacetItem[]): number {
        let total = 0;
        for (const f of fis) {
            total += f.count;
        }
        return total;
    }
}
