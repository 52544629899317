import { Injectable, EventEmitter } from '@angular/core';


@Injectable()
export class UIActionsService {
  //#region Authentication Emitters

  public get switchMenu(): EventEmitter<any> {
    return this._switchMenu;
  }

  private _switchMenu: EventEmitter<any> = new EventEmitter();

  //#endregion
}