import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit, ViewChild
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AuthService, ReleaseNotesService, StorageService} from '@app/core';
import {Version} from '@app/shared/models/version';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Router} from '@angular/router';
// import {ApiService} from '@app/shared';
import {BasicResponse} from '@app/shared/models/basic-response';
import { Observable } from 'rxjs';

@Component({
  //moduleId: module.id,
  selector: 'portal-footer',
  templateUrl: 'portal-footer.html',
  styleUrls: ['portal-footer.scss']
})
export class PortalFooterComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Fields
  //#endregion

  private readonly STORAGE_VERSION = 'DDBVERSION';

  private versionObj: Version;

  @ViewChild('versionBubble', {static: true}) versionBubble: OverlayPanel;

  //#region Constructor
  get isLogged(): boolean {
    return this._auth.isLogged;
  }

  constructor( // private api: ApiService,
              private _auth: AuthService,
              private _releaseNoteService: ReleaseNotesService,
              private storage: StorageService,
              private http: HttpClient,
  ) {
    _releaseNoteService.getVersion().subscribe((version: Version) => {
      this.setVersion(version);
    });
  }

  private setVersion( v: Version ) {
    // backup because before get may be a version and next request fail so let it show last known version
    let version = this.version;

    if( v && v.statusCode == 200 && v.version
           && v.version.trim().length >= 5 ) {
      version = 'v' + v.version;
      this.versionObj = v;
    };
    this.version = version;
  }

  private scheduleVersionCheck() {
    console.log('scheduleVersionCheck()');

    this.checkVersion();

    window.setInterval(() => {
      this.checkVersion();
    }, 300000); // check all 5 minutes
  }

  private hideBubble() {
    if( this.versionBubble ) {
        this.versionBubble.hide();
      }
  }

  private checkVersion() {
    if( !this.isLogged ) {
      this.hideBubble();
      return;
    }

    this._releaseNoteService.getVersion().subscribe((data: Version) => {
      //console.log(data);
      this.setVersion( data );

      if( this.versionObj ) {
        this.retryLoadVersion = 0;
        this.loadAndCompareVersion( this.versionObj );
      }
    });
  }

  loadAndCompareVersion( data: Version ) {
    // this.api.getSettings(this.STORAGE_VERSION, true)
    //     .subscribe(( version: BasicResponse) => {

    //       if( !version ) {
    //         return;
    //       }

    //       if( version.statusCode !== 200 ) {
    //         this.retryLoadVersion++;
    //         if( this.retryLoadVersion <= 5 ) {
    //           window.setTimeout(() => {
    //             this.loadAndCompareVersion(data);
    //           }, 5000);
    //         }
    //         return;
    //       }

    //       let storedVersion = null;
    //       if( version.message && version.message.startsWith("{") && version.message.endsWith("}") ) {
    //         storedVersion = JSON.parse(version.message);
    //       }

    //       if (!storedVersion ||
    //             ( storedVersion && (storedVersion.version !== data.version
    //             || storedVersion.build !== data.build) ) ) {

    //         console.log('Version change', data);

    //         document.getElementById('versionLink').onmouseover = (event) => {
    //           if( this.versionBubble ) {
    //             this.versionBubble.show(event);
    //           }
    //         };
    //         const mouseoverEvent = new Event('mouseover');
    //         document.getElementById('versionLink').dispatchEvent(mouseoverEvent);
    //       } else
    //       if( storedVersion &&
    //             storedVersion.version === data.version &&
    //               storedVersion.build === data.build ) {
    //         if( this.versionBubble.overlayVisible ) {
    //           this.versionBubble.hide();
    //         }
    //       }
    //   });
  }

  //#endregion

  //#region Lifecycle Hooks


  storeVersionInDB() {
    // try {
    //   if (this.versionObj) {
    //     this.api.saveSettings(this.STORAGE_VERSION, JSON.stringify(this.versionObj))
    //       .subscribe((data: BasicResponse) => {
    //       if( data.statusCode !== 200 ) {
    //         this.retryStoreVersion++;
    //         if( this.retryStoreVersion <= 5 ) {
    //           this.storeVersionInDB();
    //         }
    //       }
    //     });
    //   }
    // } catch( ex ) {
    //   console.log( 'storeVersion error:', ex );
    // }
  }

  storeVersion() {
    this.retryStoreVersion = 0;
    this.storeVersionInDB();
  }

  ngOnInit() {
    this.scheduleVersionCheck();

    this._auth.loggedOut.subscribe(( event ) => {
      this.hideBubble();
    });
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    // this._element.nativeElement.style.display = 'block';
  }

  //#endregion

  //#region Helpers
  //#endregion

  //#region Events Handlers
  //#endregion
  version: string = '';

  readonly currentYear = new Date().getFullYear();

  retryLoadVersion = 0;
  retryStoreVersion = 0;

  licensesText: Observable<string>;
  showLicensesDialog: boolean = false;
  showLegalNoticeDialog: boolean = false;

  reloadPage() {
    this.storeVersion();
    window.location.reload();
  }

  openReleaseNotes() {
    this.storeVersion();
    window.open('/portal/release-notes', 'ddbReleaseNotes');

    /*window.setTimeout(() => {
      this.reloadPage();
    }, 1000);*/
  }

  onCloseClick() {
    this.storeVersion();
    this.versionBubble.hide();
  }

  onLicensesAnchorClick(event: MouseEvent) {
    event.preventDefault();
    this.licensesText = this.http.get('/3rdpartylicenses.txt', {responseType: 'text'});
    this.showLicensesDialog = true;
  }

  onLegalNoticeAnchorClick(event: MouseEvent) {
    event.preventDefault();
    this.showLegalNoticeDialog = true;
  }
}
