/**
 * Enums that defines type of SRS category.
 *
 * @enum {SrsCategoryType}
 */
export enum SrsCategoryType {
  Vehicle = 0,
  Article = 1,
  VehicleLinkage = 2,
  Unknown = 3
}
