import { Injectable } from '@angular/core';

import { TourService } from 'ngx-ui-tour-ng-bootstrap';
import { INgbStepOption } from 'ngx-ui-tour-ng-bootstrap/lib/step-option.interface';

/**
 * Service for Demand Dashboard tour functionality.
 */
@Injectable({providedIn: 'root'})
export class DashboardTourService {
  /**
   * List of paths where the dasbourd tour is available.
   */
  private readonly TOUR_SUPPORTED_URLS = [
    '/analytics/market-overview-competition',
    '/analytics/market-overview-trends',
    '/analytics/article-views-brands',
    '/analytics/article-views-genarts',
    '/analytics/article-views-article-numbers',
    '/analytics/article-views-oe-numbers',
    '/analytics/article-views-vehicles',
    '/analytics/article-views-countries',
    '/analytics/most-searched-numbers',
    '/analytics/most-searched-vehicles'
  ];

  /**
   * Steps of the dashboard tour.
   */
  private readonly TOUR_STEPS: INgbStepOption[] = [
    {
      anchorId: 'start-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      content: `
        <div class="d-flex flex-row p-3">
          <div class="img-welcome me-3"></div>
          <div class='tour-text'>
            <p class="tour-font-title">Welcome to the Demand Dashboard Product Tour!</p>
            <p class="tour-font-normal">
              With this product tour, you will learn the basic functionalities of the Demand
              Dashboard. Creating analyses and reports will become even more accessible.
            </p>
            <p class="tour-font-normal">Click "Next" to start the tour.</p>
          </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      },
    },
    {
      anchorId: 'main-nav-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      content: `
         <div class="d-flex flex-row p-3">
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Main Navigation</p>
                <p class="tour-font-normal">
                  <b>Market overview</b>
                  <br> Get insights in a direct overview of your products compared to the competition. The total article views of your products in the respective data source, e.g., the TecDoc Catalogue 3.0, is measured. Under Trends, you can view the market development for each generic article.
                </p>
                <p class="tour-font-normal">
                  <b>Article views</b>
                  <br> Compare the percentage of article views calculated with the following parameters: Brands, Generic articles, Article numbers, OE numbers, Vehicles, and Countries.
                </p>
                <p class="tour-font-normal">
                  <b>Most searched</b>
                  <br> Find and compare the percentage of the most searched numbers, e.g., Article numbers, OE numbers, or Competitor article numbers in the overall usage.
                </p>
            </div>
         </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'filter-bar-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      placement: "bottom",
      content: `
        <div class="d-flex flex-row p-3">
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Filter bar</p>
                  <p class="tour-font-normal">
                    <i class="fa ta-icon-clock"></i>
                    <b>Filter by Time</b>
                    <br> Define the period for your analysis with predefined values, or define your analysis period with a calendar picker.
                    </p>
                  <p class="tour-font-normal">
                    <i class="fa ta-icon-tags"></i>
                    <b>Filter by Brand</b>
                    <br> Choose your brand(s) for the comparison. Include or exclude brands to refine your analysis.
                  </p>
                  <p class="tour-font-normal">
                    <i class="fa ta-icon-location"></i>
                    <b>Filter by Location</b>
                    <br> Localize specific markets and look at the figures of the predefined regions or single countries.
                  </p>
                  <p class="tour-font-normal">
                    <i class="fa ta-icon-master-data"></i>
                    <b>Filter by Data Source</b>
                    <br> Select the data source from which you want to draw the information for your analysis.
                  </p>
                  <p class="tour-font-normal">
                    <i class="fa ta-icon-cw"></i>
                    <b>Refresh Data</b>
                    <br> Please don't forget to refresh after you change your selection. The refresh button will be disabled (light green), when the data is updated.
                  </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'genart-filter-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      content: `
        <div class="d-flex flex-row p-3">
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Generic Article Filter</p>
                <p class="tour-font-normal"> With the Generic Article Filter you can focus your research on the relevant product groups. </p>
                <p class="tour-font-normal"> You can search for the desired generic article via name or GenArt number in the input field. An intelligent proposal list helps you to quickly create your selection group. </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'fav-genarts-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      content: `
        <div class="d-flex flex-row p-3">
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Favorites</p>
                <p class="tour-font-normal">
                    To save a single or a range of generic articles from the selection field, you have to press the favorites button <i class="fa ta-icon-star"></i>. This selection will be saved as a favorite and is permanently available in the dropdown list.
               </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'tooltip-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      content: `
        <div class="d-flex flex-row p-3">
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Detailed Information</p>
                <p class="tour-font-normal">
                    If you move the mouse cursor over the <i class="fa ta-icon-info-circled"></i> icon, you will get additional information about the particular chart type and the method of how the data is collected.
                </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'exp-report-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      content: `
        <div class="d-flex flex-row p-3">
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Export Report</p>
                <p class="tour-font-normal">
                    To export a report, you have to press the save button <i class="fa ta-icon-save"></i>in the right corner of the chart header. You can choose between two formats to save your reports as CVS and PDF. When you select PDF, you get a saved view of your current selection.
                </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'drill-down-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      centerAnchorOnScroll: true,
      placement: 'after',
      popoverClass: 'position-absolute top-50 start-50 translate-middle mw-100 tour-popover-no-arrow',
      content: `
        <div class="d-flex flex-row p-3">
            <div class="img-drilldown me-3"></div>
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Chart drill-down Information</p>
                <p class="tour-font-normal">
                    Behind many charts are hidden drill-down information; the mouse cursor changes from an arrow to hand by hovering a pillar when additional information is available. When you see a hand icon, click on the chart to see more details.
                </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    },
    {
      anchorId: 'end-tour',
      title: '<span class="me-2"><i class="fa ta-icon-graduation-cap"></i></span><span class="tour-font-normal">PRODUCT TOUR</span>',
      popoverClass: 'position-absolute top-50 start-50 translate-middle mw-100 tour-popover-no-arrow',
      content: `
        <div class="d-flex flex-row p-3">
            <div class="img-complete me-3"></div>
            <div class='tour-text'>
                <p class="tour-font-title fw-bold">Congratulations you have completed the Product Tour!</p>
                <p class="tour-font-normal">
                    You now know the basic functionalities of the tool and we encourage you to try your knowledge out. You can access the product tour again from the top-right corner and good luck with your analysis!
                </p>
            </div>
        </div>
      `,
      stepDimensions: {
        minWidth: '600px',
      }
    }
  ]

  /**
   * Storage key of the cookie that flags if the tour has been alrady seen or not.
   */
  readonly TOUR_SEEN_KEY = 'tourSeen';

  constructor(public tourService: TourService) {
    this.tourService.initialize(this.TOUR_STEPS, {
      enableBackdrop: true,
      showProgress: false,
      prevBtnTitle: 'Back',
      nextBtnTitle: 'Next',
      endBtnTitle: 'Done',
      popoverClass: 'mw-100 tour-popover-no-arrow',
      disablePageScrolling: true,
      disableScrollToAnchor: true,
      backdropConfig: {
        zIndex: '1050'
      }
    });
  }

  /**
   * Checks if the tour can be shown or not on the specified path.
   *
   * @param path The path to be checked.
   * @returns `true` if the tour can be shown in the specified path; otherwise `false`.
   */
  isTourSupported(path: string): boolean {
    return this.TOUR_SUPPORTED_URLS.includes(path);
  }
}
