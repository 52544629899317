import { Injectable, Injector, EventEmitter } from '@angular/core';

import { CoreModuleConfig } from '../../core-module-config';
import { Authentication } from '../../security/authentication';
import { AuthResponse } from '../../security/auth-response';

import { UserDetail } from '../../security/user-detail';
import { AuthTypes } from '../../security/auth-types';
import { AuthBaseService } from './auth-base';
import { OAuth2Service } from './oauth2';
import { JWTAuthService } from './jwt-auth';
import { CisAuthService } from './cis-auth';

import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { StatusResponse } from './cis/status-response';

/**
 * Authentication wrapper service that internally uses the specific
 * auth service indicated in the core settings.
 *
 * @file auth.js
 * @module app.core
 * @summary Core service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2018
 */
@Injectable()
export class AuthService implements Authentication {
  //#region Fields

  private auth: AuthBaseService;

  //#endregion

  //#region Properties

  public get type(): AuthTypes {
    return this.auth.type;
  }

  public get token(): string {
    return this.auth.token;
  }

  public get refreshToken(): string {
    return this.auth.refreshToken;
  }

  public get expirationDate(): Date {
    return this.auth.expirationDate;
  }

  public get userDetail(): UserDetail {
    return this.auth.userDetail;
  }

  public get isLogged(): boolean {
    return this.auth.isLogged;
  }

  private _validated: EventEmitter<any> = new EventEmitter();
  public get validated(): EventEmitter<any> {
    return this._validated;
  }

  //#endregion

  //#region Authentication Emitters

  public get loggedIn(): EventEmitter<any> {
    return this.auth.loggedIn;
  }

  public get refreshed(): EventEmitter<any> {
    return this.auth.refreshed;
  }

  public get iddledForSeconds(): EventEmitter<any> {
    return this.auth.iddledForSeconds;
  }

  public get expired(): EventEmitter<any> {
    return this.auth.expired;
  }

  public get loggingOut(): EventEmitter<any> {
    return this.auth.loggingOut;
  }

  public get loggedOut(): EventEmitter<any> {
    return this.auth.loggedOut;
  }

  /*public get fullVersionRequested(): boolean {
    return this.auth.fullVersionRequested;
  }

  public set fullVersionRequested( v: boolean ) {
    this.auth.fullVersionRequested = v;
  }*/

  //#endregion

  //#region Constructor

  constructor(
    private config: CoreModuleConfig,
    private injector: Injector
  ) {
    switch (this.config.authType) {
      case AuthTypes.Jwt:
        this.auth = this.injector.get(JWTAuthService);
        break;

      case AuthTypes.Oauth2:
        this.auth = this.injector.get(OAuth2Service);
        break;

      case AuthTypes.Cis:
      default:
        this.auth = this.injector.get(CisAuthService);
        break;
    }
  }

  //#endregion

  //#region Methods

  /**
   * If exist a token it tries to validate it calling the refresh method.
   * If the token is validated successfully, it initializes the task which
   * refreshes the token automatically while it is alive. This method only have
   * to be called when the app is initiating.
   */
  public validateSession(): Observable<AuthResponse> {
    return this.refresh()
      .pipe(
        tap(res => {
          if (res.status === 200) {
            this.auth.initCheckSession();
            this.validated.emit();
          } else {
            console.error('Session expired');
            this.expired.emit();
          }
        }),
        catchError((err, caught) => {
          console.error('Session expired %O', err);
          this.expired.emit();
          return of(new AuthResponse());
        })
      );
  }

  public setUser(user: UserDetail) {
    this.auth.setUser(user);
  }

  public login(body: any): Observable<AuthResponse> {
    return this.auth.login(body);
  }

  public forgotPassword(body: any): Observable<StatusResponse> {
    return this.auth.forgotPassword(body);
  }

  public changePassword(body: any): Observable<StatusResponse> {
    return this.auth.changePassword(body);
  }

  public refresh(body?: any): Observable<AuthResponse> {
    return this.auth.refresh(body);
  }

  public logout() {
    this.auth.logout();
  }

  public getUserDetail<T extends UserDetail>(): T {
    return this.auth.getUserDetail<T>();
  }

  public updateLocalStorage() {
    this.auth.updateLocalStorage();
  }

  //#endregion
}
