@import "../../../../scss/variables";

ul.nav.navbar-nav.flex-column {
  font-size: 13px;
  line-height: .5em;
  list-style: none;
  margin-bottom: 0;
  padding: 0;

  & > li {
    padding: 0!important;
    border-bottom: 1px solid $border-color!important;

    &.active, &:hover {
      border-left: 10px solid $ta-active-color;
      background-color: $ta-sidebar-active-bg;

      & a {
        padding-left: 20px!important;
        white-space: nowrap;
      }
    }

    & > a {
      padding-left: 30px!important;
    }

    ul {
      padding: 0 0 0 20px;

      li a {
        padding-left: 30px;
        padding-top: 0!important;
      }
    }
  }

  li {
    overflow-y: hidden;

    &:hover {
      font-weight: 600;
      background-color: $ta-sidebar-active-bg;
    }

    &.active {
      font-weight: 600;
      color: $ta-text-color;
    }

    a {
      position: relative;
      padding: 16px;
      color: $ta-text-color;
      display: block;
      font-size: 14px;
      font-weight: 300;
      line-height: normal;
      text-decoration: none !important;

      &:hover {
        font-weight: 600;
        color: $ta-text-color;
      }

      &.active {
        font-weight: 600;
        color: $ta-text-color;
      }

      &:focus {
        color: $ta-text-color;
      }
    }
  }
}

.upgrade-now-button {
  color: $ta-light-color !important;
  background: $ta-cta-bg 0% 0% no-repeat padding-box !important;
  border: 1px solid $ta-cta-bg;
}
