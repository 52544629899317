import {
  OnInit,
  OnDestroy,
  AfterViewInit, Injectable
} from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import {
    DISCART_CHANGE_TITLE,
    DISCART_CHABGE_MESSAGE,
    CONFIRM_DELETE_TITLE,
    CONFIRM_DELETE_MESSAGE,
    CONFLICT_DELETE_USER_TITLE,
    CONFLICT_DELETE_USER_MESSAGE
} from '../consts/messages';

import { TrackerService } from '../../core';
import { Features } from '../models/features';
import { ApiService } from '../services/api.service';

/**
 * Base entity page component.
 */

@Injectable()
export abstract class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Properties

  abstract get pageName(): string;

  //#endregion

  //#region Constructor

  constructor(
    protected _tracker: TrackerService,
    protected _confirmation: ConfirmationService,
    protected analyticsApi: ApiService,
    protected router: Router,
  ) { }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this._tracker.trackPageView(this.pageName);
  }

  //#endregion

  //#region Abstract Methods

  /**
   * Returns a flag that indicates if the entity has changes.
   */
  public abstract hasChanges(): boolean;

  //#endregion

  //#region Public Methods

  public discartChanges(acceptCallback: Function, discartCallback?: Function): void {
    if (!this.hasChanges()) {
      acceptCallback();
      return;
    }

    this._confirmation.confirm({
      key: 'discart-changes',
      header: DISCART_CHANGE_TITLE,
      message: DISCART_CHABGE_MESSAGE,
      accept: () => {
        acceptCallback();
      },
      reject: () => {
        if (discartCallback) {
          discartCallback();
        }
      }
    });
  }

  public confirmDelete(acceptCallback: Function, discartCallback?: Function, title?: string, message?: string): void {
    this._confirmation.confirm({
      key: 'discart-changes',
      header: title || CONFIRM_DELETE_TITLE,
      message: message || CONFIRM_DELETE_MESSAGE,
      accept: () => {
        acceptCallback();
      },
      reject: () => {
        if (discartCallback) {
          discartCallback();
        }
      }
    });
  }

  public showMessage( msg: string, title: string,  acceptCallback?: Function, discartCallback?: Function): void {
    this._confirmation.confirm({
      key: 'warning-message',
      header: ' ' + title,
      message: msg,
      accept: () => {
        acceptCallback();
      },
      reject: () => {
        if (discartCallback) {
          discartCallback();
        }
      }
    });
  }

  public goToFirstAuthorizedItem(validFeatures?: Features[]) {
    if (validFeatures?.length) {
      this.router.navigate([validFeatures[0].defaultPath]);
      return;
    }

    this.analyticsApi.getProps().subscribe((props: any) => {
      let authorizedFeatureIds = props.features;
      if (this.analyticsApi.isAdmin()) {
        authorizedFeatureIds.push(Features.ADMIN.id);
      }
      if (this.analyticsApi.isInternalUser()) {
        authorizedFeatureIds.push(Features.VEHICLE_SEARCHES.id);
      }
      let authorizedFeaturesOnMenu = Features.ALL.filter(f => authorizedFeatureIds.indexOf(f.id) !== -1 && f.defaultPath !== '');
      let path = authorizedFeaturesOnMenu[0].defaultPath;

      this.router.navigate([path]);
    });
  }

  public conflictDeleteUser() {
    this._confirmation.confirm({
      key: 'warning-message',
      header: CONFLICT_DELETE_USER_TITLE,
      message: CONFLICT_DELETE_USER_MESSAGE,
      accept: () => { }
    });
  }

  //#endregion
}
