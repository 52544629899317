import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';

import { SharedModule } from '@app/shared/shared.module';

import * as pages from './pages';
import { AnonymousRoutingModule } from './anonymous-routing.module';
import { LoginFormComponent } from './pages/login/components/login-form/login-form';
import { ForgotPasswordFormComponent } from './pages/forgot-password/components/forgot-password-form/forgot-password-form.component';

/**
 * This module contains components and other functionalities that can be
 * used in the application without being authenticated.
 */
@NgModule({
  declarations: [
    pages.LoginPage,
    LoginFormComponent,
    pages.LogoutPage,
    pages.ForgotPasswordPage,
    ForgotPasswordFormComponent,
  ],
  imports: [
    CommonModule,
    AnonymousRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    TooltipModule,
  ],
  providers: [ConfirmationService],
})
export class AnonymousModule { }
