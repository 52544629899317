import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SelectItem, SelectItemGroup } from 'primeng/api';
import { MultiSelectChangeEvent } from 'primeng/multiselect';

import { Brand } from '@app/shared/models/brand';
import { FacetItem } from '@app/shared/models/facet-item';
import { ApiService } from '@app/shared/services/api.service';

import { AnalyticsFilterService } from './analytics-filter.service';

import { Observable, combineLatest, lastValueFrom, map, mergeMap, of } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrl: './analytics-filter.component.scss'
})
export class AnalyticsFilterComponent implements OnInit {
  private readonly MAX_SELECTABLE_ITEMS = 100;

  get showBrandFilter(): boolean {
    return this.analyticsFilter.options.showBrandFilter;
  }

  get showSearchTypeIdFilter(): boolean {
    return this.analyticsFilter.options.showSearchTypeIdFilter;
  }

  get showSearchTypeOeIdFilter(): boolean {
    return this.analyticsFilter.options.showSearchTypeOeIdFilter;
  }

  get showNumberPlateTypeFilter(): boolean {
    return this.analyticsFilter.options.showNumberPlateTypeFilter;
  }

  get showVehicleTypesFilter(): boolean {
    return this.analyticsFilter.options.showVehicleTypesFilter;
  }

  get showCountryFilter(): boolean {
    return this.analyticsFilter.options.showCountryFilter;
  }

  get showDatasourceFilter(): boolean {
    return this.analyticsFilter.options.showDatasourceFilter;
  }

  get showGenericArticleFilter(): boolean {
    return this.analyticsFilter.options.showGenericArticleFilter;
  }

  get isFilterEnabled(): boolean {
    return this.analyticsFilter.isFilterEnabled;
  }

  filterForm: FormGroup;

  get brands(): Brand[] {
    return this.analyticsFilter.brands;
  }

  get datasources(): SelectItemGroup[] {
    return this.analyticsFilter.datasources;
  }

  get locations(): SelectItem[] {
    return this.analyticsFilter.locations;
  }

  get searchTypes(): SelectItem[] {
    return this.analyticsFilter.searchTypes;
  }

  get vehicleTypes(): SelectItem[] {
    return this.analyticsFilter.vehicleTypes;
  }

  get numberPlateTypes(): SelectItem[] {
    return this.analyticsFilter.numberPlateTypes;
  };

  get applyFilterButtonTooltipText(): string {
    const selectedItemsCount = this.filterForm.get('brands')?.value?.length || 0;
    return selectedItemsCount <= this.MAX_SELECTABLE_ITEMS
      ? 'ANALYTICS_FILTER.APPLY_SETTINGS'
      : 'ANALYTICS_FILTER.MAX_FILTER_ITEMS';
  }

  get applyFilterButtonTooltipParams() {
    const selectedItemsCount = this.filterForm.get('brands')?.value?.length || 0;
    return {
      maxSelectableItems: this.MAX_SELECTABLE_ITEMS,
      selectedItemsCount,
    };
  }

  get isApplyFilterButtonEnabled(): boolean {
    return this.filterForm.dirty;
  }

  datasourcesGroups: {[key: string]: string} = {};

  constructor(
    private analyticsFilter: AnalyticsFilterService,
    private fb: FormBuilder,
    private api: ApiService
  ) {
    this.buildFilterForm();
  }

  async ngOnInit() {
    await lastValueFrom(this.loadFilterData());
    this.analyticsFilter.applyFilter.emit(this.filterForm.getRawValue());

    this.analyticsFilter.setupFilterEnd
      .subscribe(async () => {
        await lastValueFrom(this.loadFilterData());
        this.analyticsFilter.applyFilter.emit(this.filterForm.getRawValue())
      });
  }

  private buildFilterForm(): void {
    const defaultDatesValue = 'lastMonth';

    this.filterForm = this.fb.group({
      timespan: this.fb.group({
        isRange: [false],
        dates: this.fb.control(defaultDatesValue),
      }),
      brands: this.fb.control([]),
      searchTypes: this.fb.control([]),
      numberPlateTypes: this.fb.control([]),
      vehicleTypes: this.fb.control([]),
      locations: this.fb.control([]),
      datasources: this.fb.control([]),
      genarts: this.fb.control([]),
    });
  }

  private loadFilterData(): Observable<void> {
    return this.api.getUserDataSources(this.analyticsFilter.pageKey)
      .pipe(
        mergeMap(datasources => {
          // Get datasource from localStorage, or select the first available option.
          let selectedDatasources = this.api.getSelectedDatasourceId().join(',');
          if (!this.areValidSelectedDatasources(selectedDatasources, datasources)) {
            selectedDatasources = datasources[0].id
            this.api.setSelectedDatasourceId([selectedDatasources]);
          }

          // Set selected datasource as control value
          this.filterForm.get('datasources').setValue(this.api.getSelectedDatasourceId());

          return combineLatest([of(datasources), of(selectedDatasources)]);
        }),
        mergeMap(([datasources, selectedDatasource]) => {
          // Retrieve filters data
          return combineLatest([
            this.api.getBrands(selectedDatasource),
            of(datasources),
            this.api.getResources('countries'),
            (this.showSearchTypeIdFilter || this.showSearchTypeOeIdFilter) ? this.api.getResources('articleNumberTypes') : of({}),
            this.showVehicleTypesFilter ? this.api.getResources('vehicleTypes') : of({}),
            this.showNumberPlateTypeFilter ? this.api.getResources('numberPlateTypes') : of({})
          ]);
        }),
        map(([brands, datasources, countries, searchTypes, vehicleTypes, numberPlateTypes]) => {
          // Setup brands
          this.analyticsFilter.brands = _.orderBy(brands, ['brandName'])
            .map(x => <Brand>({
              ...x,
              brandLabel: `${x.brandName} [${x.brandId}]`,
            }));

          // Setup datasoruces (grouped)
          datasources.forEach(x => this.datasourcesGroups[x.id] = x.group);
          const groupedDatasources = _.groupBy(datasources, 'group');
          this.analyticsFilter.datasources = Object.values(groupedDatasources)
            .map<SelectItemGroup>(group => ({ label: '', value: '', items: group.map(x => ({ label: x.name, value: x.id })) }));

          // Setup countries (first regions, then individual countries)
          const regions = _.pick(countries, Object.keys(countries).filter(x => x.includes(',')));
          const regionSelectItems = _.orderBy(Object.entries(regions).map<SelectItem>(([key, value]) => ({ label: value, value: key, styleClass: 'fw-bold' })), 'label');
          const individualCountries = _.omit(countries, Object.keys(regions));
          const countrySelectItems = _.orderBy(Object.entries(individualCountries).map(([key, value]) => ({ value: key, label: value })), 'label');
          this.analyticsFilter.locations = [...regionSelectItems, ...countrySelectItems];

          // Setup search types
          const searchTypesFormControl = this.filterForm.get('searchTypes');
          searchTypesFormControl.enable({ onlySelf: true, emitEvent: true });

          if (searchTypes) {
            this.analyticsFilter.searchTypes = _.orderBy(Object.entries(<{[k: string]: string}>searchTypes).map(([key, value]) => ({ value: key, label: value })), 'label');
            if (this.showSearchTypeOeIdFilter) {
              // Set "OE numbers" option on filter and disable the control.
              const oeNumbersOptions = this.searchTypes.find(x => x.label.startsWith("OE"));
              const searchTypesFormControl = this.filterForm.get('searchTypes');
              searchTypesFormControl.setValue([oeNumbersOptions.value]);
              searchTypesFormControl.disable({ onlySelf: true, emitEvent: true });
            }
          }

          // Setup vehicle types
          if (vehicleTypes) {
            this.analyticsFilter.vehicleTypes = _.orderBy(Object.entries(<{ [k: string]: string }>vehicleTypes).map(([key, value]) => ({ value: key, label: value })), 'label');
          }

          // Setup number plate types
          if (numberPlateTypes) {
            const multiplePlatesCode = _.pick(numberPlateTypes, Object.keys(numberPlateTypes).filter(x => x.includes(',')));
            const multiplePlatesCodeSelectItems = _.orderBy(Object.entries(<{ [k: string]: string }>multiplePlatesCode).map(([key, value]) => ({ value: key, label: value, styleClass: 'fw-bold' })), 'label');
            const singlePlatesCode = _.omit(numberPlateTypes, Object.keys(multiplePlatesCode));
            const singlePlatesCodeSelectItems = _.orderBy(Object.entries(<{ [k: string]: string }>singlePlatesCode).map(([key, value]) => ({ value: key, label: value })), 'label');
            this.analyticsFilter.numberPlateTypes = [...multiplePlatesCodeSelectItems, ...singlePlatesCodeSelectItems];
          }
        }),
      );
  }

  private areValidSelectedDatasources(selectedDatasources: string, availableDatasources: FacetItem[]): boolean {
    if (!selectedDatasources?.length) {
      return false;
    }

    const selectedDatasourceIds = selectedDatasources.split(',');
    const availableDatasourcesIds = availableDatasources.map(x => x.id);
    return selectedDatasourceIds.every(x => availableDatasourcesIds.includes(x));
  }

  onApplyFilterClick(): void {
    this.analyticsFilter.applyFilter.emit(this.filterForm.getRawValue());
    this.filterForm.markAsPristine();
  }

  onDatasourceSelect(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }

    const isSelectionSameGroup = (event.value as string[]).map(x => this.datasourcesGroups[x]).every((x, _, arr) => x === arr[0]);
    if (isSelectionSameGroup) {
      return;
    }

    // Options for different groups can't be selected at the same time.
    // If selected datasource is for a different group, select only that new option.
    const lastSelectedOption = event.itemValue?.value as string;
    if (!lastSelectedOption) {
      return;
    }

    this.filterForm.get('datasources').setValue([lastSelectedOption]);
  }
}
