import {DownloadStatus} from '@app/shared/models/download-status';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {Observable, Subscription} from 'rxjs';

export class DownloadHandler {
  public downloadStatus: DownloadStatus;
  public downloadProgress: number;
  public next: (value: HttpEvent<Blob>) => void;
  public error: (error: any) => void;
  public contentLengthAvailable: boolean;
  private subscription: Subscription;

  constructor(contentLengthAvailable: boolean) {
    this.contentLengthAvailable = contentLengthAvailable;
    this.downloadStatus = DownloadStatus.NONE;
    this.next = (event: HttpEvent<Blob>) => {
      if (this.downloadStatus === DownloadStatus.NONE) {//Canceled
        return;
      }
      if (event.type === HttpEventType.DownloadProgress) {
        this.downloadStatus = DownloadStatus.IN_PROGRESS;
        if (!event.total || !this.contentLengthAvailable) {
          //Content length is not available => display MB downloaded
          let downloaded = Number((event.loaded / (1024 * 1024)).toFixed(2));
          this.downloadProgress = downloaded;
        } else {
          this.downloadProgress = Math.round(event.loaded * 100 / event.total);
        }

      } else if (event.type === HttpEventType.Response) {
        let response = event;
        let fileName = 'exported';
        let contentDisposition = response.headers.get('Content-Disposition');
        let attachmentPattern = 'attachment; filename=';
        if (contentDisposition && contentDisposition.indexOf(attachmentPattern) !== -1) {
          fileName = contentDisposition
            .replace(attachmentPattern, '')
            .replace(/"/g, '');
        }
        saveAs(response.body, fileName);
        this.downloadStatus = DownloadStatus.NONE;
      }
    };

    this.error = err => {
      if (this.downloadStatus === DownloadStatus.NONE) {//Canceled
        return;
      }
      this.downloadStatus = DownloadStatus.NONE;
      alert('Problem while downloading the file.');
      console.error(err);
    };
  }

  public startTracking(downloadRequest: Observable<HttpEvent<Blob>>): void {
    this.downloadStatus = DownloadStatus.STARTED;
    this.downloadProgress = 0;
    this.subscription = downloadRequest.subscribe({
      next: this.next,
      error: this.error
    });
  }

  public cancel() {
    this.downloadStatus = DownloadStatus.NONE;
    this.subscription.unsubscribe();
  }
}
