export const AssemblyGroupIcons =  [
  [100001, 'ta-icon-body-car'],
  [100002, 'ta-icon-engine'],
  [103671, 'ta-icon-motorcycle-front'],
  [100005, 'ta-icon-filter'],
  [100018, 'ta-icon-window-cleaning'],
  [100254, 'ta-icon-fuel-mixture-formation'],
  [100011, 'ta-icon-suspension'],
  [100006, 'ta-icon-brake'],
  [100004, 'ta-icon-exhaust'],
  [100243, 'ta-icon-cooling-ac'],
  [100007, 'ta-icon-cooling-ac'],
  [100012, 'ta-icon-steering'],
  [100341, 'ta-icon-interior-equipment'],
  [100238, 'ta-icon-transmission'],
  [100010, 'ta-icon-electrics'],
  [100008, 'ta-icon-spark-glow-ignition'],
  [100733, 'ta-icon-accessories'],
  [100013, 'ta-icon-axle'],
  [100400, 'ta-icon-axle-drive'],
  [103099, 'ta-icon-wheels-tyres'],
  [100016, 'ta-icon-belt-drive'],
  [100343, 'ta-icon-carrier-equipment'],
  [100050, 'ta-icon-clutch-parts'],
  [100335, 'ta-icon-comfort-systems'],
  [103168, 'ta-icon-compressed-air-system'],
  [104152, 'ta-icon-electric-drive'],
  [100342, 'ta-icon-headlight-washer-system'],
  [100241, 'ta-icon-heater'],
  [103446, 'ta-icon-hybrid'],
  [100339, 'ta-icon-communication-systems'],
  [100019, 'ta-icon-maintenance-service-parts'],
  [100417, 'ta-icon-security-systems'],
  [100015, 'ta-icon-towbar-parts'],
  [100014, 'ta-icon-wheel-drive'],
  [100685, 'ta-icon-locking-system'],
  [700001, 'ta-icon-chemical-products'],
  [700202, 'ta-icon-body-car'],
  [705972, 'ta-icon-engine'],
  [701273, 'ta-icon-window-cleaning'],
  [701253, 'ta-icon-fuel-mixture-formation'],
  [700203, 'ta-icon-brake'],
  [701281, 'ta-icon-exhaust'],
  [701304, 'ta-icon-cooling-ac'],
  [701187, 'ta-icon-cooling-ac'],
  [700302, 'ta-icon-steering'],
  [700200, 'ta-icon-electrics'],
  [705160, 'ta-icon-spark-glow-ignition'],
  [705103, 'ta-icon-accessories'],
  [703012, 'ta-icon-axle'],
  [704450, 'ta-icon-axle-drive'],
  [703042, 'ta-icon-wheels-tyres'],
  [705856, 'ta-icon-belt-drive'],
  [701941, 'ta-icon-carrier-equipment'],
  [700283, 'ta-icon-comfort-systems'],
  [703524, 'ta-icon-compressed-air-system'],
  [701236, 'ta-icon-headlight-washer-system'],
  [701240, 'ta-icon-heater'],
  [703521, 'ta-icon-hybrid'],
  [700289, 'ta-icon-communication-systems'],
  [700310, 'ta-icon-security-systems'],
  [700305, 'ta-icon-wheel-drive'],
  [700308, 'ta-icon-locking-system'],
  [200022, 'ta-icon-body-car'],
  [200026, 'ta-icon-engine'],
  [200047, 'ta-icon-filter'],
  [200050, 'ta-icon-fuel-mixture-formation'],
  [200060, 'ta-icon-suspension'],
  [200058, 'ta-icon-brake'],
  [200051, 'ta-icon-exhaust'],
  [200065, 'ta-icon-cooling-ac'],
  [200052, 'ta-icon-cooling-ac'],
  [200062, 'ta-icon-steering'],
  [200797, 'ta-icon-interior-equipment'],
  [200054, 'ta-icon-transmission'],
  [200064, 'ta-icon-electrics'],
  [200067, 'ta-icon-accessories'],
  [200056, 'ta-icon-axle-drive'],
  [200063, 'ta-icon-wheels-tyres'],
  [200048, 'ta-icon-belt-drive'],
  [200053, 'ta-icon-clutch-parts'],
  [200987, 'ta-icon-comfort-systems'],
  [200059, 'ta-icon-compressed-air-system'],
  [200065, 'ta-icon-heater'],
  [200061, 'ta-icon-wheel-drive'],
  [200958, 'ta-icon-locking-system'],
  // Motorcycles
  [103881, 'ta-icon-cooling-ac'],
  [103952, 'ta-icon-electrics'],
  [103777, 'ta-icon-filter'],
  [103931, 'ta-icon-spark-glow-ignition'],
  [103814, 'ta-icon-engine'],
  [103842, 'ta-icon-fuel-mixture-formation'],
  [103672, 'ta-icon-brake'],
  [103879, 'ta-icon-exhaust'],
  // Axles
  [402089, 'ta-icon-brake'],
  [402132, 'ta-icon-suspension'],
  [402153, 'ta-icon-suspension'],
  [402166, 'ta-icon-steering'],
  [402187, 'ta-icon-wheels-tyres'],
  [403511, 'ta-icon-wheel-drive'],
  [403513, 'ta-icon-axle-drive']
];
