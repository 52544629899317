import { ImagesConfig } from './images-config';
import { AppLanguage } from './app-language';
import { AuthSettings } from './auth-settings';
import { NavSettings } from './nav-settings';

/**
 * @summary App context class.
 */
export class AppContext {
  /**
   * App base URL.
   *
   * @type {string}
   * @memberof AppContext
   */
  baseUrl: string;

  /**
   * Main API URL.
   *
   * @type {string}
   * @memberof AppContext
   */
  apiUrl: string;

  /**
   * Auth settings.
   *
   * @type {string}
   * @memberof AppContext
   */
  auth: AuthSettings;

  /**
   * Navigation settings.
   *
   * @type {string}
   * @memberof AppContext
   */
  navigation: NavSettings;

  /**
   * Setup build version.
   *
   * @type {string}
   * @memberof AppContext
   */
  version: string;

  /**
   * Configured country.
   *
   * @type {string}
   * @memberof AppContext
   */
  country: string;

  /**
   * App language.
   *
   * @type {string}
   * @memberof AppContext
   */
  language: string;

  /**
   * Available languages.
   *
   * @type {Array<AppLanguage>}
   * @memberof AppContext
   */
  languages: Array<AppLanguage>;

  /**
   * Hosting description. It can be used like a environment indicator.
   *
   * @type {string}
   * @memberof AppContext
   */
  hostingLocation: string;

  /**
   * Debug flag.
   *
   * @type {boolean}
   * @memberof AppContext
   */
  debug: boolean;

  /**
   * Client identifier. It can be used for OAuth authentication.
   *
   * @type {string}
   * @memberof AppContext
   */
  clientId: string;

  /**
   * Configuration for images.
   *
   * @type {ImagesConfig}
   * @memberof AppContext
   */
  images: ImagesConfig; 

}
