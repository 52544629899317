<div class="d-flex flex-column h-100">
  <ul tourAnchor="main-nav-tour" class="nav navbar-nav flex-column flex-grow-1">
    <li class="nav-item" [ngClass]="{ 'active' : item.state == 'expanded' }" [hidden]="item.hidden"
      *ngFor="let item of items; trackBy: trackByFn">
      <a class="nav-link" routerLink="{{ item.nodes && item.nodes.length > 0 ? item.nodes[0].link : '' }}"
        (click)="onToggleExpanded($event, item)" [attr.aria-expanded]="item.state == 'expanded'" [id]="item.id">
        <span>{{ item.text | translate }}</span>
      </a>
      <ul [attr.aria-expanded]="item.state == 'expanded'" [@state]="item.state"
        *ngIf="!minimized && item.nodes && item.nodes.length > 0">
        <li class="nav-item" *ngFor="let child of item.nodes; trackBy: trackByFn">
          <a class="nav-link" routerLink="{{ child.link }}" routerLinkActive="active" [id]="child.id"
            [title]="child.text.length > 16 ? child.text : ''">
            <span>{{ child.text | translate }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <div class="m-2 d-flex flex-column justify-content-center">
    <button *ngIf="showUpgradeButton"
      class="btn btn-sm upgrade-now-button mx-4"
      (click)="onUpgradeNowButtonClick($event)">
      <span class="fw-bold">{{ 'HEADER.UPGRADE' | translate }}</span>
    </button>
    <!-- <language-selector class="w-100 mt-2"></language-selector> -->
  </div>
  <div class="d-flex flex-row-reverse justify-content-around m-2 pb-2">
    <a [pTooltip]="'HEADER.LOGOUT' | translate" tooltipPosition="top" class="header-button btn-logout text-black" routerLink="/portal/logout" *ngIf="isLogged">
      <i class="fa fa-sign-out"></i>
    </a>

    <a class="header-button text-black" routerLink="/portal/login" routerLinkActive="active" *ngIf="!isLogged">
      <i class="fa fa-lock"></i>
    </a>

    <a [pTooltip]="'HEADER.FEEDBACK' | translate" tooltipPosition="top" class="header-button btn-comment text-black" *ngIf="isLogged" (click)="onFeedbackButtonClick($event)">
      <i class="fa fa-comment"></i>
    </a>

    <a [pTooltip]="userEmail" tooltipPosition="top" class="header-button text-black" *ngIf="isLogged" (click)="onChangePasswordButtonClick($event)">
      <i class="fa fa-user"></i>
    </a>

    <a tourAnchor="start-tour" [pTooltip]="'HEADER.START_PRODUCT_TOUR' | translate" tooltipPosition="top" class="header-button btn-tour text-black" *ngIf="showGuidedTourButton" (click)="onTourStart()">
      <i class="fa ta-icon-graduation-cap"></i>
    </a>
  </div>
</div>

<feedback-overlay #feedbackOverlay></feedback-overlay>
<change-password-overlay #changePasswordOverlay></change-password-overlay>
<get-full-version-dialog #getFullVersionDialog></get-full-version-dialog>

