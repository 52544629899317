import { ChangeDetectorRef, SimpleChanges, Input, Directive } from '@angular/core';

import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  ValidatorFn
} from '@angular/forms';

import {
  GrowlService
} from '../services/growl';

import {
  ListItem
} from '../models/list-item';

import { EditableComponent } from './editable';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * Base reactive form component.
 */
@Directive()
export abstract class ReactiveFormComponent<TInput, TModel> extends EditableComponent<TInput, TModel> {
  //#region Inputs

  @Input()
  public  languages: ListItem[] = [{ val: 'en', desc: 'English' }];

  //#endregion

  //#region Fields

  _form: UntypedFormGroup;
  _formErrors: any = {};
  _validationMessages: Object = {};

  _subscriptions: Subscription[] = [];

  // Observables
  // protected _languagesSub = new BehaviorSubject([{ val: 'en', desc: 'English' }]);
  // protected _languagesObs = this._languagesSub.asObservable();

  //#endregion

  //#region Consctructor

  constructor(
    ref: ChangeDetectorRef,
    growl: GrowlService,
    protected _fb: UntypedFormBuilder,
    protected translate?: TranslateService,
  ) {
    super(ref, growl);

    this.buildForm();
    this.buildValidationMessages();

    this._subscriptions.push(
      this._form.valueChanges
          .subscribe(data => this.onValueChanged(data))
    );
    this.onValueChanged(); // (re)set validation messages now
  }

  //#endregion

  //#region Lifecylce Hooks

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this._subscriptions.forEach((subscription: Subscription) => { subscription.unsubscribe(); });
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    // if (changes['languages'] && changes['languages'].currentValue) {
    //    this._languagesSub.next(changes['languages'].currentValue);
    // }
  }

  //#endregion

  //#region Abstract Methods

  /**
   * Sets up the reactive form and sets up _formErrors and _validationMessages
   * objects.
   *
   * Example:
   *   this._form = this._fb.group({
   *     firstName: new FormControl('', [Validators.maxLength(50)]),
   *     lastName: new FormControl('', [Validators.maxLength(50)]),
   *   });
   */
  protected abstract buildForm(): void;

  /**
   * Sets up validation messages objects.
   *
   * Example:
   *   this._validationMessages['firstName'] = {
   *     firstName: {
   *       required: 'Name is required.',
   *       minlength: 'Name must be at least 4 characters long.',
   *       maxlength: 'Name cannot be more than 24 characters long.'
   *     }
   *   };
   *
   */
  protected abstract buildValidationMessages(): void;

  /**
   * Rebuilds the internationalization controls.
   */
  public abstract refreshI18nFormControls(): void;

  //#endregion

  //#region Public Methods

  public isDirty(): boolean {
    return (this._form && this._form.dirty);
  }

  public isValid(): boolean {
    return (!this._form || this._form.valid);
  }

  //#endregion

  //#region Forms Event Handlers

  protected onValueChanged(data?: any) {
    if (!this._form) { return; }

    const form = this._form;
    const messages = this._validationMessages;

    this.checkFormGroup(this._formErrors, form, messages);
  }

  //#endregion

  //#region i18n Helpers Methods

  protected createI18nControls(validator?: ValidatorFn | ValidatorFn[] | null): { [key: string]: UntypedFormControl; } {
    let controls: { [key: string]: UntypedFormControl; } = { en: new UntypedFormControl() };

    if (!this.languages) {
      return controls;
    }

    this.languages.forEach((lang: ListItem) => {
      controls[lang.val] = new UntypedFormControl('', validator);
    });

    return controls;
  }

  /**
   * Given a form group builds a form control for each language.
   *
   * @param group
   * @param validator
   */
  protected updateI18nControls(group: UntypedFormGroup, validator?: ValidatorFn | ValidatorFn[] | null, getModelValue?: (key: string) => any): void {
    if (!group) {
      return;
    }

    for (let controlName in group.controls) {
      if (!this.languages.find((l: ListItem) => { return l.val === controlName; })) {
        group.removeControl(controlName);
      }
    }

    this.languages.forEach((lang: ListItem) => {
      if (!group.contains(lang.val)) {
        group.addControl(lang.val, new UntypedFormControl('', validator));
      }

      let value = getModelValue
        ? getModelValue(lang.val) || ''
        : '';

      group.get(lang.val).setValue(value);
    });
  }

  protected getI18nNormalizedValues(values: {[lang: string]: string}): {[lang: string]: string} {
    let normalized = {};

    (this.languages || [{val: 'en', desc: 'English'}]).forEach((lang: ListItem) => {
      normalized[lang.val] = values[lang.val] || '';
    });

    return normalized;
  }

  //#endregion

  //#region Helpers

  private checkFormGroup(formErrors: any, control: AbstractControl, validationMessages: any) {
    if (!validationMessages) {
      return;
    }

    for (const field in validationMessages) {
      if (typeof validationMessages[field] === 'undefined') {
        continue;
      }

      const child = control.get(field);

      const messages = validationMessages[field];

      if (child instanceof UntypedFormGroup) {
        formErrors[field] = {};
        this.checkFormGroup(formErrors[field], child, messages);
      } else {
        this.checkFormControl(formErrors, field, child, messages);
      }
    }
  }

  private checkFormControl(formErrors: any, field: string, control: AbstractControl, validationMessages: any) {
    formErrors[field] = '';

    if (control && control.dirty && !control.valid) {
      for (const key in control.errors) {
        if (!control.errors[key]) {
          continue;
        }

        if (formErrors[field]) {
          formErrors[field] += '<br>';
        }

        formErrors[field] += validationMessages[key];
      }
    }
  }

  //#endregion
}
