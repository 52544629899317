/**
 * @file Component: Loading
 * @author David López Rguez
 */

import {
  Component,
  Input,
  DoCheck,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import { TaskTrackerService } from '../../../core';
import { ILoadingOptions, LoadingOptions } from './loading-options';

import { Observable } from 'rxjs';

export interface ILoadingContext {
    message: string;
};

@Component({
  selector: 'loading',
  template: `
    <ng-container *ngIf="useDialog; then dialogLoading else loadingContent"></ng-container>

    <ng-template #dialogLoading>
      <p-dialog [visible]="isActive()" [modal]="true" [styleClass]="'bg-white'">
        <ng-template pTemplate="headless">
          <ng-container [ngTemplateOutlet]="loadingContent"></ng-container>
        </ng-template>
      </p-dialog>
    </ng-template>

    <ng-template #loadingContent>
      <div class="d-flex align-content-center m-2" *ngIf="isActive()">
        <div class="spinner-border mt-1" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="m-2" *ngIf="settings.message">{{ settings.message }}</span>
        <button class="btn btn-primary me-2" (click)="clearTasks()" *ngIf="showCloseButton">Cancel</button>
      </div>
    </ng-template>
  `,
  styleUrls:  ['loading.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingComponent implements DoCheck, OnChanges {
  @Input() options: ILoadingOptions;
  @Input() busy: Observable<any> | boolean;
  @Input() useDialog = true;

  defaults: LoadingOptions;
  settings: ILoadingOptions;

  private _busyActive: boolean;

  @Input()
  showCloseButton = true;

  constructor(private tracker: TaskTrackerService) {
    this.defaults = new LoadingOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['busy']) {
      if (this.busy instanceof Observable) {
        this._busyActive = true;

        this.busy.subscribe({
          next: () => {},
          error: () => {},
          complete: () => { this._busyActive = false; }
        });
      } else if (typeof this.busy === 'boolean') {
        this._busyActive = this.busy;
      }
    }
  }

  public clearTasks(){
    this.tracker.clearTasks();
  }

  ngDoCheck() {
    this.settings = Object.assign({}, this.defaults, this.options || {});
  }

  isActive() {
    return this.tracker.anyActiveTask() || this._busyActive;
  }
}
