import { ChangeDetectorRef, Component, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import {Message} from 'primeng/api';

import { environment } from '@env/environment';
import { AuthResponse, AuthService, ContextService, InitSession2Request, RequestCacheService, StorageService, TaskTrackerService } from '@app/core';
import { GrowlService, ReactiveFormComponent, CustomValidators } from '@app/shared';
import { WebShopContext } from '@app/shared/models/web-shop-context';
import { SELECTED_DATASOURCE } from '@app/shared/consts/datasource';

import { LoginData } from '../../../../models/login-data';

import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'login-form',
  templateUrl: 'login-form.html',
  styleUrls: ['login-form.scss']
})
export class LoginFormComponent extends ReactiveFormComponent<
  LoginData,
  LoginData
> {
  //#region Fields

  context: WebShopContext;

  loginError = false;
  errorMessage = '';

  //#endregion

  msgs: Message[] = [];

  showResponse(event: any) {
    this.msgs = [];
    this.msgs.push({
      severity: 'info',
      summary: 'Succees',
      detail: 'User Responded'
    });
  }

  //#region Consctructor

  constructor(
    ref: ChangeDetectorRef,
    growl: GrowlService,
    fb: FormBuilder,
    private _context: ContextService,
    private auth: AuthService,
    private tracker: TaskTrackerService,
    private router: Router,
    private route: ActivatedRoute,
    private cache: RequestCacheService,
    private http: HttpClient,
    private storage: StorageService
  ) {
    super(ref, growl, fb);
    this.context = _context.getInstance<WebShopContext>();
    this.languages = [{ val: 'en', desc: 'English' }];
  }

  //#endregion

  //#region Lifecycle Hooks

  // tslint:disable-next-line:use-life-cycle-interface
  override ngOnInit() {
    super.ngOnInit();
    this.checkTokenInUrl();
  }

  checkTokenInUrl() {
    this.route.queryParams.subscribe((params:Params) => {
      if( params['token'] ) {
        const loginData = <InitSession2Request>{
          token: params['token']
        };

        this.loginRequest(loginData, params['redirect']);
      } else
      if( params['user'] ) {
        const passw = params['passenc'] ? atob( decodeURIComponent( params['passenc'] ) ) : '';

        this._form.setValue({
          userName: params['user'],
          password: passw
        });

        if( passw.length ) {
           this.loginWithCreds( params['user'], passw );
        }
      }
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  userName = '';
  saveUserName( event ) {
    this.userName = event.target.value;
  }
  //#endregion

  //#region ReactiveFormComponent Methods

  public refreshI18nFormControls(): void {}

  isInputValueTheEditModel(): boolean {
    return true;
  }

  buildForm(): void {
    this._form = this._fb.group({
      userName: new FormControl('', CustomValidators.loginUserName().validators),
      password: new FormControl('', CustomValidators.loginPassword().validators)
    });
  }

  buildValidationMessages(): void {
    this._validationMessages = {
      userName: CustomValidators.loginUserName().messages,
      password: CustomValidators.loginPassword().messages
    };
  }

  loadModel(params: LoginData): Observable<LoginData> {
    throw new Error('Function not implemented');
  }

  loginWithCreds( user, passw ) {
    const loginData: InitSession2Request = <InitSession2Request>{
      login: user,
      password: passw
    };

    this.loginRequest(loginData);
  }

  saveModel(): Observable<LoginData> {
    // prepare model
    const formModel = this._form.value;

    this.loginWithCreds(formModel.userName, formModel.password);

    return EMPTY;
  }

  countdownIntervalId = null;

  formatTime(val) {
    if(val > 9) {
      return val;
    }
    return '0' + val;
  }

  stopCountDown() {
    if(this.countdownIntervalId) {
      clearInterval(this.countdownIntervalId);
      this.countdownIntervalId = null;
    }
  }

  startCountDown( msg, secondsSum ) {

    this.stopCountDown();

    this.countdownIntervalId = setInterval(() => {
      let minutes = Math.floor(secondsSum / 60);
      let seconds = secondsSum - ( minutes * 60 );
      this.errorMessage = msg.replace('{}', '' + this.formatTime( minutes ) + ':' + this.formatTime( seconds ) );

      secondsSum--;
      if( minutes <= 0 && seconds <= 0 ) {
        this.stopCountDown();
        this.errorMessage = '';
        return;
      }
    }, 1000);
  }

  failedLogins = 0;

  loginRequest( loginData: InitSession2Request, redirect?: string ) {
    (this.tracker.add(this.auth.login(loginData)) as Observable<
      AuthResponse
    >).subscribe(
      (response: AuthResponse) => {

        console.log( response );

        this.loginError = response.status != 200;
        this.errorMessage = '';

        if( !this.loginError ) {
          this.failedLogins = 0;

          let navigationPath = [redirect || '/analytics'];
          if( response.status2 == 204 ) {
            const formModel: LoginData = this._form.value;
            this.storage.storePasswordChangeCookies(formModel.username, response.message, '', 10);
            navigationPath = ['/portal/changePassword'];
          }

          setTimeout(() => {
            this._context.saveUserName(response.userDetail.login);
            this.cache.setCachePaths(response.frontendCachePaths);
            this.router.navigate(navigationPath);
          });
        } else {
          let msg = response.message;

          if( response.status == 404 ) {
            this._form.get('password').setValue('');
            this.failedLogins++;
            this.startCountDown( 'Retry after {} seconds', Math.pow(2, this.failedLogins));
          } else
          if ( response.status == 408 ) {
            this._form.get('password').setValue('');
            this.startCountDown( msg, response.status2 );
            return;
          }


          if( msg == 'message_ws_wrong_login' ) {
            msg = 'Wrong user name or password.';
          } else
          if( msg == 'message_too_many_logins' ) {
            msg = 'Your user account is already in usage.';
          }
          this.errorMessage = msg;
        }
      },
      (err: any) => {
        this.loginError = true;
        if( err && err.message && err.message.indexOf('unknown url') != -1
            || err.message.indexOf('0 Unknown Error') != -1
            || err.message.indexOf('Timeout has occurred') != -1 ) {

          this._growl.push(<Message>{
            summary: 'Login Error',
            severity: 'error',
            detail: 'Unable to connect to server. Please try again later.'
          });
          this.startEC2AndDocDB();
        }
        console.log(err);
      },
      () => {}
    );
  }

  deleteModel(): Observable<any> {
    throw new Error('Function not implemented');
  }

  modelReady(): void {
    if (!this._form) {
      return;
    }

    this._form.reset();

    if (!this.model) {
      return;
    }

    this._form.setValue({
      userName: this.model.username,
      password: this.model.password
    });
  }

  //#endregion

  //#region Events Handlers

  onLogin() {
    this._form.get('userName').markAsDirty();
    this._form.get('password').markAsDirty();
    this.onValueChanged();

    this.save();
  }

  onForgotPassword(event) {
    event.preventDefault();
    this.router.navigate(['/portal/forgotPassword']);
  }


  //#endregion

  private startEC2AndDocDB(): void {

    if( !environment.api.url.startsWith(
      'https://service.staging.analytics.tecalliance.cloud') ) {
      // do not call to start staging if you don't use staging
      return;
    }

    this._growl.push(<Message>{
      severity: 'info',
      detail: 'Try starting server.'
    });
    if (!environment.api['turnOnLambdaApi']) {
      this._growl.push(<Message>{
        severity: 'error',
        detail: 'No start server url defined.'
      });
      return;
    }

    this.http.get(environment.api['turnOnLambdaApi'],
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'InvocationType': 'Event'
        }
      }).subscribe(
      (response) => {
        this._growl.push(<Message>{
          severity: 'info',
          detail: 'Start server successfully. Please wait few minutes then try again'
        });
      },
      (error => {
          this._growl.push(<Message>{
            severity: 'error',
            detail: 'Start server failed. Error status: ' + error.status + '. See console log for details.'
          });
          console.log(error);
        }
      ));
  }

}
