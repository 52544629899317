<div class="card" [ngClass]="panelStyleClass" [ngStyle]="panelStyle">
  <div class="card-header d-flex align-items-center justify-content-between">
    <!-- Left side -->
    <div class="flex-shrink-0">
      <span *ngIf="infoText" [pTooltip]="infoText" tooltipPosition="bottom" class="me-1">
        <i class="fa ta-icon-info-circled"></i>
      </span>
      <span>{{ headerLabel }}</span>
    </div>

    <!-- Center -->
    <div class="flex-grow-1 text-center">
      <span>{{ dateRangeLabel }}</span>
    </div>
  </div>
  <div class="card-body row" [style.maxHeight.%]="90">
    <div class="mh-100"
      leaflet
      [leafletOptions]="leafletOptions"
      [leafletLayers]="leafletLayers"
      (leafletMapReady)="onMapReady($event)">
    </div>
  </div>
</div>
