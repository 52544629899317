/**
 * @file RESTful.service.js
 * @module app.core
 * @summary RESTful service.
 * @description
 * Base class from which must inherit the services with RESTful calls.
 *
 * @version 0.0.0
 * @copyright 2016
 */
export abstract class RESTfulService {

    /**
     * Given a URI and optional parameters builds and returns a URL.
     *
     * @example
     * arguments[0] - uri = '/my/uri?param1={0}
     * arguments[1]       = 1234
     * url                = http://api/my/uri?param1=1234
     *
     * @param uri
     * @returns {string} Url
     */
    public getUri(uri: string, params: Object): string {
      // if (tecContext.debug) {
      //   console.debug("@factory[tecRest] getUrl: %O", arguments);
      // }

      if (params) {
        let keys = Object.keys(params);

        if (keys && keys.length) {
          for (let i = 0; i < keys.length; i++) {
            let value = params[keys[i]];
            let regex = new RegExp(`\\{${keys[i]}\\}`, 'ig');
            uri = uri.replace(regex, value);
          }
        }
      }

      // var result = [tecContext.api.url, uri].join('');

      // if (tecContext.debug) {
      //   console.debug("@factory[tecRest] getUrl: %s", result);
      // }

      return uri;
    }
}