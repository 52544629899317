import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@app/core';
import { ChangePasswordRequest } from '@app/core/services/security/cis/change-password-request';
import { CustomValidators, GrowlService } from '../..';

@Component({
  selector: 'change-password-overlay',
  templateUrl: './change-password-overlay.component.html',
  styleUrl: './change-password-overlay.component.scss'
})
export class ChangePasswordOverlayComponent {
  @ViewChild('overlay') overlay: OverlayPanel;

  get userEmail(): string {
    return this.auth?.userDetail?.email;
  }

  form: FormGroup;

  showPassword = false;
  showErrorStyles = false;

  constructor(
    private auth: AuthService,
    private growl: GrowlService,
    protected translate: TranslateService,
  ) {
    this.form = new FormGroup({
      oldPassword: new FormControl<string>(undefined, CustomValidators.oldPassword(this.translate).validators),
      newPassword: new FormControl<string>(undefined, CustomValidators.newPassword(this.translate).validators),
      repeatNewPassword: new FormControl<string>(undefined, CustomValidators.repeatNewPassword(this.translate).validators),
    }, CustomValidators.passwordMatch('newPassword', 'repeatNewPassword'));
  }

  getFormControlErrorMessages(name: string) {
    const formControlErrors = this.form.get(name)?.errors;
    if (!formControlErrors) {
      return undefined;
    }

    return Object.keys(formControlErrors)
      .map(error => CustomValidators[name]?.call(this, this.translate)?.messages?.[error])
      .join('\n');
  }

  getFormControlErrors(name: string) {
    return this.form.get(name)?.errors;
  }

  onShowPasswordClick(event: MouseEvent) {
    this.showPassword = !this.showPassword;
  }

  onSendClick(event: MouseEvent) {
    if (this.form.invalid) {
      this.growl.showError('ERROR', this.translate.instant('FORGOT_PASSWORD.FORM_ERROR'));
      this.showErrorStyles = true;
      return;
    }

    const request: ChangePasswordRequest = {
      oldPassword: this.form.get('oldPassword').value,
      newPassword: this.form.get('newPassword').value,
    };

    this.auth.changePassword(request)
      .subscribe(response => {
        this.showErrorStyles = false;

        if (response.status == 200) {
          this.form.reset();
          this.overlay.hide();
          this.growl.infoMessage(this.translate.instant('CHANGE_PASSWORD.CHANGE_SUCCESSFUL'), '', 3500);
        } else {
          this.growl.showError(this.translate.instant('CHANGE_PASSWORD.ERROR_CHANGING_PASSWORD'), response.statusText);
        }
      });
  }
}
