<div class="login-form">
  <div class="inner-login-form">
    <form [formGroup]="_form">
      <fieldset>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <h3 class="text-left text-secondary txt-login">{{ 'LOGIN_PAGE.LOGIN' | translate }}</h3>
            </div>
          </div>
          <!-- Error message -->
          <div class="row" *ngIf="loginError" id="error-message">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <span class="badge bg-danger">{{errorMessage}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <p class="text-muted p-login" for="userName">{{ 'LOGIN_PAGE.PLEASE_LOGIN' | translate }}</p>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="text" id="userName" maxlength="50" class="required form-control" [placeholder]="'LOGIN_PAGE.ENTER_USERNAME' | translate" formControlName="userName"
                    pTooltip [pTooltip]="_formErrors.userName" tooltipPosition="top" tooltipToValidateInput="true" (keyup)="saveUserName($event)" >
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="password" id="password" maxlength="50" class="required form-control" [placeholder]="'LOGIN_PAGE.ENTER_PASSWORD' | translate" formControlName="password"
                       pTooltip [pTooltip]="_formErrors.password" tooltipPosition="top" tooltipToValidateInput="true">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!--label class="text-muted" for="language">Language</label-->
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!--div class="select">
                <select class="form-control" id="language" name="language">
                  <option *ngFor="let l of languages" [ngValue]="l.val">{{ l.desc }}</option>
                </select>
              </div-->
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="d-grid">
                <button class="btn btn-block btn-primary" (click)="onLogin()" [disabled]="countdownIntervalId">{{ 'LOGIN_PAGE.LOGIN_BUTTON' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="row">
            &nbsp;
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!-- p-checkbox binary="true" label="Remember Me"></p-checkbox -->
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12">
              <a class="pull-right forgot-up" href="#" (click)="onForgotPassword($event)">{{ 'LOGIN_PAGE.PASSWORD_FORGOT' | translate }}</a>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
