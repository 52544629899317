import { NumberType } from '@app/shared/enums';

export const NumberTypes = [
  {
    id: NumberType.Article,
    enabled: true,
    sort: 20,
    prefixToken: 'CATALOGUE.INPUTSEARCH_ARTICLE_NUMBER_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_ARTICLE_NUMBER',
    icon: 'ta-icon-item-part-number'
  },
  {
    id: NumberType.OE,
    enabled: true,
    sort: 50,
    prefixToken: 'CATALOGUE.INPUTSEARCH_OENUMBER_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_OENUMBER',
    icon: 'ta-icon-oe-number'
  },
  {
    id: NumberType.Trade,
    enabled: true,
    sort: 40,
    prefixToken: 'CATALOGUE.INPUTSEARCH_TRADE_NUMBER_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_TRADE_NUMBER',
    icon: 'ta-icon-trade-number'
  },
  {
    id: NumberType.Comparable,
    enabled: true,
    sort: 30,
    prefixToken: 'CATALOGUE.INPUTSEARCH_COMPETITOR_NUMBER_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_COMPETITOR_NUMBER',
    icon: 'ta-icon-comparable-number'
  },
  {
    id: NumberType.Replacement,
    enabled: false,
    sort: 70,
    prefixToken: 'CATALOGUE.INPUTSEARCH_REPLACES_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_REPLACES',
    icon: 'ta-icon-replacement-number'
  },
  {
    id: NumberType.Replaced,
    enabled: false,
    sort: 80,
    prefixToken: 'CATALOGUE.INPUTSEARCH_REPLACED_BY_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_REPLACED_BY',
    icon: 'ta-icon-replaced-number'
  },
  {
    id: NumberType.GTIN,
    enabled: true,
    sort: 90,
    prefixToken: 'CATALOGUE.INPUTSEARCH_GTIN_NUMBER_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_GTIN_NUMBER',
    icon: 'ta-icon-gtin-number'
  },
  {
    id: NumberType.Any,
    enabled: true,
    sort: 10,
    prefixToken: 'CATALOGUE.INPUTSEARCH_ANY_NUMBER_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_ANY_NUMBER',
    icon: 'ta-icon-search-by-number'
  },
  {
    id: NumberType.FreeText,
    enabled: true,
    sort: 10,
    prefixToken: 'CATALOGUE.INPUTSEARCH_FREE_TEXT_PREFIX',
    token: 'CATALOGUE.INPUTSEARCH_FREE_TEXT',
    icon: 'ta-icon-chat'
  }

];
