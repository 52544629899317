<p-dialog [(visible)]="isVisible"
  [header]="'GET_FULL_VERSION_DIALOG.HEADER' | translate"
  [style]="dialogStyle"
  [modal]="true">

  <loading [busy]="isSending"></loading>

  <ng-container *ngTemplateOutlet="isMessageSent ? sentConfirmation : demoForm"></ng-container>
</p-dialog>

<ng-template #sentConfirmation>
  <div class="container-fluid px-4">
    <div class="row confirm-content ">
      {{ 'GET_FULL_VERSION_DIALOG.SENT_CONFIRMATION' | translate }}
    </div>
    <div class="row pull-right py-4">
      <button class="btn btn-secondary" (click)="isVisible= false">{{ 'COMMON.CLOSE' | translate }}</button>
    </div>
  </div>
</ng-template>

<ng-template #demoForm>
  <form class="px-4">
    <span class="my-2" [innerHTML]="'GET_FULL_VERSION_DIALOG.FORM_HEADER' | translate"></span>

    <div class="form-group my-2">
      <input type="text" name="name" [(ngModel)]="user.name" maxlength="25" class="form-control required"
             [placeholder]="'COMMON.NAME' | translate" [ngClass]="{ 'error-field': error.name }" />
    </div>
    <div class="form-group my-2">
      <input type="text" name="surname" required="required" [(ngModel)]="user.surname" maxlength="25"
             class="form-control required" [placeholder]="'COMMON.SURNAME' | translate" [ngClass]="{ 'error-field': error.surname }" />
    </div>
    <div class="form-group my-2">
      <input type="text" name="email" required="required" [(ngModel)]="user.email" maxlength="50"
             class="form-control required" [placeholder]="'COMMON.EMAIL' | translate" [ngClass]="{ 'error-field': error.email }" />
    </div>
    <div class="form-group error-div my-2">
      <div class="alert-danger" *ngIf="errorMsg">
        {{errorMsg}}
      </div>
    </div>
    <div class="form-group pull-right my-2">
      <button class="btn btn-primary" (click)="sendForm($event)">{{ 'GET_FULL_VERSION_DIALOG.REQUEST_DEMO_BUTTON' | translate }}</button>
    </div>
</form>
</ng-template>
