import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { MapOptions, Map, Layer, Control, DomUtil, geoJson, Browser, LeafletMouseEvent } from 'leaflet';

import { UtilsService } from '@app/core';
import { FacetItem } from '@app/shared/models/facet-item';

import { GEO_JSON_COUNTRIES } from './countries';

@Component({
  selector: 'map-panel',
  templateUrl: './map-panel.component.html',
  styleUrl: './map-panel.component.scss'
})
export class MapPanelComponent implements OnChanges {
  /**
   * CSS Class(es) to be passed to the panel.
   */
  @Input() panelStyleClass: string;

  /**
   * Collection of CSS styles to be passed to the panel.
   */
  @Input() panelStyle: { [key: string]: any };

  // Header data inputs/outputs

  /**
   * Text to be shown as a tooltip when hover on the panel header info icon.
   */
  @Input() infoText: string;

  /**
   * Text label to be shown on the panel header.
   */
  @Input() headerLabel: string;

  /**
   * Date range of the selected data to be shown at the center of the panel header.
   */
  @Input() dateRangeLabel: string;

  /**
   * Leaflet settings for the map.
   */
  @Input() leafletOptions: MapOptions;

  /**
   * Report data to be represented on the map.
   */
  @Input() mapData: FacetItem[];

  /**
   * List of layers for the Leaflet map.
   */
  leafletLayers: Layer[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mapData']?.currentValue) {
      this.setupMapData();
    }
  }

  private setupMapData() {
    const geoJsonData = this.getGeoJsonCollectionFromMapData();
    this.leafletLayers = [
      geoJson(geoJsonData, {
        style: feature => ({
          weight: 2,
          opacity: 1,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.7,
          fillColor: UtilsService.getCountryColorForMap(feature.properties.density),
        }),
        onEachFeature: (feature: any, layer: Layer) => {
          layer.on({
            mouseover: this.highlightFeature,
            mouseout: this.resetHighlight
          });
        }
      })
      .bindTooltip(layer => `<div><b>${layer['feature'].properties.name}</b></div><div>${UtilsService.simpleNumberFormat(layer['feature'].properties.density)}</div>`),
    ];
  }

  private getGeoJsonCollectionFromMapData(): GeoJSON.FeatureCollection {
    return {
      type: "FeatureCollection",
      features: this.mapData.map(data => {
        const geoJsonCountry = GEO_JSON_COUNTRIES.features.find(country => `${country.id}`.toUpperCase() === data.id.toUpperCase());
        if (!geoJsonCountry) {
          return null;
        }
        geoJsonCountry.properties['density'] = data.count;
        return geoJsonCountry;
      }).filter(x => x !== null),
    };
  }

  private highlightFeature(event: LeafletMouseEvent) {
    const layer = event.target;
    layer.setStyle({
      fillOpacity: 0.5
    });

    if (!Browser.ie && !Browser.opera && !Browser.edge) {
      layer.bringToFront();
    }
  }

  private resetHighlight(event: LeafletMouseEvent) {
    const layer = event.target;
    layer.setStyle({
      fillOpacity: 0.7
    });
  }

  onMapReady(map: Map) {
    // Set previous default Leaflet prefix
    map.attributionControl.setPrefix('<a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>');

    const legend = new Control({
      position: 'bottomright',
    });
    legend.onAdd = () => {
      const div = DomUtil.create('div', 'info-legend');
      const grades = [0, 10, 100, 1000, 10000, 100000, 1000000, 10000000];
      const noData = '<i style="background:' + UtilsService.getCountryColorForMap(0) + '"></i> No data';

      const getShortenedNumber = (num: number): string => {
        if (num > 10000000) {
          return '>10M';
        } else if (num == 10000000) {
          return '10M';
        } else if (num > 1000000) {
          return '>1M';
        } else if (num == 1000000) {
          return '1M';
        } else if (num > 100000) {
          return '>100K';
        } else if (num == 100000) {
          return '100K';
        } else if (num > 10000) {
          return '>10K';
        } else if (num == 10000) {
          return '10K';
        } else if (num > 1000) {
          return '>1K';
        } else if (num == 1000) {
          return '1K';
        } else return num + '';
      }

      const numLabels = grades.map((grade, i, grades) => {
        const lowerValue = getShortenedNumber(grade + 1);
        const higherValue = (i + 1) < grades.length ? getShortenedNumber(grades[i + 1]) : null;
        const legendLabel = `${lowerValue}${higherValue ? '&ndash;' + higherValue : ''}`;
        return `<i style="background: ${UtilsService.getCountryColorForMap(grades[i] + 1)}"></i> ${legendLabel}`;
      })
      const labels = [noData, ...numLabels];

      div.innerHTML = labels.join('<br>');
      return div;
    }

    legend.addTo(map);
  }
}
