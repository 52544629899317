import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';

import { ApiService, GrowlService } from '../..';
import { Feedback } from '../../models/feedback';

@Component({
  selector: 'feedback-overlay',
  templateUrl: './feedback-overlay.component.html',
  styleUrl: './feedback-overlay.component.scss'
})
export class FeedbackOverlayComponent {
  @ViewChild('overlay') overlay: OverlayPanel;

  readonly validation = {
    subject: {
      maxLength: 50,
    },
    message: {
      maxLength: 500,
    },
    rating: {
      min: 0,
      max: 5,
    },
  };

  form: FormGroup = new FormGroup({
    subject: new FormControl<string>(undefined, [Validators.required, Validators.maxLength(this.validation.subject.maxLength)]),
    message: new FormControl<string>(undefined, [Validators.required, Validators.maxLength(this.validation.message.maxLength)]),
    rating: new FormControl<number>(undefined, [Validators.required, Validators.max(this.validation.rating.max), Validators.min(this.validation.rating.min)]),
  });

  get hasFormErrors(): boolean {
    return this.hasFormControlErrors('subject')
      || this.hasFormControlErrors('message')
      || this.hasFormControlErrors('rating');
  }

  constructor(
    private api: ApiService,
    private growl: GrowlService,
    private translate: TranslateService,
  ) { }

  hasFormControlErrors(name: string, checkDirty = true) {
    const control = this.form.get(name);
    return !!control.errors && (checkDirty ? control.dirty : true);
  }

  onSendClick(event: MouseEvent) {
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.value;
    const feedback = <Feedback>{
      maxRatting: this.validation.rating.max,
      subject: formValue.subject,
      message: formValue.message,
      ratting: formValue.rating,
    };

    this.api.sendFeedback(feedback)
      .subscribe(result => {
        if (result.statusCode !== 200) {
          this.growl.push({
            detail: this.translate.instant('FEEDBACK_OVERLAY.SEND_ERROR'),
            severity: 'warning',
            life: 5000,
          });
          return;
        }

        this.growl.push({
          detail: this.translate.instant('FEEDBACK_OVERLAY.SEND_SUCCESSFUL'),
          severity: 'success',
          life: 3000,
        });
        this.form.reset();
      })
  }
}
