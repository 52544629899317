<p-overlayPanel #overlay [style]="{'minWidth': '400px', 'minHeight': '300px'}">
  <form [formGroup]="form" class="d-flex flex-column">
    <h5>{{ 'FEEDBACK_OVERLAY.HEADER' | translate }}</h5>

    <input [ngClass]="{'border border-2 border-danger': hasFormControlErrors('subject')}"
      class="mb-2"
      formControlName="subject"
      [placeholder]="'FEEDBACK_OVERLAY.SUBJECT' | translate"
      [maxlength]="validation.subject.maxLength">

    <textarea [ngClass]="{'border border-2 border-danger': hasFormControlErrors('message')}"
      formControlName="message"
      [rows]="5"
      [placeholder]="'FEEDBACK_OVERLAY.MESSAGE' | translate"
      [maxlength]="validation.message.maxLength">
    </textarea>
    <span class="mb-2">{{ form?.get('message')?.value?.length || 0 }}/{{ validation.message.maxLength }}</span>

    <span>{{ 'FEEDBACK_OVERLAY.RATE_US' | translate }}</span>
    <p-rating [ngClass]="{'border border-2 border-danger': hasFormControlErrors('rating')}"
      class="mb-2"
      formControlName="rating"
      [stars]="validation.rating.max"
      [cancel]="false">
    </p-rating>

    <button class="btn btn-primary" (click)="onSendClick($event)">{{ 'COMMON.SEND' | translate }}</button>
  </form>
</p-overlayPanel>
