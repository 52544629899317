import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MessageService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenuModule } from 'primeng/menu';
import { ChartModule } from 'primeng/chart';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { RatingModule } from 'primeng/rating';

import { UIActionsService } from './services/ui-actions';
import { GrowlService } from './services/growl';
import { ApiService } from './services/api.service';
import { SessionManagerService } from './services/session-manager';
import { DashboardTourService } from './services/dashboard-tour.service';
import { PortalFooterComponent } from './components/portal-footer/portal-footer';
import { LoadingComponent } from './components/loading/loading';
import { SideMenuComponent } from './components/side-menu/side-menu';
import { AnalyticsFilterComponent } from './components/analytics-filter/analytics-filter.component';
import { AnalyticsFilterService } from './components/analytics-filter/analytics-filter.service';
import { TimespanSelectComponent } from './components/analytics-filter/timespan-select/timespan-select.component';
import { ChartPanelComponent } from './components/chart-panel/chart-panel.component';
import { GenartsFilterComponent } from './components/analytics-filter/genarts-filter/genarts-filter.component';
import { MapPanelComponent } from './components/map-panel/map-panel.component';
import { ChangePasswordOverlayComponent } from './components/change-password-overlay/change-password-overlay.component';
import { FeedbackOverlayComponent } from './components/feedback-overlay/feedback-overlay.component';
import { GetFullVersionDialogComponent } from './components/get-full-version-dialog/get-full-version-dialog.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import {TourAnchorNgBootstrapDirective} from "ngx-ui-tour-ng-bootstrap";


@NgModule({
  declarations: [
    PortalFooterComponent,
    LoadingComponent,
    SideMenuComponent,
    AnalyticsFilterComponent,
    TimespanSelectComponent,
    ChartPanelComponent,
    GenartsFilterComponent,
    MapPanelComponent,
    ChangePasswordOverlayComponent,
    FeedbackOverlayComponent,
    GetFullVersionDialogComponent,
    LanguageSelectorComponent,
  ],
  providers: [
    UIActionsService,
    MessageService,
    GrowlService,
    SessionManagerService,
    ApiService,
    AnalyticsFilterService,
    DashboardTourService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,

    // Third-party modules
    LeafletModule,
    OverlayPanelModule,
    ButtonModule,
    DialogModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    DropdownModule,
    MultiSelectModule,
    MenuModule,
    ChartModule,
    AutoCompleteModule,
    CheckboxModule,
    RatingModule,
    TourAnchorNgBootstrapDirective,
  ],
  exports: [
    PortalFooterComponent,
    LoadingComponent,
    SideMenuComponent,
    AnalyticsFilterComponent,
    ChartPanelComponent,
    MapPanelComponent,

    TranslateModule,
  ]
})
export class SharedModule { }
