/**
 * Defines the authentication types implemented by core module.
 *
 * @enum {AuthTypes}
 */
export enum AuthTypes {
  /**
   * TecAlliance CIS authentication.
   *
   * @deprecated
   */
  Cis = 'cis',

  /**
   * Json Web Token authentication.
   */
  Jwt = 'jwt',

  /**
   * Outh 2 authentication.
   */
  Oauth2 = 'oauth2'
}
