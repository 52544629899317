import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@app/core';
import { ApiService } from '../..';
import { BasicResponse } from '../../models/basic-response';

@Component({
  selector: 'get-full-version-dialog',
  templateUrl: './get-full-version-dialog.component.html',
  styleUrl: './get-full-version-dialog.component.scss'
})
export class GetFullVersionDialogComponent {
  isVisible = false;
  isSending = false;
  isMessageSent = false;

  errorMsg: string;

  user = {
    name: '',
    surname: '',
    email: ''
  };

  error = {
    name: false,
    surname: false,
    email: false
  };

  get dialogStyle() {
    return {
      width: '600px',
      height: this.isMessageSent ? '230px' : '600px',
    };
  }

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private translate: TranslateService,
  ) { }

  show() {
    this.user.email = this.auth?.userDetail?.email;
    this.isVisible = true;
  }

  sendForm(event: MouseEvent) {
    let hasErrors = false;
    for( const field in this.user ) {
      this.error[field] = false;
      if( this.user[field].trim().length === 0 ) {
        this.errorMsg = this.translate.instant('GET_FULL_VERSION_DIALOG.PLEASE_FILL_FORM');
        this.error[field] = true;
        hasErrors = true;
      }
    }

    if( hasErrors || this.isSending) {
      return;
    }
    this.isSending = true;

    this.api.sendFullVersionRequest(this.user)
      .subscribe((data: BasicResponse) => {
        this.isSending = false;
        this.errorMsg = '';

        if( data.statusCode != 200 ) {
          this.errorMsg = data.message;
        } else {
          this.isMessageSent = true;
        }
      });
  }
}
