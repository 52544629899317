import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  VALIDATION_REQUIRED,
  VALIDATION_MIN_LENGTH,
  VALIDATION_MAX_LENGTH,
  VALIDATION_EMAIL,
  VALIDATION_NUMBERS, VALIDATION_UPPER_CASE, VALIDATION_LOWER_CASE, VALIDATION_SPECIAL_CHARS,
  VALIDATION_EQUALS
} from './consts/validations';

export class CustomValidators {

  static passwordMatch(controlName: string, matchingControlName: string) {
    return (formGroup: AbstractControl) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }

      return null;
    }
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static oldPassword(translate: TranslateService): ValidatorObject {
    return {
      validators: Validators.required,
      messages: {
        required: translate.instant(VALIDATION_REQUIRED),
      },
    };
  }

  static newPassword(translate: TranslateService): ValidatorObject {
    return {
      validators: Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 5. check whether the entered password has a special character
        CustomValidators.patternValidator(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/, { hasSpecialCharacters: true }),
        // 6. Has a minimum length of 8 characters
        Validators.maxLength(25),
        Validators.minLength(12)]),
      messages: {
        required: translate.instant(VALIDATION_REQUIRED),
        maxlength: translate.instant(VALIDATION_MAX_LENGTH, {
          field: 'Password',
          length: '25'
        }),
        minlength: translate.instant(VALIDATION_MIN_LENGTH, {
          field: 'Password',
          length: '12'
        }),
        hasNumber: translate.instant(VALIDATION_NUMBERS),
        hasCapitalCase: translate.instant(VALIDATION_UPPER_CASE),
        hasSmallCase: translate.instant(VALIDATION_LOWER_CASE),
        hasSpecialCharacters: translate.instant(VALIDATION_SPECIAL_CHARS, {
          chars: '!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~'
        })
      }
    }
  }

  static repeatNewPassword(translate: TranslateService): ValidatorObject {
    return {
      validators: [
        Validators.required,
        Validators.maxLength(25)
      ],
      messages: {
        required: translate.instant(VALIDATION_REQUIRED),
        confirmedValidator: translate.instant(VALIDATION_EQUALS),
      }
    };
  }

  static userNameOrEmail(): ValidatorObject {
    return {
      validators: [
        Validators.required,
        Validators.maxLength(50)
      ],
      messages: {
        required: VALIDATION_REQUIRED,
        email: VALIDATION_EMAIL,
        maxlength: CustomValidators.formatMessage(VALIDATION_MAX_LENGTH, {
          field: 'Username or Email Address',
          length: '50'
        })
      }
    }
  }

  static loginUserName(): ValidatorObject {
    return {
      validators: [
        Validators.required,
        Validators.maxLength(50)
      ],
      messages: {
        required: VALIDATION_REQUIRED,
        maxlength: CustomValidators.formatMessage(VALIDATION_MAX_LENGTH, {
          field: 'Username',
          length: '50'
        })
      }
    }
  }

  static loginPassword(): ValidatorObject {
    return {
      validators: [
        Validators.required,
        Validators.maxLength(50)
      ],
      messages: {
        required: VALIDATION_REQUIRED,
        maxlength: CustomValidators.formatMessage(VALIDATION_MAX_LENGTH, {
          field: 'Password',
          length: '50'
        })
      }
    }
  }

  /**
   * Given a validation message, replace the params in the correct
   * positions and return a well formated validation message.
   *
   * @param params
   */
  static formatMessage(validationMessage: string, params: Object): string {
    if (params) {
      let keys = Object.keys(params);

      if (keys && keys.length) {
        for (let i = 0; i < keys.length; i++) {
          let value = params[keys[i]];
          let regex = new RegExp(`\\{${keys[i]}\\}`, 'ig');
          validationMessage = validationMessage.replace(regex, value);
        }
      }
    }

    return validationMessage;
  }

}

export interface ValidatorObject {
  messages: Object;
  validators: ValidatorFn | ValidatorFn[];
}
