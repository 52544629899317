import {Injectable} from '@angular/core';
import {HttpRequest, HttpResponse} from '@angular/common/http';
import {StorageService} from '..';

const maxAge = 1800000;//In milliseconds
@Injectable()
export class RequestCacheService {

  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    if (this.isCacheable(url)) {
      const entry = {url, response, created: Date.now()};
      this.cache.set(url, entry);
    }
  }

  cleanup() {
    const expired = Date.now() - maxAge;
    this.cache.forEach(entry => {
      if (entry.created < expired) {
        this.cache.delete(entry.url);
      }
    });
  }

  public static readonly CACHE_HEADER = "fromCache";

  private static readonly CACHE_PATHS_KEY = 'CACHE_PATHS';

  setCachePaths(paths: string[]): void {
    this._storage.setObject(RequestCacheService.CACHE_PATHS_KEY, paths);
  }

  constructor(private _storage: StorageService) {
  }

  private isCacheable(url: string): boolean {
    if (!this._storage.contains(RequestCacheService.CACHE_PATHS_KEY)) {
      return false;
    }
    let cachePaths = this._storage.getObject(RequestCacheService.CACHE_PATHS_KEY) || [];
    for (let path of cachePaths) {
      if (url.includes(path)) {
        return true;
      }
    }
    return false;
  }

}
