import { Brand } from "../models/brand";
import { BrandRecommendsResponse } from "../models/brand-recommends-response";
import { FacetItem } from "../models/facet-item";
import { BrandRecommends } from "../models/brand-recommends";
import { GenartRecommends } from "../models/genart-recommends";
import { GenartRecommendsResponse } from "../models/genart-recommends-response";

export class Converter {

    static convertBrandRecoms(brandsMap: Map<string, Brand>, data: BrandRecommendsResponse): FacetItem[] {
        const r: FacetItem[] = [];
        const recoms: BrandRecommends[] = data.recommendations;

        if (recoms) {
            for (const a of recoms) {
                if (brandsMap.has('' + a.brand)) {
                    r.push(
                        <FacetItem>{
                            id: '' + a.brand,
                            name: brandsMap.get('' + a.brand).brandName,
                            label: brandsMap.get('' + a.brand).brandName + ' [' + a.brand + ']',
                            count: a.percent,
                            percent: a.percent
                        }
                    );
                }
            }
        }
        return r;
    }

    static convertGenartRecoms( gaMap: object, data: GenartRecommendsResponse ) {
        const r: FacetItem[] = [];
        const recoms: GenartRecommends[] = data.recommendations;
        if( recoms ) {
          for( const a of recoms ) {
            if( gaMap[ '' + a.genart ] ) {
              r.push(
                <FacetItem>{
                  id: '' + a.genart,
                  name: gaMap[ '' + a.genart ],
                  label:  gaMap[ '' + a.genart ] + ' [' + a.genart + ']',
                  count: a.percent,
                  percent: a.percent
                }
              );
            }
          }
        }
        return r;
    }

    static convertToBrandsMap( brands: Brand[] ) {
        if( brands ) {
          const brandsMap = new Map<string, Brand>();
          for( const b of brands ) {
            brandsMap.set( "" + b.brandId, b );
          }
          return brandsMap;
        }
        return null;
    }

    static facet2Options( fis: FacetItem[], labelField?: string, valueField?: string ) {
      const r = [];
      for( const f of fis ) {
        r.push({"label": f[ labelField || "name" ], "value": f[valueField || "id"]});
      }
      return r;
    }
}
