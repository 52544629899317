import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { AuthService, CisResponse, StorageService, TaskTrackerService } from '@app/core';
import { ForgotPasswordRequest } from '@app/core/services/security/cis/forgot-password-request';
import { CustomValidators, GrowlService } from '@app/shared';

import { Observable } from 'rxjs';

@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrl: './forgot-password-form.component.scss'
})
export class ForgotPasswordFormComponent implements OnInit {
  buttonText: string;
  formTitle: string = 'FORGOT_PASSWORD.HEADER';

  loginError = false;
  errorMessage = '';
  email_uuid = '';

  signature = null;

  tempCodeSended = false
  isPasswordChange = false;

  countdownWaitNextRequest: number;
  countdownWaitTimerHandle: number;

  userNameOrEmail = '';

  showErrorStyles = false;

  form = new FormGroup({
    userNameOrEmail: new FormControl<string>('', CustomValidators.userNameOrEmail().validators),
    tempCode: new FormControl<string>('', [Validators.required]),
    newPassword: new FormControl<string>('', CustomValidators.newPassword(this.translate).validators),
    repeatNewPassword: new FormControl<string>('', CustomValidators.repeatNewPassword(this.translate).validators),
  }, CustomValidators.passwordMatch('newPassword', 'repeatNewPassword'));

  constructor(
    private growl: GrowlService,
    private translate: TranslateService,
    private auth: AuthService,
    private tracker: TaskTrackerService,
    private router: Router,
    private storage: StorageService,
  ) { }

  ngOnInit(): void {
    this.readSignature();
  }

  private readSignature() {
    this.isPasswordChange = this.router.url.indexOf('/changePassword') != -1;
    this.signature = this.storage.getPasswordChangeSignature();

    if( this.signature ) {
      this.tempCodeSended = true;
      this.userNameOrEmail = this.storage.getPasswordChangeUser();
      this.email_uuid = this.storage.getPasswordChangeEmailUUID();
    } else {
      this.tempCodeSended = false;
    }

    this.updateTexts();
  }

  private resetPasswordRequest(request: ForgotPasswordRequest) {
    const task = this.tracker.add(this.isPasswordChange
      ? this.auth.changePassword(request)
      : this.auth.forgotPassword(request)
    ) as Observable<CisResponse>;

    if (this.hasAnyFormError() && this.tempCodeSended) {
      this.growl.showError('ERROR', this.translate.instant('FORGOT_PASSWORD.FORM_ERROR'));
      this.showErrorStyles = true;
      return;
    }

    task.subscribe({
      next: (response: CisResponse) => {

        console.log(response);

        this.loginError = response.status != 200;
        this.errorMessage = '';

        if (!this.loginError) {
          if( !this.tempCodeSended ) {
            this.storeSignatureAndUser( response.statusText, request.userNameOrEmail );
            this.tempCodeSended = true;
            this.growl.infoMessage(this.formTitle, this.translate.instant('FORGOT_PASSWORD.CHECK_EMAIL_TOKEN'), 5000);
            this.emptyHiddenFields();
            this.updateTexts();
          } else {
            this.growl.infoMessage(this.formTitle, this.translate.instant('FORGOT_PASSWORD.SUCCESS_CHANGED'), 5000);

            this.router.navigate([ this.isPasswordChange ? '/analytics' : '/portal/login'] );
            this.resetState();
          }
        } else {
          let msg = response.statusText;
          if (msg == 'message_ws_wrong_login') {
            msg = this.translate.instant('FORGOT_PASSWORD.WRONG_USERNAME_OR_EMAIL');
          }

          if( response.status === 402 && response.statusText && response.statusText.startsWith( 'wait:' ) ) {
            this.startCountDownWaitInErrorMessage( Number.parseInt( response.statusText.substring('wait:'.length) ) );
          } else if( response.status === 402 && response.statusText === 'limit' ) {
            this.errorMessage = this.translate.instant('FORGOT_PASSWORD.MAX_3_TIMES_PER_DAY');
          } else {
            this.errorMessage = msg;
          }
        }
      },
      error: (err: any) => {
        this.loginError = true;
        if (err && err.message && err.message.indexOf('unknown url') != -1
          || err.message.indexOf('Timeout has occurred') != -1) {
          this.growl.showError(this.formTitle, this.translate.instant('FORGOT_PASSWORD.SERVER_ERROR'));
        }
        console.log(err);
      },
      complete: () => { }
    });
  }

  private startCountDownWaitInErrorMessage( waitSeconds ) {
    if( this.countdownWaitTimerHandle != null ) {
      return;
    }
    this.countdownWaitNextRequest = waitSeconds;
    this.countdownWaitTimerHandle = window.setInterval(
      () => {

        if( this.countdownWaitNextRequest <= 0 ) {
          window.clearInterval(this.countdownWaitTimerHandle);
          this.countdownWaitTimerHandle = null;
          this.errorMessage = '';
        } else {
          this.errorMessage = this.translate.instant('FORGOT_PASSWORD.WAIT_SECONDS_NEXT_TRY', { seconds: this.countdownWaitNextRequest });
        }

        this.countdownWaitNextRequest--;

      }, 1000);
  }

  private storeSignatureAndUser(signatureAndTime, userNameOrEmail) {
    const p = signatureAndTime.split(':');
    this.email_uuid = p[0];
    this.signature = p[1];
    const life = Math.round( parseInt( p[2] ) * 0.9 ); // 10% less time than server to have time to reset

    this.storage.storePasswordChangeCookies(userNameOrEmail,
          this.signature,
          this.email_uuid,
          life);
  }

  private resetHiddenFields() {
    const hv = this.tempCodeSended ? this.isPasswordChange ? '.' : '' : '.';
    const pw = this.tempCodeSended ? '' : 'A!b1c2Gjs.77hsZ';

    this.form.setValue({
      userNameOrEmail: this.userNameOrEmail,
      tempCode: hv,
      newPassword: pw,
      repeatNewPassword: pw
    });
  }

  private emptyHiddenFields() {
    this.form.setValue({
      userNameOrEmail: this.form.value.userNameOrEmail,
      tempCode: '',
      newPassword: '',
      repeatNewPassword: ''
    });
  }

  private updateTexts() {
    this.buttonText = this.tempCodeSended
      ? this.translate.instant('FORGOT_PASSWORD.UPDATE_PASSWORD')
      : this.translate.instant('FORGOT_PASSWORD.SEND_MAIL');
    this.formTitle =  this.isPasswordChange
      ? this.translate.instant('FORGOT_PASSWORD.CHANGE_PASSWORD')
      : this.translate.instant('FORGOT_PASSWORD.FORGOT');
  }

  private hasAnyFormError() {
    return this.form.get('userNameOrEmail').errors
      || this.form.get('tempCode').errors
      || this.form.get('newPassword').errors
      || this.form.get('repeatNewPassword').errors;
  }

  getFormControlErrorMessages(name: string) {
    const formControlErrors = this.form.get(name)?.errors;
    if (!formControlErrors) {
      return undefined;
    }

    return Object.keys(formControlErrors)
      .map(error => CustomValidators[name]?.call(this, this.translate)?.messages?.[error])
      .join('\n');
  }

  getFormControlErrors(name: string) {
    return this.form.get(name)?.errors;
  }

  onResetPassword() {
    this.form.get('userNameOrEmail').markAsDirty();

    if (this.tempCodeSended) {
      this.form.get('tempCode').markAsDirty();
      this.form.get('newPassword').markAsDirty();
      this.form.get('repeatNewPassword').markAsDirty();
    }

    const formModel = this.form.value

    let tempCode = null;
    if( this.tempCodeSended && this.signature ) {
      if(this.isPasswordChange) {
        tempCode = ("" + this.signature).trim();
      } else
      if( this.signature.indexOf('.') != -1 ) {
        tempCode = ("" + this.signature).trim() + ("" + formModel.tempCode).trim();
      } else {
        tempCode = ("" + this.signature).trim() + "." + ("" + formModel.tempCode).trim();
      }
    }

    const request: ForgotPasswordRequest = <ForgotPasswordRequest>{
      userNameOrEmail: formModel.userNameOrEmail,
      tempCode: tempCode,
      newPassword: this.tempCodeSended ? ("" + formModel.newPassword).trim() : null,
      endPointExtension: 'AfterLogin'
    };

    this.resetPasswordRequest(request);
  }

  saveUserNameOrEmail(event) {
    this.userNameOrEmail = event.target.value;
  }

  resetState() {
    if( !this.tempCodeSended ) {
      this.emptyHiddenFields();
      this.router.navigate(['/portal/login']);
    } else {
      this.userNameOrEmail = '';
      this.email_uuid = '';
      this.tempCodeSended = false;
      this.signature = null;
      this.storage.removePassworChangeCookies();
      this.updateTexts();
      this.resetHiddenFields();
    }
  }
}
